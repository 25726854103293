<div
	[ngClass]="{
		'flex-row-reverse': alignment === 'right',
	}"
	class="flex w-fit"
>
	<div
		[ngClass]="{
			'border border-ui-100 bg-ui-50': showGradeBackGround,
			'w-8 text-base': size === 'sm',
			'w-12 text-2xl': size === 'base',
			'w-16 text-3xl': size === 'lg',
		}"
		class="flex aspect-square h-0% items-center justify-center rounded font-bold text-accent-125"
	>
		{{ grade | number: '1.1-1' : language }}
	</div>
	@if (numberOfRatings) {
		<div
			[ngClass]="{
				'text-sm': size === 'sm' || size === 'base',
				'text-base': size === 'lg',
				'ml-3': alignment === 'left',
				'mr-3': alignment === 'right',
				'text-right': alignment === 'right',
			}"
			class="ml-3 flex flex-col justify-center text-sm leading-none"
		>
			<span
				[ngClass]="{
					'mb-1': size === 'sm',
					'mb-2': size === 'base',
					'mb-3': size === 'lg',
				}"
				class="font-bold text-dark-primary"
			>
				{{
					'components.social-proof.ratings.' + ratingText | translate
				}}
			</span>
			<span class="text-dark-tertiary">
				{{ numberOfRatings | number: '1.0-0' : language }}
				{{ 'components.social-proof.reviews' | translate }}
			</span>
		</div>
	}
</div>
