import { Promise } from './polyfills';
import { NAVIGATION_TIMING_MARKS, COMPRESSED_NAV_TIMING_MARKS } from '../performance-monitoring/navigation/marks';
import { isBeaconInspectionEnabled } from './utils';
function compressStackFrames(frames) {
  return frames.map(function (frame) {
    return {
      ap: frame.abs_path,
      f: frame.filename,
      fn: frame.function,
      li: frame.lineno,
      co: frame.colno
    };
  });
}
function compressResponse(response) {
  return {
    ts: response.transfer_size,
    ebs: response.encoded_body_size,
    dbs: response.decoded_body_size
  };
}
function compressHTTP(http) {
  var compressed = {};
  var method = http.method,
    status_code = http.status_code,
    url = http.url,
    response = http.response;
  compressed.url = url;
  if (method) {
    compressed.mt = method;
  }
  if (status_code) {
    compressed.sc = status_code;
  }
  if (response) {
    compressed.r = compressResponse(response);
  }
  return compressed;
}
function compressContext(context) {
  if (!context) {
    return null;
  }
  var compressed = {};
  var page = context.page,
    http = context.http,
    response = context.response,
    destination = context.destination,
    user = context.user,
    custom = context.custom;
  if (page) {
    compressed.p = {
      rf: page.referer,
      url: page.url
    };
  }
  if (http) {
    compressed.h = compressHTTP(http);
  }
  if (response) {
    compressed.r = compressResponse(response);
  }
  if (destination) {
    var service = destination.service;
    compressed.dt = {
      se: {
        n: service.name,
        t: service.type,
        rc: service.resource
      },
      ad: destination.address,
      po: destination.port
    };
  }
  if (user) {
    compressed.u = {
      id: user.id,
      un: user.username,
      em: user.email
    };
  }
  if (custom) {
    compressed.cu = custom;
  }
  return compressed;
}
function compressMarks(marks) {
  if (!marks) {
    return null;
  }
  var compressedNtMarks = compressNavigationTimingMarks(marks.navigationTiming);
  var compressed = {
    nt: compressedNtMarks,
    a: compressAgentMarks(compressedNtMarks, marks.agent)
  };
  return compressed;
}
function compressNavigationTimingMarks(ntMarks) {
  if (!ntMarks) {
    return null;
  }
  var compressed = {};
  COMPRESSED_NAV_TIMING_MARKS.forEach(function (mark, index) {
    var mapping = NAVIGATION_TIMING_MARKS[index];
    compressed[mark] = ntMarks[mapping];
  });
  return compressed;
}
function compressAgentMarks(compressedNtMarks, agentMarks) {
  var compressed = {};
  if (compressedNtMarks) {
    compressed = {
      fb: compressedNtMarks.rs,
      di: compressedNtMarks.di,
      dc: compressedNtMarks.dc
    };
  }
  if (agentMarks) {
    var fp = agentMarks.firstContentfulPaint;
    var lp = agentMarks.largestContentfulPaint;
    if (fp) {
      compressed.fp = fp;
    }
    if (lp) {
      compressed.lp = lp;
    }
  }
  if (Object.keys(compressed).length === 0) {
    return null;
  }
  return compressed;
}
export function compressMetadata(metadata) {
  var service = metadata.service,
    labels = metadata.labels;
  var agent = service.agent,
    language = service.language;
  return {
    se: {
      n: service.name,
      ve: service.version,
      a: {
        n: agent.name,
        ve: agent.version
      },
      la: {
        n: language.name
      },
      en: service.environment
    },
    l: labels
  };
}
export function compressTransaction(transaction) {
  var spans = transaction.spans.map(function (span) {
    var spanData = {
      id: span.id,
      n: span.name,
      t: span.type,
      s: span.start,
      d: span.duration,
      c: compressContext(span.context),
      o: span.outcome,
      sr: span.sample_rate
    };
    if (span.parent_id !== transaction.id) {
      spanData.pid = span.parent_id;
    }
    if (span.sync === true) {
      spanData.sy = true;
    }
    if (span.subtype) {
      spanData.su = span.subtype;
    }
    if (span.action) {
      spanData.ac = span.action;
    }
    return spanData;
  });
  var tr = {
    id: transaction.id,
    tid: transaction.trace_id,
    n: transaction.name,
    t: transaction.type,
    d: transaction.duration,
    c: compressContext(transaction.context),
    k: compressMarks(transaction.marks),
    me: compressMetricsets(transaction.breakdown),
    y: spans,
    yc: {
      sd: spans.length
    },
    sm: transaction.sampled,
    sr: transaction.sample_rate,
    o: transaction.outcome
  };
  if (transaction.experience) {
    var _transaction$experien = transaction.experience,
      cls = _transaction$experien.cls,
      fid = _transaction$experien.fid,
      tbt = _transaction$experien.tbt,
      longtask = _transaction$experien.longtask;
    tr.exp = {
      cls: cls,
      fid: fid,
      tbt: tbt,
      lt: longtask
    };
  }
  if (transaction.session) {
    var _transaction$session = transaction.session,
      id = _transaction$session.id,
      sequence = _transaction$session.sequence;
    tr.ses = {
      id: id,
      seq: sequence
    };
  }
  return tr;
}
export function compressError(error) {
  var exception = error.exception;
  var compressed = {
    id: error.id,
    cl: error.culprit,
    ex: {
      mg: exception.message,
      st: compressStackFrames(exception.stacktrace),
      t: error.type
    },
    c: compressContext(error.context)
  };
  var transaction = error.transaction;
  if (transaction) {
    compressed.tid = error.trace_id;
    compressed.pid = error.parent_id;
    compressed.xid = error.transaction_id;
    compressed.x = {
      t: transaction.type,
      sm: transaction.sampled
    };
  }
  return compressed;
}
export function compressMetricsets(breakdowns) {
  return breakdowns.map(function (_ref) {
    var span = _ref.span,
      samples = _ref.samples;
    return {
      y: {
        t: span.type
      },
      sa: {
        ysc: {
          v: samples['span.self_time.count'].value
        },
        yss: {
          v: samples['span.self_time.sum.us'].value
        }
      }
    };
  });
}
export function compressPayload(params, type) {
  if (type === void 0) {
    type = 'gzip';
  }
  var isCompressionStreamSupported = typeof CompressionStream === 'function';
  return new Promise(function (resolve) {
    if (!isCompressionStreamSupported) {
      return resolve(params);
    }
    if (isBeaconInspectionEnabled()) {
      return resolve(params);
    }
    var payload = params.payload,
      headers = params.headers,
      beforeSend = params.beforeSend;
    var payloadStream = new Blob([payload]).stream();
    var compressedStream = payloadStream.pipeThrough(new CompressionStream(type));
    return new Response(compressedStream).blob().then(function (payload) {
      headers['Content-Encoding'] = type;
      return resolve({
        payload: payload,
        headers: headers,
        beforeSend: beforeSend
      });
    });
  });
}