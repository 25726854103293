import { Directive } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class BaseDirective {
	language: string;

	constructor(public translate: TranslateService) {
		this.language =
			this.translate?.currentLang || this.translate?.defaultLang;
	}
}
