<picture>
	@for (source of resolvedSources; track source) {
		<source
			[media]="source.media"
			[srcset]="
				image.src
					| imageQuery: { height: source.height, width: source.width }
			"
			type="image/jpeg"
		/>
	}
	<img
		[alt]="image.altText"
		[attr.style]="image.style"
		[attr.width]="imageWidth ? imageWidth : null"
		[class]="
			className +
			(isCustomImage ? '' : ' mx-auto w-full bg-ui-25 object-none')
		"
		[src]="
			image.src | imageQuery: { height: image.height, width: image.width }
		"
		(error)="setImageFallback()"
	/>
</picture>
