import { CommonModule } from '@angular/common';
import { inject, NgModule } from '@angular/core';

import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { APM_ENVIRONMENT, provideApmHandling } from '@valk-nx/apm/index';
import {
	AVAILABILITY_DATES_API_COOKIES,
	AVAILABILITY_DATES_SERVICE_URL,
} from '@valk-nx/availability-dates-store/availability-dates.service';
import { environment } from '@valk-nx/content/environments/environment';
import { ASSETS_ROOT, HOTEL_SLUG } from '@valk-nx/core/lib/core';
import {
	HOTEL_API_COOKIES,
	HOTEL_SERVICE_URL,
} from '@valk-nx/hotel-store/hotels.service';
import {
	POSTALCODE_API_COOKIES,
	POSTALCODE_SERVICE_URL,
} from '@valk-nx/postal-code-store/lib/postal-code.service';
import {
	AVAILABILITY_DEAL_API_COOKIES,
	AVAILABILITY_DEAL_SERVICE_URL,
} from '@valk-nx/services/availability-deal/src/lib/availability-deal.service';
import {
	FORM_API_COOKIES,
	FORM_SERVICE_URL,
} from '@valk-nx/services/form/src/lib/form.service';
import { GTM_CODE } from '@valk-nx/services/google-tag-manager/src/lib/google-tag-manager.service';
import {
	NEWSLETTER_API_COOKIES,
	NEWSLETTER_SUBSCRIBE_SERVICE_URL,
} from '@valk-nx/services/newsletter/src/lib/newsletter.service';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import {
	CONTENT_API_COOKIES,
	CONTENT_SERVICE_URL,
} from '@valk-nx/storyblok-services/src/lib/services/content.service';
import {
	GIFTCARD_API_COOKIES,
	GIFTCARD_SERVICE_URL,
	POLLING_TIME,
} from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import {
	STORYBLOK_ACCESS_TOKEN,
	STORYBLOK_ENDPOINT,
} from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

import { ConfigService } from './config.service';
/**
 * Changes the service values from function calls to tokens.
 */
@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [
		provideApmHandling(),
		ConfigService,
		{
			provide: APM_ENVIRONMENT,
			useFactory: () => ({
				environment: inject(ConfigService).environment,
				name: inject(ConfigService).name,
				origin: inject(ConfigService).origin,
				production: environment.production,
				version: environment.version,
			}),
		},
		{
			provide: STORYBLOK_ACCESS_TOKEN,
			useFactory: () => {
				return inject(ConfigService).storyblokAccesstoken;
			},
		},
		{
			provide: STORYBLOK_ENDPOINT,
			useFactory: () => {
				return inject(ConfigService).storyblokEndpoint;
			},
		},
		{
			provide: ASSETS_ROOT,
			useFactory: () => {
				return inject(ConfigService).assetsRoot;
			},
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useFactory: () => {
				return inject(ConfigService).recaptchaKey;
			},
		},
		{
			provide: GTM_CODE,
			useFactory: () => {
				return inject(ConfigService).gtmCode;
			},
		},
		{
			provide: AVAILABILITY_DATES_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: AVAILABILITY_DATES_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: AVAILABILITY_DEAL_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: AVAILABILITY_DEAL_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: CONTENT_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: CONTENT_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: FORM_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: FORM_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: GIFTCARD_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: GIFTCARD_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: HOTEL_SLUG,
			useFactory: () => {
				return inject(ConfigService).hotelSlug;
			},
		},
		{
			provide: HOTEL_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: HOTEL_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: BOOKINGTOOL,
			useFactory: () => {
				return inject(ConfigService).bookingtool;
			},
		},
		{
			provide: NEWSLETTER_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: POSTALCODE_API_COOKIES,
			useFactory: () => {
				return inject(ConfigService).apiCookies;
			},
		},
		{
			provide: POSTALCODE_SERVICE_URL,
			useFactory: () => {
				return inject(ConfigService).apiUrl;
			},
		},
		{
			provide: NEWSLETTER_SUBSCRIBE_SERVICE_URL,
			useFactory: () => {
				return `${inject(ConfigService).apiUrl}newsletter/subscribe`;
			},
		},
		{
			provide: POLLING_TIME,
			useFactory: () => {
				return inject(ConfigService).pollingTime;
			},
		},
	],
})
export class ConfigModule {}
