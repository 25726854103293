import { Component, Input, OnInit } from '@angular/core';

import { BookingWidgetComponent } from '@valk-nx/storyblok/components/booking-widget/src/lib/booking-widget';
import { CarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	BookingWidget,
	Carousel,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-carousel-with-booking-widget',
	templateUrl: './carousel-with-booking-widget.html',
	standalone: true,
	imports: [CarouselComponent, BookingWidgetComponent],
})
export class CarouselWithBookingWidgetComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input() blocks: (Carousel | BookingWidget)[] = [];

	carousel!: Carousel;
	widget!: BookingWidget;

	ngOnInit() {
		this.carousel = this.blocks.find(
			(x) => x.component === 'carousel',
		) as Carousel;
		this.widget = this.blocks.find(
			(x) => x.component === 'bookingWidget',
		) as BookingWidget;
	}
}
