import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

@Pipe({
	name: 'dateparser',
	pure: true,
})
export class DateParserPipe implements PipeTransform {
	constructor(private readonly translateService: TranslateService) {}
	transform(
		value: string | Date,
		newFormat: string,
		initial = 'YYYY-MM-DD',
	): string {
		return dayjs(value, initial)
			.locale(this.translateService.currentLang)
			.format(newFormat);
	}
}
