import { Component } from '@angular/core';

import { FieldWrapper } from '@ngx-formly/core';

import { LabelPosition } from '@valk-nx/components/ui-label/src/lib/label.interface';

@Component({
	selector: 'formly-wrapper-label',
	template: `
		<div
			[attr.data-test-id]="field.id"
			[ngClass]="{
				'form-field-error': showError,
				'form-field-spaced': !labelExtras['noPadding'],
				'mt-3': withMarginTop
			}"
		>
			<vp-label
				[contentWidth]="labelExtras['contentWidth']"
				[helperText]="labelExtras['helperText'] | translate"
				[id]="field.id"
				[inputName]="field.key"
				[labelText]="to.label"
				[labelWidth]="labelExtras['width']"
				[mobilePosition]="mobileLabelPosition"
				[position]="labelPosition"
				[required]="to.required"
				[requiredHelperText]="
					labelExtras['requiredHelperText'] | translate
				"
				[showError]="showError"
				[truncate]="labelExtras['truncate'] ?? true"
			>
				<div [class]="inputWidth">
					<ng-container #fieldComponent />
				</div>
				<vp-error>
					<div
						[class]="labelExtras['errorClass']"
						class="text-xs text-error-100 sm:text-sm"
						data-test-id="error-message"
					>
						<formly-validation-message [field]="field" />
					</div>
				</vp-error>
			</vp-label>
		</div>
	`,
})
export class LabelWrapperComponent extends FieldWrapper {
	get labelExtras() {
		return this.props['labelExtras'] ?? {};
	}
	get labelPosition() {
		return this.labelExtras?.['alignment'] ?? LabelPosition.TopAlign;
	}
	get mobileLabelPosition() {
		return this.labelExtras?.['mobileAlignment'] ?? LabelPosition.TopAlign;
	}
	get inputWidth() {
		return this.labelExtras?.['inputWidth'] ?? 'w-full';
	}
	get withMarginTop() {
		return this.labelExtras?.['withMarginTop'] ?? false;
	}
}
