@if (story$ | async; as story) {
	<div>
		@if (story.content) {
			<ndc-dynamic
				[ndcDynamicComponent]="components[story.content.component]"
				[ndcDynamicInputs]="story.content"
			>
			</ndc-dynamic>
		}
	</div>
}
