import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DynamicModule } from 'ng-dynamic-component';

import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { HOTEL_SLUG } from '@valk-nx/core/lib/core';
import { CarouselComponent as SbCarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { SectionComponent } from '@valk-nx/storyblok/components/section/src/lib/section';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';

const Components = {
	carousel: SbCarouselComponent,
	section: SectionComponent,
};

@Component({
	selector: 'sb-meetingroom-overview',
	templateUrl: './meetingroom-overview.component.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		StoryblokDirective,
		PersuadeCardComponent,
	],
})
export class MeetingroomOverviewComponent extends StoryblokRootDirective {
	@Input({ required: true }) content!: unknown[];

	// Needed to "mute" errors in console
	@Input({ required: true }) canonical!: string;
	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) robotsNoFollow!: boolean;

	components: typeof Components = Components;

	currentUrl = '';

	constructor(
		private router: Router,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();

		this.currentUrl = this.router.url;
	}
}
