import { Promise } from './polyfills';
var slice = [].slice;
var isBrowser = typeof window !== 'undefined';
var PERF = isBrowser && typeof performance !== 'undefined' ? performance : {};
function isCORSSupported() {
  var xhr = new window.XMLHttpRequest();
  return 'withCredentials' in xhr;
}
var byteToHex = [];
for (var i = 0; i < 256; ++i) {
  byteToHex[i] = (i + 0x100).toString(16).substr(1);
}
function bytesToHex(buffer) {
  var hexOctets = [];
  for (var _i = 0; _i < buffer.length; _i++) {
    hexOctets.push(byteToHex[buffer[_i]]);
  }
  return hexOctets.join('');
}
var destination = new Uint8Array(16);
function rng() {
  if (typeof crypto != 'undefined' && typeof crypto.getRandomValues == 'function') {
    return crypto.getRandomValues(destination);
  } else if (typeof msCrypto != 'undefined' && typeof msCrypto.getRandomValues == 'function') {
    return msCrypto.getRandomValues(destination);
  }
  return destination;
}
function generateRandomId(length) {
  var id = bytesToHex(rng());
  return id.substr(0, length);
}
function getDtHeaderValue(span) {
  var dtVersion = '00';
  var dtUnSampledFlags = '00';
  var dtSampledFlags = '01';
  if (span && span.traceId && span.id && span.parentId) {
    var flags = span.sampled ? dtSampledFlags : dtUnSampledFlags;
    var id = span.sampled ? span.id : span.parentId;
    return dtVersion + '-' + span.traceId + '-' + id + '-' + flags;
  }
}
function parseDtHeaderValue(value) {
  var parsed = /^([\da-f]{2})-([\da-f]{32})-([\da-f]{16})-([\da-f]{2})$/.exec(value);
  if (parsed) {
    var flags = parsed[4];
    var sampled = flags !== '00';
    return {
      traceId: parsed[2],
      id: parsed[3],
      sampled: sampled
    };
  }
}
function isDtHeaderValid(header) {
  return /^[\da-f]{2}-[\da-f]{32}-[\da-f]{16}-[\da-f]{2}$/.test(header) && header.slice(3, 35) !== '00000000000000000000000000000000' && header.slice(36, 52) !== '0000000000000000';
}
function getTSHeaderValue(_ref) {
  var sampleRate = _ref.sampleRate;
  if (typeof sampleRate !== 'number' || String(sampleRate).length > 256) {
    return;
  }
  var NAMESPACE = 'es';
  var SEPARATOR = '=';
  return "" + NAMESPACE + SEPARATOR + "s:" + sampleRate;
}
function setRequestHeader(target, name, value) {
  if (typeof target.setRequestHeader === 'function') {
    target.setRequestHeader(name, value);
  } else if (target.headers && typeof target.headers.append === 'function') {
    target.headers.append(name, value);
  } else {
    target[name] = value;
  }
}
function checkSameOrigin(source, target) {
  var isSame = false;
  if (typeof target === 'string') {
    isSame = source === target;
  } else if (target && typeof target.test === 'function') {
    isSame = target.test(source);
  } else if (Array.isArray(target)) {
    target.forEach(function (t) {
      if (!isSame) {
        isSame = checkSameOrigin(source, t);
      }
    });
  }
  return isSame;
}
function isPlatformSupported() {
  return isBrowser && typeof Set === 'function' && typeof JSON.stringify === 'function' && PERF && typeof PERF.now === 'function' && isCORSSupported();
}
function setLabel(key, value, obj) {
  if (!obj || !key) return;
  var skey = removeInvalidChars(key);
  var valueType = typeof value;
  if (value != undefined && valueType !== 'boolean' && valueType !== 'number') {
    value = String(value);
  }
  obj[skey] = value;
  return obj;
}
function getServerTimingInfo(serverTimingEntries) {
  if (serverTimingEntries === void 0) {
    serverTimingEntries = [];
  }
  var serverTimingInfo = [];
  var entrySeparator = ', ';
  var valueSeparator = ';';
  for (var _i2 = 0; _i2 < serverTimingEntries.length; _i2++) {
    var _serverTimingEntries$ = serverTimingEntries[_i2],
      name = _serverTimingEntries$.name,
      duration = _serverTimingEntries$.duration,
      description = _serverTimingEntries$.description;
    var timingValue = name;
    if (description) {
      timingValue += valueSeparator + 'desc=' + description;
    }
    if (duration) {
      timingValue += valueSeparator + 'dur=' + duration;
    }
    serverTimingInfo.push(timingValue);
  }
  return serverTimingInfo.join(entrySeparator);
}
function getTimeOrigin() {
  return PERF.timing.fetchStart;
}
function stripQueryStringFromUrl(url) {
  return url && url.split('?')[0];
}
function isObject(value) {
  return value !== null && typeof value === 'object';
}
function isFunction(value) {
  return typeof value === 'function';
}
function baseExtend(dst, objs, deep) {
  for (var i = 0, ii = objs.length; i < ii; ++i) {
    var obj = objs[i];
    if (!isObject(obj) && !isFunction(obj)) continue;
    var keys = Object.keys(obj);
    for (var j = 0, jj = keys.length; j < jj; j++) {
      var key = keys[j];
      var src = obj[key];
      if (deep && isObject(src)) {
        if (!isObject(dst[key])) dst[key] = Array.isArray(src) ? [] : {};
        baseExtend(dst[key], [src], false);
      } else {
        dst[key] = src;
      }
    }
  }
  return dst;
}
function getElasticScript() {
  if (typeof document !== 'undefined') {
    var scripts = document.getElementsByTagName('script');
    for (var i = 0, l = scripts.length; i < l; i++) {
      var sc = scripts[i];
      if (sc.src.indexOf('elastic') > 0) {
        return sc;
      }
    }
  }
}
function getCurrentScript() {
  if (typeof document !== 'undefined') {
    var currentScript = document.currentScript;
    if (!currentScript) {
      return getElasticScript();
    }
    return currentScript;
  }
}
function extend(dst) {
  return baseExtend(dst, slice.call(arguments, 1), false);
}
function merge(dst) {
  return baseExtend(dst, slice.call(arguments, 1), true);
}
function isUndefined(obj) {
  return typeof obj === 'undefined';
}
function noop() {}
function find(array, predicate, thisArg) {
  if (array == null) {
    throw new TypeError('array is null or not defined');
  }
  var o = Object(array);
  var len = o.length >>> 0;
  if (typeof predicate !== 'function') {
    throw new TypeError('predicate must be a function');
  }
  var k = 0;
  while (k < len) {
    var kValue = o[k];
    if (predicate.call(thisArg, kValue, k, o)) {
      return kValue;
    }
    k++;
  }
  return undefined;
}
function removeInvalidChars(key) {
  return key.replace(/[.*"]/g, '_');
}
function getLatestSpan(spans, typeFilter) {
  var latestSpan = null;
  for (var _i3 = 0; _i3 < spans.length; _i3++) {
    var span = spans[_i3];
    if (typeFilter && typeFilter(span.type) && (!latestSpan || latestSpan._end < span._end)) {
      latestSpan = span;
    }
  }
  return latestSpan;
}
function getLatestNonXHRSpan(spans) {
  return getLatestSpan(spans, function (type) {
    return String(type).indexOf('external') === -1;
  });
}
function getLatestXHRSpan(spans) {
  return getLatestSpan(spans, function (type) {
    return String(type).indexOf('external') !== -1;
  });
}
function getEarliestSpan(spans) {
  var earliestSpan = spans[0];
  for (var _i4 = 1; _i4 < spans.length; _i4++) {
    var span = spans[_i4];
    if (earliestSpan._start > span._start) {
      earliestSpan = span;
    }
  }
  return earliestSpan;
}
function now() {
  return PERF.now();
}
function getTime(time) {
  return typeof time === 'number' && time >= 0 ? time : now();
}
function getDuration(start, end) {
  if (isUndefined(end) || isUndefined(start)) {
    return null;
  }
  return parseInt(end - start);
}
function scheduleMacroTask(callback) {
  setTimeout(callback, 0);
}
function scheduleMicroTask(callback) {
  Promise.resolve().then(callback);
}
function isPerfTimelineSupported() {
  return typeof PERF.getEntriesByType === 'function';
}
function isPerfTypeSupported(type) {
  return typeof PerformanceObserver !== 'undefined' && PerformanceObserver.supportedEntryTypes && PerformanceObserver.supportedEntryTypes.indexOf(type) >= 0;
}
function isPerfInteractionCountSupported() {
  return 'interactionCount' in performance;
}
function isBeaconInspectionEnabled() {
  var flagName = '_elastic_inspect_beacon_';
  if (sessionStorage.getItem(flagName) != null) {
    return true;
  }
  if (!window.URL || !window.URLSearchParams) {
    return false;
  }
  try {
    var parsedUrl = new URL(window.location.href);
    var isFlagSet = parsedUrl.searchParams.has(flagName);
    if (isFlagSet) {
      sessionStorage.setItem(flagName, true);
    }
    return isFlagSet;
  } catch (e) {}
  return false;
}
function isRedirectInfoAvailable(timing) {
  return timing.redirectStart > 0;
}
export { extend, merge, isUndefined, noop, baseExtend, bytesToHex, isCORSSupported, isObject, isFunction, isPlatformSupported, isDtHeaderValid, parseDtHeaderValue, getServerTimingInfo, getDtHeaderValue, getTSHeaderValue, getCurrentScript, getElasticScript, getTimeOrigin, generateRandomId, getEarliestSpan, getLatestNonXHRSpan, getLatestXHRSpan, getDuration, getTime, now, rng, checkSameOrigin, scheduleMacroTask, scheduleMicroTask, setLabel, setRequestHeader, stripQueryStringFromUrl, find, removeInvalidChars, PERF, isPerfTimelineSupported, isBrowser, isPerfTypeSupported, isPerfInteractionCountSupported, isBeaconInspectionEnabled, isRedirectInfoAvailable };