import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { BaseOptions as FlatPickrBaseOptions } from 'flatpickr/dist/types/options';
import { combineLatest, Observable, tap } from 'rxjs';

import { CalendarComponent } from '@valk-nx/components/ui-calendar/src/lib/calendar';
import { datePickerInputConfig } from '@valk-nx/components/ui-input/src/lib/configs';
import { InputComponent } from '@valk-nx/components/ui-input/src/lib/input';
import { LabelComponent } from '@valk-nx/components/ui-label/src/lib/label/label';
import { PopoverModule } from '@valk-nx/components/ui-popover/src/lib/popover.module';
import { ClickedModule } from '@valk-nx/core/lib/directives/clicked/clicked.module';
import { FlatpickrFacade } from '@valk-nx/flatpickr-store/flatpickr.facade';

import { DatePickerInterface } from './date-picker.interface';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		CalendarComponent,
		ClickedModule,
		InputComponent,
		LabelComponent,
		PopoverModule,
		TranslateModule,
	],
	exportAs: 'vpDatePicker',
	selector: `vp-date-picker`,
	template: '',
})
export class BaseDatePickerComponent {
	@Input({ required: true }) config: Partial<FlatPickrBaseOptions>;
	@Input() currency = 'EUR';
	@Input() isLoading = false;
	@Input() isPopoverOpen = false;
	@Input() prettyDateFormat = 'dd D MMMM';
	@Input() disabled = false;
	@Input() showButtonOnMobile = false;
	@Input() showPrices = false;
	@Output() datePickerModelChanged = new EventEmitter<DatePickerInterface>();
	@Output() emitHasError = new EventEmitter<boolean>();
	@Output() emitIsPopoverOpen = new EventEmitter<boolean>();
	@Output() emitMonthChange = new EventEmitter<string>();
	@Output() emitShowAvailabilityButton = new EventEmitter<Event>();
	@Output() initialDatesNotAvailable = new EventEmitter<void>();

	selectedDates$: Observable<Date[]>;
	numberOfNights$: Observable<number>;
	prettyDate$: Observable<string>;

	isPastDate = false;

	masterInputConfig = datePickerInputConfig;

	constructor(readonly flatpickrFacade: FlatpickrFacade) {
		this.numberOfNights$ = this.flatpickrFacade.selectNights$;
		this.selectedDates$ = this.flatpickrFacade.selectSelectedDates$;

		combineLatest([
			this.flatpickrFacade.selectStartDate$,
			this.flatpickrFacade.selectEndDate$,
			this.flatpickrFacade.selectNights$,
			this.flatpickrFacade.selectPrice$,
		])
			.pipe(
				takeUntilDestroyed(),
				tap(([arrivalDate, departureDate, numberOfNights, price]) => {
					this.datePickerModelChanged.emit({
						arrivalDate,
						departureDate,
						numberOfNights,
						price,
					});
				}),
			)
			.subscribe();
	}

	isDateInThePast(defaultDate: string): boolean {
		this.isPastDate = dayjs(defaultDate, 'YYYY-MM-DD').isBefore(
			dayjs(),
			'day',
		);
		this.emitHasError.emit(this.isPastDate);
		return this.isPastDate;
	}

	formatDate(
		date: string | Date | dayjs.Dayjs | undefined,
		format = this.prettyDateFormat,
	): string {
		return date ? `${dayjs(date).format(format)}` : '';
	}

	addDaysToDate(date: Date | string | dayjs.Dayjs, days: number): Date {
		return dayjs(date).add(days, 'days').toDate();
	}

	popoverStateChanged(isOpen: boolean) {
		this.isPopoverOpen = isOpen;
		this.emitIsPopoverOpen.emit(isOpen);
	}

	onEmitMonthChange(newDate: string) {
		this.emitMonthChange.emit(newDate);
	}

	onEmitHasError(hasError: boolean) {
		this.emitHasError.emit(hasError);
	}

	updatePrice() {
		if (this.showPrices) {
			this.flatpickrFacade.updatePrice();
		}
	}
}
