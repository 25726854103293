import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { LetDirective } from '@ngrx/component';
import { DynamicModule } from 'ng-dynamic-component';
import {
	combineLatest,
	delay,
	map,
	Observable,
	of,
	Subject,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';

import { SizeValues } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { CookieManagerComponent } from '@valk-nx/compositions/ui-cookie-manager/src/lib/cookie-manager.component';
import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import {
	fallbackLanguage,
	HOTEL_SLUG,
	Language,
	negativeHeader$,
} from '@valk-nx/core/lib/core';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { BannerComponent as SbBannerComponent } from '@valk-nx/storyblok/components/banner/src/lib/banner';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FooterComponent as SbFooterComponent } from '@valk-nx/storyblok/components/footer/src/lib/footer';
import { HeaderComponent } from '@valk-nx/storyblok/components/header/src/lib/header';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { hotelInfoSubject } from '@valk-nx/storyblok-core/src/lib/globals';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { StoryblokGlobals } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	banner: SbBannerComponent,
	footer: SbFooterComponent,
	header: HeaderComponent,
};

@Component({
	selector: 'sb-wrapper',
	templateUrl: './wrapper.component.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		CookieManagerComponent,
		LetDirective,
	],
})
export class SbWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('headerRef') headerRef!: ElementRef;

	wrapper$!: Observable<{
		header: StoryblokGlobals['header'] & {
			translatedSlugs?: TranslatedSlug[];
		};
		banner: StoryblokGlobals['banner'];
		footer: StoryblokGlobals['footer'];
		language: Language;
	}>;
	components: typeof Components = Components;
	storyblokComponent = '';
	fallbackLanguage = fallbackLanguage;
	cookieTranslations = {
		nl: {
			extra: {
				url: '/cookies',
			},
		},
		ca: {
			extra: {
				url: '/cookies',
			},
		},
		en: {
			extra: {
				url: '/cookies',
			},
		},
		es: {
			extra: {
				url: '/cookies',
			},
		},
		de: {
			extra: {
				url: '/cookies',
			},
		},
		fr: {
			extra: {
				url: '/cookies',
			},
		},
	};

	isNegativeHeader = false;
	destroy$ = new Subject<void>();

	constructor(
		@Inject(PLATFORM_ID) readonly platformId: string,
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private router: Router,
		@Inject(HOTEL_SLUG)
		private readonly hotelSlug: string,
	) {}

	ngOnInit() {
		this.wrapper$ = combineLatest([
			this.sbService.globals$,
			this.sbService.translatedSlugs$,
		]).pipe(
			takeUntil(this.destroy$),
			map(([sbGlobals, translatedSlugs]) => {
				const wrapper = {
					...sbGlobals,
				};
				wrapper.header['translatedSlugs'] = translatedSlugs;
				wrapper.language =
					translatedSlugs?.find((slug) => slug.selected)?.lang ||
					fallbackLanguage;
				return wrapper;
			}),
			switchMap((wrapper) => {
				if (this.hotelSlug) {
					return this.contentService
						.getHotelInfo(wrapper.language)
						.pipe(
							tap((data) =>
								hotelInfoSubject.next(data.body.data),
							),
							map(() => wrapper),
						);
				} else {
					return of(wrapper);
				}
			}),
		);

		negativeHeader$.pipe(delay(0)).subscribe((isNegative) => {
			this.isNegativeHeader = isNegative;
			this.checkHeight();
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const rawUrlTree = this.router['rawUrlTree'];
			if (
				rawUrlTree.root.children.primary?.segments[0].path ===
				fallbackLanguage
			) {
				this.router.navigate([
					this.router.routerState.snapshot.url.split(
						`/${fallbackLanguage}`,
					)[1],
				]);
			}
			this.storyblokComponent = rawUrlTree.queryParams._storyblok_c;
		});
	}

	@HostListener('window:resize')
	@Debounce()
	checkHeight() {
		if (isPlatformBrowser(this.platformId)) {
			if (this.isNegativeHeader && window.innerWidth >= SizeValues.md) {
				this.headerRef?.nativeElement.classList.add(
					'md:absolute',
					'md:w-full',
				);
			} else {
				this.headerRef?.nativeElement.classList.remove(
					'md:absolute',
					'md:w-full',
				);
			}
		}
	}
}
