import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';

import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-cta-card`,
	templateUrl: './cta-card.html',
})
export class CtaCardComponent {
	@Input() title = '';
	@Input() bulletList = '';
	@Input() price = 0;
	@Input() currency = 'EUR';
	@Input() ctaLabel = '';
	@Input({ required: true }) validFrom: Date = new Date(0);
	@Input({ required: true }) validUntil: Date = new Date(0);
	@Input() ctaUrl: { href: string; target?: string } = { href: '' };

	isMobile$: Observable<boolean> = of(false);

	get isDealAvailable() {
		return !this.isFutureDeal && !this.isPastDeal;
	}

	get isPastDeal() {
		return dayjs().isAfter(this.validUntil);
	}

	get isFutureDeal() {
		return dayjs().isBefore(this.validFrom);
	}

	get disabled() {
		return !this.isDealAvailable || this.price === 0;
	}

	constructor(private readonly viewPortService: ViewPortService) {
		this.isMobile$ = this.viewPortService.isMobile$;
	}
}
