import {
	Item,
	ItemWithExtras,
	SpecifiedTaxes,
} from '@valk-nx/compositions/ui-receipt-summary/src/lib/receipt-summary.interface';

export const isItemWithExtras = (
	item: Item | ItemWithExtras,
): item is ItemWithExtras => {
	return (
		'extras' in item && Array.isArray(item.extras) && item.extras.length > 0
	);
};

export const isTaxSpecified = (
	tax: number | SpecifiedTaxes,
): tax is SpecifiedTaxes => {
	return typeof tax === 'object';
};
