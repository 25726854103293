import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { Link } from '@valk-nx/components/ui-link/src/lib/link.interface';
import { fallbackLanguage } from '@valk-nx/core/lib/core';

import { HeaderMenuItem, MetaMenu } from '../header.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-header-desktop`,
	templateUrl: './header-desktop.html',
	styleUrls: ['header-desktop.scss'],
	standalone: true,
	imports: [
		CommonModule,
		DropdownComponent,
		LinkComponent,
		RouterModule,
		TranslateModule,
	],
})
export class HeaderDesktopComponent implements OnChanges {
	@Input({ required: true }) title: string;
	@Input({ required: true }) menuItems: HeaderMenuItem[];
	@Input({ required: true }) metaMenu: MetaMenu;
	@Input({ required: true }) language: string;
	@Input() isNegativeHeader = false;

	@Output() languageSwitch = new EventEmitter<string>();

	logoLink: Link;

	ngOnChanges() {
		this.logoLink = {
			url: this.language === fallbackLanguage ? '/' : `/${this.language}`,
		};
	}
}
