import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';
import { isSmallTablet } from '@valk-nx/services/viewport/src/lib/determine-viewports';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-storytelling',
	standalone: true,
	templateUrl: './storytelling.html',
	imports: [CommonModule, SharedModule, ImageComponent],
})
export class StorytellingComponent
	extends BaseDirective
	implements AfterViewInit
{
	@Input() image: PhotoInterface = { src: '', alt: '' };
	@Input() position: 'left' | 'right' = 'left';
	@Input() useBackground = false;

	@ViewChild('cardContent', { read: ElementRef }) cardContent!: ElementRef;

	styleHeight = '';
	sizes = Sizes;

	constructor(
		private readonly cd: ChangeDetectorRef,
		public override translate: TranslateService,
		@Inject(PLATFORM_ID) private platformId: string,
	) {
		super(translate);
	}

	ngAfterViewInit(): void {
		this.setImageHeight();
	}

	@HostListener('window:resize')
	@Debounce()
	setImageHeight(): void {
		if (isPlatformBrowser(this.platformId)) {
			const minHeightImage = 650;
			const heightContentOffset = 60;
			const screenTablet = isSmallTablet(window.innerWidth);
			const contentHeight =
				this.cardContent.nativeElement?.getBoundingClientRect()
					.height || 0;

			if (!screenTablet) {
				if (contentHeight + heightContentOffset * 2 > minHeightImage) {
					this.styleHeight = `height: ${
						contentHeight + heightContentOffset * 2
					}px`;
				} else {
					this.styleHeight = `height: ${minHeightImage}px`;
				}
			} else {
				this.styleHeight = `height: auto`;
			}

			this.cd.detectChanges();
		}
	}
}
