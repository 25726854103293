@if (!hidden) {
	<div
		[@alertAnimationTrigger]="animationState"
		[attr.data-test-class]="'alert-' + type"
		[class]="className"
		[class.opacity-100]="!isToastr"
		[ngClass]="{
			'mb-2': isToastr,
			'shadow-border': !emphasized && !hasElevation,
			'shadow-none': emphasized && !hasElevation,
			'!bg-error-50': emphasized && type === 'dialog--error',
			'!bg-warning-50': emphasized && type === 'dialog--warning',
			'!bg-select-50': emphasized && type === 'dialog--info',
			'!bg-success-25': emphasized && type === 'dialog--success',
			'elevation-3': hasElevation,
			'py-3 pl-1 pr-3': size === 'base',
			'py-2 pl-0.5 pr-2': size === 'small',
		}"
		(@alertAnimationTrigger.done)="onAlertVisibilityChange()"
		class="relative flex w-full flex-col items-start rounded bg-white opacity-0 shadow-ui-100"
	>
		<div class="flex w-full items-start">
			<span
				[ngClass]="{
					'border-error-100 bg-error-100': type === 'dialog--error',
					'border-warning-100 bg-warning-100':
						type === 'dialog--warning',
					'border-select-100 bg-select-100': type === 'dialog--info',
					'border-success-100 bg-success-100':
						type === 'dialog--success',
					'!left-0 !top-0 !h-full': emphasized,
				}"
				class="absolute -left-px -top-px h-[calc(100%+2px)] w-1.5 rounded-l border"
			></span>
			<span
				[ngClass]="size === 'base' ? 'mt-0.5 max-h-6' : 'mt-1 max-h-4'"
				class="ml-3"
			>
				@switch (type) {
					@case ('dialog--error') {
						<ng-container
							[ngTemplateOutlet]="alertIcon"
							[ngTemplateOutletContext]="{
								src: emphasized
									? 'assets/icons/icon-cross-circle-solid.svg'
									: 'assets/icons/icon-cross-circle-open.svg',
								color: 'text-error-100',
							}"
						/>
					}
					@case ('dialog--warning') {
						<ng-container
							[ngTemplateOutlet]="alertIcon"
							[ngTemplateOutletContext]="{
								src: emphasized
									? 'assets/icons/icon-exclamation-mark-triangle-solid.svg'
									: 'assets/icons/icon-exclamation-mark-triangle.svg',
								color: 'text-warning-100',
							}"
						/>
					}
					@case ('dialog--success') {
						<ng-container
							[ngTemplateOutlet]="alertIcon"
							[ngTemplateOutletContext]="{
								src: emphasized
									? 'assets/icons/icon-checkmark-circle-solid.svg'
									: 'assets/icons/icon-checkmark-circle-open.svg',
								color: 'text-success-100',
							}"
						/>
					}
					@case ('dialog--info') {
						<ng-container
							[ngTemplateOutlet]="alertIcon"
							[ngTemplateOutletContext]="{
								src: emphasized
									? 'assets/icons/icon-exclamation-mark-circle-solid.svg'
									: 'assets/icons/icon-exclamation-mark-circle.svg',
								color: 'text-select-100',
							}"
						/>
					}
				}
			</span>
			<span [ngClass]="size === 'base' ? 'pl-3' : 'pl-2'" class="grow">
				@if (title?.length) {
					<span
						[ngClass]="size === 'base' ? 'text-xl' : 'text-base'"
						class="font-semibold"
						data-test-id="title"
					>
						{{ title }}
					</span>
				}
				@if (content && content.length > 0) {
					<markdown
						[data]="content"
						[ngClass]="{
							'mt-0.5 block': !title || title.length === 0,
							'text-sm': size === 'small',
						}"
						data-test-id="content"
					/>
				}
			</span>
			@if (closeable) {
				<span
					[ngClass]="size === 'base' ? 'max-h-6' : 'max-h-4'"
					(click)="remove()"
					class="mt-1 cursor-pointer pl-3"
					data-test-id="close-action"
				>
					<svg-icon
						[ngClass]="size === 'base' ? 'h-6 w-6' : 'h-4 w-4'"
						[src]="'assets/icons/icon-cross.svg'"
						class="inline-block"
					/>
				</span>
			}
		</div>
	</div>
}

<ng-template #alertIcon let-color="color" let-src="src">
	<svg-icon
		[class]="color"
		[ngClass]="size === 'base' ? 'h-6 w-6' : 'h-4 w-4'"
		[src]="src"
		class="inline-block"
	/>
</ng-template>
