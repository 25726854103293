<button
	[attr.aria-expanded]="showCarousel"
	[ngClass]="className"
	(click)="toggleCarousel()"
	aria-controls="carousel"
	class="block w-full"
	type="button"
>
	<ng-content />
</button>
<div
	[ngClass]="{ invisible: !showCarousel }"
	(click)="toggleCarousel($event)"
	aria-hidden="true"
	class="fixed left-0 top-0 z-50 flex size-full flex-col bg-[#000]/50 px-4 py-6 sm:bottom-auto sm:block lg:px-14 xl:px-28"
	data-close-carousel="true"
	data-test-id="carousel"
	id="carousel"
>
	<div
		class="flex max-h-[33%] flex-1 flex-row-reverse items-end sm:w-auto"
		data-close-carousel="true"
	>
		<div>
			<button
				(click)="toggleCarousel()"
				aria-label="Close"
				class="elevation-1 mb-5 w-11 rounded-full border border-ui-100 bg-white p-3 hover:border-ui-125 hover:bg-ui-25 focus:border-select-100 focus:bg-white focus:shadow-item-focus focus:shadow-focus-100 focus:outline-transparent lg:mb-10"
				data-test-id="carousel__close-button"
				role="button"
				type="button"
			>
				<svg-icon
					class="fill-current"
					src="assets/icons/icon-cross.svg"
				/>
			</button>
		</div>
	</div>
	<div
		class="flex flex-row flex-wrap items-center justify-center sm:w-auto sm:flex-nowrap"
	>
		<swiper-container
			#swiper
			class="mb-5 w-full grow-0 overflow-hidden rounded sm:order-2 sm:mb-0"
			init="false"
		>
			@for (image of images; track image; let i = $index) {
				<swiper-slide class="cursor-grab rounded">
					@if (!imagesLoaded[i]) {
						<svg-icon
							class="mx-auto block size-16 animate-spin text-white"
							data-test-id="loader"
							src="assets/icons/icon-spinner.svg"
						/>
					}
					<img
						[alt]="image.alt"
						[ngClass]="{
							invisible: !imagesLoaded[i],
							'w-full bg-ui-25 object-none': !isCustomImages[i],
						}"
						[src]="
							image.src
								| imageQuery: { height: 1152, width: 2048 }
						"
						[srcset]="
							(image.src
								| imageQuery: { height: 270, width: 480 }) +
							' 500w,' +
							(image.src
								| imageQuery: { height: 540, width: 960 }) +
							' 900w,' +
							(image.src
								| imageQuery: { height: 810, width: 1440 }) +
							' 1600w,' +
							(image.src
								| imageQuery: { height: 1152, width: 2048 }) +
							' 2000w'
						"
						(error)="setImageFallback(i)"
						(load)="onImageLoad(i)"
						class="mx-auto max-h-[calc(100vh-132px)] rounded"
						data-test-class="carousel__image"
						height="auto"
						width="auto"
					/>
				</swiper-slide>
			}
		</swiper-container>
		<div class="mr-6 w-11 sm:order-1 sm:mr-7">
			@if (images.length > 1) {
				<button
					(click)="slidePrev()"
					aria-label="Previous slide"
					class="elevation-1 w-11 rounded-full border border-ui-100 bg-white p-3 hover:border-ui-125 hover:bg-ui-25 focus:border-select-100 focus:bg-white focus:shadow-item-focus focus:shadow-focus-100 focus:outline-transparent"
					data-test-id="carousel__prev-button"
					role="button"
					type="button"
				>
					<svg-icon
						class="fill-current"
						src="assets/icons/icon-chevron-left.svg"
					/>
				</button>
			}
		</div>
		<div class="order-3 ml-6 w-11 sm:ml-7">
			@if (images.length > 1) {
				<button
					(click)="slideNext()"
					aria-label="Next slide"
					class="elevation-1 w-11 rounded-full border border-ui-100 bg-white p-3 hover:border-ui-125 hover:bg-ui-25 focus:border-select-100 focus:bg-white focus:shadow-item-focus focus:shadow-focus-100 focus:outline-transparent"
					data-test-id="carousel__next-button"
					role="button"
					type="button"
				>
					<svg-icon
						class="fill-current"
						src="assets/icons/icon-chevron-right.svg"
					/>
				</button>
			}
		</div>
	</div>
</div>
