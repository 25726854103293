import { CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ClickOutsideDirective } from '@valk-nx/core/lib/directives/click-outside/click-outside.directive';
import { ClickOutsideModule } from '@valk-nx/core/lib/directives/click-outside/click-outside.module';
import { LazyImageLoadingModule } from '@valk-nx/core/lib/directives/lazy-image/lazy-image.module';
import { AccommodationTypeModule } from '@valk-nx/core/lib/pipes/accommodation-type/accommodation-type.module';
import { AccommodationTypePipe } from '@valk-nx/core/lib/pipes/accommodation-type/accommodation-type.pipe';
import { ImageQueryModule } from '@valk-nx/core/lib/pipes/image-query/image-query.module';
import { ImageQueryPipe } from '@valk-nx/core/lib/pipes/image-query/image-query.pipe';

/**
 * Marks an class as an NgModule so it could be configured
 */
@NgModule({
	declarations: [],
	exports: [
		AccommodationTypeModule,
		ClickOutsideModule,
		ImageQueryModule,
		LazyImageLoadingModule,
		TranslateModule,
	],
	imports: [
		ClickOutsideModule,
		ImageQueryModule,
		LazyImageLoadingModule,
		RouterModule,
		TranslateModule.forChild({}),
	],
	providers: [
		AccommodationTypePipe,
		CurrencyPipe,
		ImageQueryPipe,
		ClickOutsideDirective,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
/* istanbul ignore next */
export class SharedModule {}
