import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	Observable,
	switchMap,
	tap,
} from 'rxjs';

import {
	AddressInterface,
	GetAddressInterface,
	PatchAddressInterface,
} from './postal-code.interface';

const properties: (keyof GetAddressInterface)[] = [
	'country',
	'postalCode',
	'houseNumber',
	'houseNumberAddition',
];

export const extractRequest = (
	source: GetAddressInterface,
): GetAddressInterface => {
	return properties.reduce((acc, v) => {
		return { ...acc, [v]: source[v] };
	}, {} as GetAddressInterface);
};

export const sameRequest = (a: GetAddressInterface, b: GetAddressInterface) =>
	a.country === b.country &&
	a.houseNumber === b.houseNumber &&
	a.postalCode === b.postalCode &&
	a.houseNumberAddition === b.houseNumberAddition;

export const resolvePostalCode = (
	inputObs$: Observable<Partial<GetAddressInterface>>,
	storeObs$: Observable<GetAddressInterface>,
	debounce: number,
	patchValue: (a: PatchAddressInterface) => void,
	getAddress: (
		address: GetAddressInterface,
	) => Observable<Partial<AddressInterface>>,
): Observable<GetAddressInterface> =>
	storeObs$.pipe(
		switchMap((storeData: GetAddressInterface) =>
			inputObs$.pipe(
				filter(
					(formData: GetAddressInterface) =>
						!sameRequest(formData, storeData),
				),
				debounceTime(debounce),
				filter(
					(data) =>
						!!data.country &&
						!!data.postalCode &&
						!!data.houseNumber &&
						//Filter so other countries wont call postal service
						data.country === 'NL',
				),
				map(extractRequest),
				distinctUntilChanged(sameRequest),
				tap(() => patchValue({ loadingAddress: true })),
				switchMap(getAddress),
				tap((address: AddressInterface) => {
					patchValue({
						street: address.street,
						city: address.city,
						loadingAddress: false,
					});
				}),
			),
		),
	);
