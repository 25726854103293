import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { Sizes } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { isSmallTablet } from '@valk-nx/services/viewport/src/lib/determine-viewports';

import { form } from './form';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-newsletter',
	templateUrl: './newsletter.html',
	standalone: true,
	imports: [
		CommonModule,
		ImageComponent,
		MarkdownModule,
		SharedFormlyModule,
		TranslateModule,
	],
})
export class NewsletterComponent implements AfterViewChecked, OnChanges {
	@Input() className = '';
	@Input() content = '';
	@Input() photos: { file: string; alt: string }[] = [];
	@Input() formFields: FormlyFieldConfig[] = form;
	@Input() newsletterSubmitting = false;
	@Input() newsletterSubmitError = false;

	@Output() formSubmit = new EventEmitter<string>();

	@ViewChild('cardOuterContent') cardOuterContent!: ElementRef;
	@ViewChild('cardContent') cardContent!: ElementRef;

	formGroup = new FormGroup({
		email: new FormControl('', Validators.required),
	});
	sizes = Sizes;
	styleHeight = '';

	newsletterSubmitted = false;

	fallbackPhotos: { file: string; alt: string }[] = [
		{
			file: 'https://valk.bynder.nl/transform/fd12c969-6545-4e4a-9eec-76c3f65f2d52/2020-07-16_VDV_Arnhem_0027',
			alt: '',
		},
		{
			file: 'https://valk.bynder.nl/transform/e66648fb-e351-4a41-88e1-c21a2883a99e/Hert_veluwe_apeldoorn',
			alt: '',
		},
		{
			file: 'https://valk.bynder.nl/transform/8c6966cf-c277-45f2-90e3-f4144765754b/Breukelen_VM19z',
			alt: '',
		},
	];

	constructor(@Inject(PLATFORM_ID) private platformId: string) {
		this.formGroup.controls['email'].valueChanges
			.pipe(takeUntilDestroyed())
			.subscribe(() => {
				this.newsletterSubmitted = false;
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['photos']) {
			this.photos = changes['photos'].currentValue;
			if (!this.photos || this.photos.length === 0) {
				this.photos = this.fallbackPhotos;
			}
		}
	}

	onSubmit(): void {
		if (this.formGroup.valid && this.formGroup.value['email']) {
			this.formSubmit.emit(this.formGroup.value['email']);
			this.newsletterSubmitted = true;
			this.newsletterSubmitting = true;

			if (
				!GeneralHelper.isIdElementInsideViewport(
					'newsletter-submit',
					44,
				)
			) {
				GeneralHelper.scrollToElementWithId('newsletter-submit', 44);
			}
		}
	}

	ngAfterViewChecked(): void {
		this.setImageHeight();
	}

	@HostListener('window:resize')
	setImageHeight(): void {
		if (isPlatformBrowser(this.platformId)) {
			const height = this.cardContent.nativeElement.offsetHeight;
			const padding = parseFloat(
				window
					.getComputedStyle(this.cardOuterContent.nativeElement)
					.getPropertyValue('padding-bottom'),
			);
			if (!isSmallTablet(window.innerWidth) && !Number.isNaN(padding)) {
				this.styleHeight = 'height:' + (height + padding * 2) + 'px';
			} else {
				this.styleHeight = 'height:240px';
			}
		}
	}
}
