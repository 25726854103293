import {
	AfterViewInit,
	Directive,
	ElementRef,
	Input,
	Renderer2,
} from '@angular/core';

import { HeadingType } from './heading.type';

// NOTE: When using this directive, there will be an hydration mismatch, use ngSkipHydration for the component where this directive is used
@Directive({
	standalone: true,
	selector: '[vc-heading]',
})
export class HeadingDirective implements AfterViewInit {
	@Input({ required: true }) heading!: HeadingType;

	constructor(
		private readonly el: ElementRef,
		private readonly renderer: Renderer2,
	) {}

	ngAfterViewInit() {
		const headingElement = this.renderer.createElement(this.heading);
		const classList = Array.from(
			this.el.nativeElement.classList,
		) as string[];

		for (const element of classList) {
			this.renderer.addClass(headingElement, element);
		}

		while (this.el.nativeElement.firstChild) {
			this.renderer.appendChild(
				headingElement,
				this.el.nativeElement.firstChild,
			);
		}

		this.renderer.appendChild(this.el.nativeElement, headingElement);
	}
}
