import { CommonModule } from '@angular/common';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CookieManagerService } from './cookie-manager.service';

@NgModule({
	imports: [CommonModule],
	providers: [CookieManagerService, provideHttpClient(withJsonpSupport())],
})
export class CookieManagerModule {}
