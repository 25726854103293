<div
	*ngrxLet="isMobile$ as isMobile"
	[attr.data-test-class]="
		isHighlightedHotel
			? 'hotel-by-deal-card-highlighted'
			: 'hotel-by-deal-card'
	"
	(click)="navigateOnMobileClick()"
	class="card card-xs md:card-sm"
>
	<section
		[attr.aria-label]="hotelName"
		class="cursor-pointer sm:cursor-default"
		role="complementary"
	>
		<div class="flex flex-row">
			<div class="relative">
				<div class="h-full w-28 min-w-full md:w-44 lg:w-64">
					<!-- Note: opacity on parent does not work on webkit browsers ? TODO: Investigate VPL-11867 -->
					<a [href]="ctaUrl" target="_blank">
						<vp-image
							className="rounded-sm object-cover w-full h-full {{
								disabled ? 'opacity-50' : ''
							}}"
							[image]="{
								src: image?.src,
								altText: image?.alt,
								width: 326,
								height: 230,
							}"
						/>
					</a>
				</div>
				@if (ribbonText) {
					<div class="absolute -left-4 top-4 sm:-left-5 sm:top-5">
						<vp-ribbon
							[maxLength]="15"
							[text]="ribbonText"
							color="yellow"
						/>
					</div>
				}
			</div>

			<div class="w-full py-1 pl-2 pr-1 md:py-3 md:pl-6 md:pr-3">
				<div class="flex min-h-full flex-col justify-between gap-3">
					<div
						class="flex w-full flex-col gap-3 sm:flex-row sm:justify-between"
					>
						<h3 class="mb-0">
							<a
								[href]="ctaUrl"
								class="mb-0 mr-2 inline-block text-lg font-semibold sm:text-2xl"
								data-test-id="name"
								target="_blank"
							>
								{{ hotelName }}
								<span class="inline-block align-middle">
									@for (star of stars; track star) {
										<svg-icon
											class="inline-block size-3 text-[#F7D073] sm:size-5"
											src="assets/icons/icon-star.svg"
										/>
									}
								</span>
							</a>
						</h3>

						@if (rating) {
							<div class="min-w-max">
								<vp-social-proof
									[alignment]="isMobile ? 'left' : 'right'"
									[numberOfRatings]="numberOfRatings"
									[rating]="rating"
									[size]="isMobile ? 'sm' : 'base'"
								/>
							</div>
						}
					</div>

					<div
						class="flex w-full flex-col gap-4 sm:gap-6 lg:flex-row lg:justify-between"
					>
						@if (!disabled) {
							<div class="flex flex-col gap-6">
								@if (!isMobile) {
									<ng-container
										[ngTemplateOutlet]="uspList"
										[ngTemplateOutletContext]="{
											usps: usps,
										}"
									/>
								}
								<ng-container
									[ngTemplateOutlet]="tagList"
									[ngTemplateOutletContext]="{ tags: tags }"
								/>
							</div>
						}

						@if (disabled && !isMobile) {
							<div class="flex flex-col gap-6">
								<ng-container
									[ngTemplateOutlet]="uspList"
									[ngTemplateOutletContext]="{ usps: usps }"
								/>
							</div>
						}

						<div
							class="flex w-full flex-row items-end justify-between gap-6 self-end lg:w-fit lg:flex-col"
						>
							@if (!disabled) {
								<vp-pricing
									[alignment]="
										(isTablet$ | async) && !isMobile
											? 'self-start'
											: 'self-end'
									"
									[conditions]="
										'global.starting-price-excl-taxes'
											| translate
									"
									[currency]="currency"
									[indication]="false"
									[price]="price"
									[size]="isMobile ? 'base' : 'xl'"
									class="ml-auto sm:ml-0"
								/>
							}

							@if (!disabled) {
								<div class="hidden self-end sm:block">
									<a
										[href]="ctaUrl"
										class="btn btn-primary"
										target="_blank"
										type="button"
									>
										{{
											'compositions.hotel-by-deal-card.button-cta'
												| translate
										}}
									</a>
								</div>
							}
							@if (disabled) {
								<div
									class="ml-auto text-right text-base italic opacity-75 sm:text-lg lg:w-60"
								>
									{{
										(isFutureDeal
											? 'compositions.hotel-by-deal-card.deal-is-future'
											: isPastDeal
												? 'compositions.hotel-by-deal-card.deal-is-past'
												: 'compositions.hotel-by-deal-card.deal-unavailable'
										) | translate
									}}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

<ng-template #uspList let-usps="usps">
	<div class="list-checkmarks">
		<ul class="ml-5 text-lg">
			@for (usp of usps; track usp) {
				<li>{{ usp }}</li>
			}
		</ul>
	</div>
</ng-template>

<ng-template #tagList let-tags="tags">
	<div class="inline-flex flex-wrap gap-1.5">
		@for (tag of tags; track tag) {
			<vp-tag [color]="'light-lime'">
				{{ tag }}
			</vp-tag>
		}
	</div>
</ng-template>
