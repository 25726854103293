import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateParserPipe } from './date-parser';

@NgModule({
	declarations: [DateParserPipe],
	exports: [DateParserPipe],
	imports: [CommonModule],
})
export class DateParserModule {}
