import plugin from 'tailwindcss/plugin';
/**
 * This plugin renders different elevations to use in your project
 * It can be extended or overwritten in your tailwind config using the elevations key.
 * A elevation requires a `backgroundColor` key and a `boxShadow` key.
 */

const elevations = plugin(
	function ({ addComponents, theme, e }) {
		const values = theme('elevations');

		return addComponents([
			Object.entries(values).map(([key, value]) => {
				const elevationClass = e(`elevation-${key}`);
				const classes = {
					[`.${elevationClass}`]: {
						'box-shadow': `${value.boxShadow}`,
					},
				};
				if (value.backgroundColor) {
					classes[`.${elevationClass}`]['background-color'] =
						value.backgroundColor;
				}
				return classes;
			}),
		]);
	},
	{
		theme: {
			elevations: {
				basement: {
					backgroundColor: '#F3F3F3',
					boxShadow: 'none',
				},
				'below-ground': {
					backgroundColor: '#F8F8F8',
					boxShadow: 'none',
				},
				none: { backgroundColor: 'transparent', boxShadow: 'none' },
				1: {
					boxShadow:
						'0px 2px 4px 0px var(--tw-shadow-color, rgba(0, 0, 0, 0.2))',
				},
				2: {
					boxShadow:
						'0px 3px 8px 0px var(--tw-shadow-color, rgba(0, 0, 0, 0.15))',
				},
				3: {
					boxShadow:
						'0px 5px 12px 0px var(--tw-shadow-color, rgba(0, 0, 0, 0.10))',
				},
			},
		},
	},
);

export default elevations;
