import plugin from 'tailwindcss/plugin';

const sectionPlugin = plugin.withOptions(function () {
	return function ({ addComponents, e }) {
		addComponents({
			[`.${e('section-spacing')}`]: {
				padding: '2rem 0.75rem',

				'@screen md': {
					padding: '3rem 2rem',
				},

				'@screen lg': {
					padding: '4rem 2rem',
				},

				'@screen 2xl': {
					padding: '4rem 2.5rem',
				},
			},

			[`.${e('section-gap')}`]: {
				'--section-gap': '1rem',

				display: 'flex',
				flexDirection: 'column',
				gap: 'var(--section-gap)',

				'@screen md': {
					'--section-gap': '1.5rem',
				},

				'@screen 2xl': {
					'--section-gap': '2rem',
				},
			},
		});
	};
});

export default sectionPlugin;
