import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AccommodationTypePipe } from './accommodation-type.pipe';

@NgModule({
	declarations: [AccommodationTypePipe],
	exports: [AccommodationTypePipe],
	imports: [CommonModule, TranslateModule.forChild({})],
})
export class AccommodationTypeModule {}
