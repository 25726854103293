import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
} from '@angular/core';

import { ImageComponent as UIImage } from '@valk-nx/components/ui-image/src/lib/image.component';
import { ImageInterface } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	BynderImage,
	ImageAspectRatio,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-image',
	templateUrl: './image.html',
	standalone: true,
	imports: [UIImage, StoryblokDirective],
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class ImageComponent extends StoryblokRootDirective implements OnInit {
	@Input() bynder!: BynderImage[];
	@Input() imageAlt = '';
	@Input() imageWidth = 1920;
	@Input() aspectRatio: ImageAspectRatio = '16:9';

	vpImage: ImageInterface = { src: '', width: 0, height: 0 };

	constructor(private el: ElementRef) {
		super();
	}

	ngOnInit(): void {
		this.setImage();
	}

	calculateImageHeight(width: number) {
		const aspectRatio = this.aspectRatio.split(':');
		return Math.round(
			(width / parseInt(aspectRatio[0], 10)) *
				parseInt(aspectRatio[1], 10),
		);
	}

	@HostListener('window:resize')
	@Debounce(300)
	setImage(): void {
		const width = this.el.nativeElement.offsetWidth || this.imageWidth;
		const src =
			this.bynder && this.bynder[0]?.files?.transformBaseUrl?.url
				? this.bynder[0].files.transformBaseUrl.url
				: '';
		this.vpImage = {
			src: src,
			altText: this.imageAlt,
			width,
			height: this.calculateImageHeight(width),
		};
	}
}
