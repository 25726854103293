import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, tap } from 'rxjs';

import { GoogleTagManagerService } from '@valk-nx/services/google-tag-manager/src/lib/google-tag-manager.service';
import { HashService } from '@valk-nx/services/google-tag-manager/src/lib/hash/hash.service';

import * as actions from './tag-manager.actions';
import {
	DealsShowAvailabilityType,
	FormSubmitType,
	GiftcardOrderB2BType,
	GiftcardOrderB2CType,
	NewsletterSubmitType,
} from './tag-manager.interface';

@Injectable()
export class TagManagerEffects {
	private readonly window?: Window;
	formSubmit$: Observable<FormSubmitType>;
	newsletterSubmit$: Observable<NewsletterSubmitType>;
	giftcardOrderB2B$: Observable<GiftcardOrderB2BType>;
	giftcardOrderB2C$: Observable<GiftcardOrderB2CType>;
	dealsShowAvailability$: Observable<DealsShowAvailabilityType>;

	constructor(
		private readonly actions$: Actions,
		private readonly gtmService: GoogleTagManagerService,
		private readonly hashService: HashService,
		@Inject(DOCUMENT) public document: Document,
	) {
		this.window = this.document.defaultView as Window;

		this.formSubmit$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(actions.formSubmit),
					tap((action) => {
						this.gtmService.push({
							event: 'form_submit',
							eventLocation: (this.window as Window).location
								.hostname,
							eventType: action.formSubject,
						});
					}),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.newsletterSubmit$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(actions.newsletterSubmit),
					tap(async (action) => {
						const emailAddress = await this.hashService.hashString(
							action.emailAddress,
						);
						this.gtmService.push({
							event: 'newsletter_subscription',
							eventLocation: (this.window as Window).location
								.hostname,
							data: { emailAddress },
						});
					}),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.giftcardOrderB2B$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(actions.giftcardOrderB2B),
					tap(async (action) => {
						const emailAddress = await this.hashService.hashString(
							action.emailAddress,
						);
						const phoneNumber = await this.hashService.hashString(
							action.phoneNumber,
						);

						this.gtmService.push({
							event: 'form_submit',
							eventLocation: (this.window as Window).location
								.hostname,
							eventType: 'giftcard_order_business',
							data: {
								emailAddress,
								phoneNumber,
								companyName: action.companyName,
							},
						});
					}),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.giftcardOrderB2C$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(actions.giftcardOrderB2C),
					tap(async (action) => {
						const emailAddress = await this.hashService.hashString(
							action.emailAddress,
						);
						const phoneNumber = await this.hashService.hashString(
							action.phoneNumber,
						);

						this.gtmService.push({
							event: 'form_submit',
							eventLocation: (this.window as Window).location
								.hostname,
							eventType: 'giftcard_order_consumer',
							data: {
								emailAddress,
								numCards: action.numCards,
								orderValue: action.orderValue,
								phoneNumber,
							},
						});
					}),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.dealsShowAvailability$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(actions.dealsShowAvailability),
					tap((action) => {
						this.gtmService.push({
							event: 'show_availability_deals',
							eventLocation: (this.window as Window).location
								.hostname,
							data: action,
						});
					}),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);
	}
}
