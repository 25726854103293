import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	OnChanges,
	PLATFORM_ID,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { Placement } from '@popperjs/core';
import dayjs from 'dayjs';
import { BaseOptions as FlatPickrBaseOptions } from 'flatpickr/dist/types/options';
import {
	combineLatest,
	distinctUntilChanged,
	map,
	Observable,
	Subject,
	tap,
} from 'rxjs';

import {
	CalendarComponent,
	FlatPickrEvent,
	FlatPickrEventType,
} from '@valk-nx/components/ui-calendar/src/lib/calendar';
import { BaseDatePickerComponent } from '@valk-nx/components/ui-date-picker/src/lib/base-date-picker';
import { LabelSettings } from '@valk-nx/components/ui-date-picker/src/lib/date-picker.interface';
import { InputComponent } from '@valk-nx/components/ui-input/src/lib/input';
import { InputInterface } from '@valk-nx/components/ui-input/src/lib/input.interface';
import { LabelComponent } from '@valk-nx/components/ui-label/src/lib/label/label';
import { PopoverModule } from '@valk-nx/components/ui-popover/src/lib/popover.module';
import { ClickedModule } from '@valk-nx/core/lib/directives/clicked/clicked.module';
import { FlatpickrFacade } from '@valk-nx/flatpickr-store/flatpickr.facade';
import { AvailabilityDealsResponse } from '@valk-nx/services/availability-deal/src/lib/availability-deal.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		CalendarComponent,
		ClickedModule,
		CommonModule,
		InputComponent,
		LabelComponent,
		LetDirective,
		PopoverModule,
		TranslateModule,
	],
	providers: [FlatpickrFacade],
	exportAs: 'vpDateRangePicker',
	selector: `vp-date-range-picker`,
	templateUrl: './date-range-picker.html',
})
export class DateRangePickerComponent
	extends BaseDatePickerComponent
	implements AfterViewInit, OnChanges
{
	@Input({ required: true }) availability: AvailabilityDealsResponse;
	@Input({ required: true }) startInputConfig: Partial<InputInterface>;
	@Input({ required: true }) endInputConfig: Partial<InputInterface>;
	@Input({ required: true }) startLabelSettings: LabelSettings;
	@Input({ required: true }) endLabelSettings: LabelSettings;
	@Input() minNights = 1;
	@Input() maxNights: number;
	@Input() initialNumberOfNights: number;
	@Input() initialArrivalDate: Date;
	@Input() jumpToFirstAvailableDate = false;
	@Input() popoverOffset: [number, number] = [0, 8];
	@Input() boundaryTargetId: string;
	@Input() popoverAlignment: Placement = 'bottom-start';
	@Input() override prettyDateFormat = 'dd D MMM';

	config$: Observable<Partial<FlatPickrBaseOptions>>;
	filteredCalendarData$: Observable<string[]>;
	prettyStartDate$: Observable<string>;
	prettyEndDate$: Observable<string>;
	isSmallMobile$: Observable<boolean>;
	isSmallTablet$: Observable<boolean>;

	recalculateCalendarData$ = new Subject<void>();

	constructor(
		@Inject(PLATFORM_ID) readonly platformId: string,
		private readonly viewport: ViewPortService,
		override readonly flatpickrFacade: FlatpickrFacade,
	) {
		super(flatpickrFacade);
		this.isSmallMobile$ = this.viewport.isSmallMobile$;
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
		this.config$ = this.adjustConfigToScreenSize();

		this.prettyStartDate$ = this.flatpickrFacade.selectStartDate$.pipe(
			map((startDate) => this.formatDate(startDate)),
		);

		this.prettyEndDate$ = this.flatpickrFacade.selectEndDate$.pipe(
			map((endDate) => this.formatDate(endDate)),
		);
		this.filteredCalendarData$ = this.filterCalendarData();
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.startInputConfig = {
				...this.masterInputConfig,
				...this.startInputConfig,
			};
			this.endInputConfig = {
				...this.masterInputConfig,
				...this.endInputConfig,
			};
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['availability']) {
			if (
				changes['availability'].previousValue === null &&
				this.initialArrivalDate
			) {
				this.selectInitialDates();
			}
			this.recalculateCalendarData$.next();
		}

		if (changes['disabled']) {
			this.startInputConfig.disabled = this.disabled;
			this.endInputConfig.disabled = this.disabled;
		}
	}

	onDatePickerEmitter(event: FlatPickrEvent): void {
		if (event.type === FlatPickrEventType.OnValueUpdate) {
			this.flatpickrFacade.setDatesAndUpdateNights(event.selectedDates);
			this.recalculateCalendarData$.next();
			this.updatePrice();
		}
	}

	adjustConfigToScreenSize(): Observable<Partial<FlatPickrBaseOptions>> {
		return this.isSmallTablet$.pipe(
			distinctUntilChanged(),
			map((isSmallTablet) => {
				return {
					...this.config,
					showMonths: isSmallTablet ? 1 : this.config.showMonths,
				};
			}),
			tap((config) => {
				this.flatpickrFacade.updateShowMonths(config.showMonths);
			}),
		);
	}

	selectInitialDates() {
		const departureDate = dayjs(this.initialArrivalDate)
			.add(this.initialNumberOfNights, 'days')
			.toDate();

		if (
			this.isArrivalDateAvailable(this.initialArrivalDate) &&
			this.isDepartureDateAvailable(
				this.initialArrivalDate,
				departureDate,
			)
		) {
			this.flatpickrFacade.setDatesAndUpdateNights([
				this.initialArrivalDate,
				departureDate,
			]);
		} else {
			this.initialDatesNotAvailable.emit();
		}
	}

	filterCalendarData(): Observable<string[]> {
		return combineLatest([
			this.recalculateCalendarData$,
			this.selectedDates$,
		]).pipe(
			map(([_, selectedDates]) => {
				this.checkForInvalidDates(selectedDates);

				if (selectedDates.length === 1) {
					const arrivalDateKey = this.formatDate(
						selectedDates[0],
						'YYYY-MM-DD',
					);
					const checkoutDates = Object.keys(
						this.availability[arrivalDateKey],
					);
					return this.filterOutOfRangeDepartureDates(
						checkoutDates,
						selectedDates,
					);
				} else if (this.availability) {
					return Object.keys(this.availability);
				} else {
					return [];
				}
			}),
		);
	}

	checkForInvalidDates(selectedDates: Date[]) {
		if (
			(selectedDates.length > 0 &&
				!this.isArrivalDateAvailable(selectedDates[0])) ||
			(selectedDates.length > 1 &&
				!this.isDepartureDateAvailable(
					selectedDates[0],
					selectedDates[1],
				))
		) {
			this.flatpickrFacade.clearFlatpickrInstance();
		}
	}

	isArrivalDateAvailable(arrivalDate: Date): boolean {
		const arrivalDateKey = this.formatDate(arrivalDate, 'YYYY-MM-DD');
		return !!this.availability[arrivalDateKey];
	}

	isDepartureDateAvailable(arrivalDate: Date, departureDate: Date): boolean {
		const arrivalDateKey = this.formatDate(arrivalDate, 'YYYY-MM-DD');
		const departureDateKey = this.formatDate(departureDate, 'YYYY-MM-DD');
		return !!this.availability[arrivalDateKey][departureDateKey];
	}

	filterOutOfRangeDepartureDates(
		dateStrings: string[],
		selectedDates: Date[],
	): string[] {
		const minRangeDate = this.addDaysToDate(
			selectedDates[0],
			this.minNights,
		);
		const maxRangeDate = this.addDaysToDate(
			selectedDates[0],
			this.maxNights,
		);
		return dateStrings?.filter((date) => {
			return !(
				dayjs(date).isBefore(minRangeDate) ||
				dayjs(date).isAfter(maxRangeDate)
			);
		});
	}
}
