import { generateRandomId, setLabel, merge, getDuration, getTime } from '../common/utils';
import { NAME_UNKNOWN, TYPE_CUSTOM } from '../common/constants';
var SpanBase = function () {
  function SpanBase(name, type, options) {
    if (options === void 0) {
      options = {};
    }
    if (!name) {
      name = NAME_UNKNOWN;
    }
    if (!type) {
      type = TYPE_CUSTOM;
    }
    this.name = name;
    this.type = type;
    this.options = options;
    this.id = options.id || generateRandomId(16);
    this.traceId = options.traceId;
    this.sampled = options.sampled;
    this.sampleRate = options.sampleRate;
    this.timestamp = options.timestamp;
    this._start = getTime(options.startTime);
    this._end = undefined;
    this.ended = false;
    this.outcome = undefined;
    this.onEnd = options.onEnd;
  }
  var _proto = SpanBase.prototype;
  _proto.ensureContext = function ensureContext() {
    if (!this.context) {
      this.context = {};
    }
  };
  _proto.addLabels = function addLabels(tags) {
    this.ensureContext();
    var ctx = this.context;
    if (!ctx.tags) {
      ctx.tags = {};
    }
    var keys = Object.keys(tags);
    keys.forEach(function (k) {
      return setLabel(k, tags[k], ctx.tags);
    });
  };
  _proto.addContext = function addContext() {
    for (var _len = arguments.length, context = new Array(_len), _key = 0; _key < _len; _key++) {
      context[_key] = arguments[_key];
    }
    if (context.length === 0) return;
    this.ensureContext();
    merge.apply(void 0, [this.context].concat(context));
  };
  _proto.end = function end(endTime) {
    if (this.ended) {
      return;
    }
    this.ended = true;
    this._end = getTime(endTime);
    this.callOnEnd();
  };
  _proto.callOnEnd = function callOnEnd() {
    if (typeof this.onEnd === 'function') {
      this.onEnd(this);
    }
  };
  _proto.duration = function duration() {
    return getDuration(this._start, this._end);
  };
  return SpanBase;
}();
export default SpanBase;