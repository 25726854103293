<ul class="flex flex-col">
	@for (link of linkListItems(); track $index) {
		<li
			[attr.data-test-id]="link.id"
			[ngClass]="{
				'border-ui-150': !$last && showDivider(),
				'border-t': $first && showBorderTop(),
			}"
			class="border-b border-transparent p-4"
			data-test-class="list-item"
		>
			@if (!link.isExternal) {
				<a
					[routerLink]="[link.url]"
					class="flex w-full flex-row items-center hover:text-black"
					data-test-class="link-list-item"
				>
					<ng-container
						[ngTemplateOutlet]="linkContent"
						[ngTemplateOutletContext]="{ link: link }"
					/>
				</a>
			} @else {
				<a
					[href]="link.url"
					[target]="link.isExternal ? '_blank' : '_self'"
					class="flex w-full flex-row items-center hover:text-black"
					data-test-class="link-list-item"
				>
					<ng-container
						[ngTemplateOutlet]="linkContent"
						[ngTemplateOutletContext]="{ link: link }"
					/>
				</a>
			}
		</li>
	}
</ul>

<ng-template #linkContent let-link="link">
	@if (showIcons()) {
		<div class="mr-2 flex min-w-5 md:min-w-6">
			<svg-icon [src]="link.icon" class="size-5 md:size-6" />
		</div>
	}
	<span class="flex w-full flex-grow text-base md:text-lg">
		{{ link.text | translate }}
	</span>
	@if (useChevron()) {
		<div
			class="w-fit min-w-5 justify-end whitespace-nowrap md:min-w-6"
			data-test-class="chevron"
		>
			<svg-icon
				class="size-5 md:size-6"
				src="assets/icons/icon-chevron-right.svg"
			/>
		</div>
	}
	@if (link.tagText && !useChevron()) {
		<div class="w-fit justify-end whitespace-nowrap" data-test-class="tag">
			<vp-tag [isEmphasized]="true" color="accent">
				{{ link.tagText }}
			</vp-tag>
		</div>
	}
</ng-template>
