<section
	[editableInStoryblok]="_editable"
	class="section-spacing"
	data-test-class="items-slider-section"
>
	<div class="section-gap">
		<sb-columns
			[colOne]="title"
			[fullWidth]="true"
			class="mx-auto max-w-3xl"
			component="columnOne"
		/>
		@if (itemSliderItems().length > 0) {
			<div class="-mx-8 max-w-5xl lg:mx-auto lg:w-full">
				<vp-items-slider [items]="itemSliderItems()" [uid]="_uid" />
			</div>
		}
	</div>
</section>
