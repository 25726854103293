<div class="flex flex-col px-8 pb-0 pt-3.5 2xl:px-10" data-test-id="desktop">
	<div
		class="mx-auto flex w-full max-w-screen-2xl flex-col gap-4 px-3 md:px-8 2xl:px-10"
	>
		<div class="flex">
			<vp-link
				[link]="logoLink"
				className="mr-6 mt-2 flex"
				data-test-id="header-logo"
				type="link-unstyled"
			>
				<img
					[src]="
						isNegativeHeader
							? 'assets/icons/logo-valk-white.svg'
							: 'assets/icons/logo-valk-black.svg'
					"
					alt="Van der Valk Logo"
					class="h-12 w-[42px]"
					height="48"
					width="42"
				/>
				<div
					[ngClass]="isNegativeHeader ? 'text-white' : 'text-black'"
					class="ml-3 flex flex-col border-l pl-3 font-logo uppercase"
				>
					<span
						[ngClass]="{ 'mt-3': !title }"
						class="mb-0.5 block text-2xl leading-none"
						data-test-id="brand-name"
					>
						{{ 'global.brand-name' | translate }}
					</span>
					@if (title) {
						<span
							class="block text-[32px] font-semibold leading-none"
							data-test-id="title"
						>
							{{ title }}
						</span>
					}
				</div>
			</vp-link>
			@if (metaMenu) {
				<div
					[ngClass]="isNegativeHeader ? 'text-white' : 'text-black'"
					class="ml-auto flex gap-x-4"
					data-test-id="meta-menu"
				>
					@if (metaMenu.contactLink.url) {
						<vp-link
							[link]="metaMenu.contactLink"
							className="my-1.5 block h-fit"
							type="link-unstyled"
						>
							{{ 'compositions.header.contact' | translate }}
						</vp-link>
					}
					@if (metaMenu.accountLink.url) {
						<vp-link
							[link]="metaMenu.accountLink"
							className="my-1.5 block h-fit"
							type="link-unstyled"
						>
							{{ 'compositions.header.account' | translate }}
						</vp-link>
					}
					@if (metaMenu.showSearch) {
						<button class="my-1.5 h-fit">
							{{ 'compositions.header.search' | translate }}
						</button>
					}
					@if (metaMenu.languages?.options) {
						<vp-dropdown
							[centered]="true"
							[inputId]="'language'"
							[isWhite]="isNegativeHeader"
							[label]="'global.language-using.label' | translate"
							[placeholder]="
								'global.dutch-abbreviation' | translate
							"
							[selectData]="metaMenu.languages.options"
							[useAsFormControl]="false"
							[value]="
								metaMenu.languages?.selectedLanguageOption.path
							"
							(selectedValue)="languageSwitch.emit($event)"
							className="my-1.5 h-fit"
							variant="detached"
						/>
					}
					@if (metaMenu.cta?.title && metaMenu.cta.url) {
						<vp-link
							[link]="metaMenu.cta"
							className="btn-sm h-fit"
							type="button"
						>
							{{ metaMenu.cta.title }}
						</vp-link>
					}
				</div>
			}
		</div>
		<div class="flex">
			<ul
				class="flex h-fit flex-wrap items-center gap-x-2"
				data-test-id="desktop-menu"
			>
				@for (menuItem of menuItems; track menuItem) {
					<li
						[ngClass]="
							isNegativeHeader
								? 'text-white hover:border-white'
								: 'text-black hover:border-black'
						"
						[routerLinkActive]="'active'"
						[routerLinkActiveOptions]="{
							exact: menuItem.title.toLowerCase() === 'home',
						}"
						class="flex h-full items-center border-b-2 border-transparent p-2 text-lg"
						data-test-class="desktop-menu-item"
					>
						<a
							[routerLink]="
								!menuItem.url.startsWith('http')
									? menuItem.url
									: null
							"
							type="link-unstyled"
						>
							{{ menuItem.title }}
						</a>
					</li>
				}
			</ul>
		</div>
	</div>
</div>
