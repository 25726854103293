export interface InputInterface {
	autocomplete: string;
	defaultStyle: boolean;
	disabled: boolean;
	hasError: boolean;
	icon: string;
	iconPosition: IconPosition;
	initialValue: string;
	isValid: boolean;
	name: string;
	placeholder: string;
	readonly: boolean;
	required: boolean;
	type: string;
}

export enum IconPosition {
	Left = 'left',
	Right = 'right',
}
