import { NgClass } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { CheckboxComponent } from '@valk-nx/components/ui-checkbox/src/lib/checkbox';
import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { CheckedOption } from '@valk-nx/core/lib/interfaces/form.interface';

import { CheckboxOption, CheckboxOptions } from './checkbox.interface';

@Component({
	exportAs: 'vpCheckbox',
	standalone: true,
	selector: 'vp-checkbox-group',
	templateUrl: './checkbox-group.html',
	imports: [NgClass, CheckboxComponent, TranslateModule],
})
export class CheckboxGroupComponent
	extends BaseDirective
	implements OnInit, OnChanges
{
	@Input({ required: true }) options: CheckboxOptions[] = [];
	@Input() horizontal = false;
	@Input() autocomplete: string | null = null;
	@Input() name = '';
	@Input() readonly = false;
	@Input() disabled = false;
	@Input() hasError = false;
	@Input() isValid = false;
	@Input() isOptional = true;
	@Input() shouldTranslate = false;
	@Input() customFormControl = new FormControl();
	@Input() isRequired = false;

	@Output() checkBoxGroupValues = new EventEmitter<CheckedOption[]>();

	checkboxOptions: CheckedOption[] = [];

	ngOnInit() {
		if (this.isRequired || !this.isOptional) {
			this.customFormControl.addValidators(this.requiredMinimumChecked());
			this.customFormControl.updateValueAndValidity();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['options']?.firstChange) {
			this.checkboxOptions = changes['options'].currentValue.map(
				(option: CheckboxOption) => {
					return {
						inputId: option.inputId,
						checked: option.initialValue,
					};
				},
			);

			this.customFormControl.setValue(this.checkboxOptions);
		}
	}

	checkboxChangedValue(id: string, value: boolean): void {
		this.checkboxOptions = this.checkboxOptions.map((option) => {
			if (option.inputId === id) {
				return {
					inputId: option.inputId,
					checked: value,
				};
			}
			return option;
		});

		this.checkBoxGroupValues.emit(this.checkboxOptions);
		this.customFormControl.setValue(this.checkboxOptions);
	}

	requiredMinimumChecked(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (
				control?.value?.filter(
					(option: CheckedOption) => option.checked,
				).length === 0
			) {
				return { required: true };
			}
			return null;
		};
	}
}
