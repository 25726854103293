import plugin from 'tailwindcss/plugin';

const withAlpha = (color, alpha) => {
	return `${color.substring(0, color.indexOf(')'))},${alpha}${color
		.substring(color.indexOf(')'))
		.replace('rgb', 'rgba')}`;
};

const overlay = plugin(
	function ({ addComponents, theme, e }) {
		const opacities = theme('overlayOpacities');
		const colors = theme('overlayColors');

		addComponents([
			...Object.keys(colors).map((color) => {
				return Object.keys(opacities).map((opacity) => {
					let modifier = '';
					if (opacity !== 'DEFAULT') {
						modifier = `-${opacity}`;
					}
					const overlayClass = e(`overlay-${color}${modifier}`);
					return {
						[`.${overlayClass}`]: {
							background: withAlpha(
								colors[color],
								opacities[opacity],
							),
						},
					};
				});
			}),
			{
				'.overlay-none': {
					background: 'none',
				},
			},
		]);
	},
	{
		theme: {
			overlayColors: {
				dark: 'rgb(0,0,0)',
				light: 'rgb(255,255,255)',
			},
			overlayOpacities: {
				DEFAULT: '1',
				1: '0.75',
				2: '0.65',
				3: '0.55',
			},
		},
	},
);

export default overlay;
