function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import { getCurrentScript, setLabel, merge, extend, isUndefined } from './utils';
import EventHandler from './event-handler';
import { CONFIG_CHANGE, LOCAL_CONFIG_KEY } from './constants';
function getConfigFromScript() {
  var script = getCurrentScript();
  var config = getDataAttributesFromNode(script);
  return config;
}
function getDataAttributesFromNode(node) {
  if (!node) {
    return {};
  }
  var dataAttrs = {};
  var dataRegex = /^data-([\w-]+)$/;
  var attrs = node.attributes;
  for (var i = 0; i < attrs.length; i++) {
    var attr = attrs[i];
    if (dataRegex.test(attr.nodeName)) {
      var key = attr.nodeName.match(dataRegex)[1];
      var camelCasedkey = key.split('-').map(function (value, index) {
        return index > 0 ? value.charAt(0).toUpperCase() + value.substring(1) : value;
      }).join('');
      dataAttrs[camelCasedkey] = attr.value || attr.nodeValue;
    }
  }
  return dataAttrs;
}
var Config = function () {
  function Config() {
    this.config = {
      serviceName: '',
      serviceVersion: '',
      environment: '',
      serverUrl: 'http://localhost:8200',
      serverUrlPrefix: '',
      active: true,
      instrument: true,
      disableInstrumentations: [],
      logLevel: 'warn',
      breakdownMetrics: false,
      ignoreTransactions: [],
      eventsLimit: 80,
      queueLimit: -1,
      flushInterval: 500,
      distributedTracing: true,
      distributedTracingOrigins: [],
      distributedTracingHeaderName: 'traceparent',
      pageLoadTraceId: '',
      pageLoadSpanId: '',
      pageLoadSampled: false,
      pageLoadTransactionName: '',
      propagateTracestate: false,
      transactionSampleRate: 1.0,
      centralConfig: false,
      monitorLongtasks: true,
      apiVersion: 2,
      context: {},
      session: false,
      apmRequest: null,
      sendCredentials: false
    };
    this.events = new EventHandler();
    this.filters = [];
    this.version = '';
  }
  var _proto = Config.prototype;
  _proto.init = function init() {
    var scriptData = getConfigFromScript();
    this.setConfig(scriptData);
  };
  _proto.setVersion = function setVersion(version) {
    this.version = version;
  };
  _proto.addFilter = function addFilter(cb) {
    if (typeof cb !== 'function') {
      throw new Error('Argument to must be function');
    }
    this.filters.push(cb);
  };
  _proto.applyFilters = function applyFilters(data) {
    for (var i = 0; i < this.filters.length; i++) {
      data = this.filters[i](data);
      if (!data) {
        return;
      }
    }
    return data;
  };
  _proto.get = function get(key) {
    return key.split('.').reduce(function (obj, objKey) {
      return obj && obj[objKey];
    }, this.config);
  };
  _proto.setUserContext = function setUserContext(userContext) {
    if (userContext === void 0) {
      userContext = {};
    }
    var context = {};
    var _userContext = userContext,
      id = _userContext.id,
      username = _userContext.username,
      email = _userContext.email;
    if (typeof id === 'number' || typeof id === 'string') {
      context.id = id;
    }
    if (typeof username === 'string') {
      context.username = username;
    }
    if (typeof email === 'string') {
      context.email = email;
    }
    this.config.context.user = extend(this.config.context.user || {}, context);
  };
  _proto.setCustomContext = function setCustomContext(customContext) {
    if (customContext === void 0) {
      customContext = {};
    }
    this.config.context.custom = extend(this.config.context.custom || {}, customContext);
  };
  _proto.addLabels = function addLabels(tags) {
    var _this = this;
    if (!this.config.context.tags) {
      this.config.context.tags = {};
    }
    var keys = Object.keys(tags);
    keys.forEach(function (k) {
      return setLabel(k, tags[k], _this.config.context.tags);
    });
  };
  _proto.setConfig = function setConfig(properties) {
    if (properties === void 0) {
      properties = {};
    }
    var _properties = properties,
      transactionSampleRate = _properties.transactionSampleRate,
      serverUrl = _properties.serverUrl;
    if (serverUrl) {
      properties.serverUrl = serverUrl.replace(/\/+$/, '');
    }
    if (!isUndefined(transactionSampleRate)) {
      if (transactionSampleRate < 0.0001 && transactionSampleRate > 0) {
        transactionSampleRate = 0.0001;
      }
      properties.transactionSampleRate = Math.round(transactionSampleRate * 10000) / 10000;
    }
    merge(this.config, properties);
    this.events.send(CONFIG_CHANGE, [this.config]);
  };
  _proto.validate = function validate(properties) {
    if (properties === void 0) {
      properties = {};
    }
    var requiredKeys = ['serviceName', 'serverUrl'];
    var allKeys = Object.keys(this.config);
    var errors = {
      missing: [],
      invalid: [],
      unknown: []
    };
    Object.keys(properties).forEach(function (key) {
      if (requiredKeys.indexOf(key) !== -1 && !properties[key]) {
        errors.missing.push(key);
      }
      if (allKeys.indexOf(key) === -1) {
        errors.unknown.push(key);
      }
    });
    if (properties.serviceName && !/^[a-zA-Z0-9 _-]+$/.test(properties.serviceName)) {
      errors.invalid.push({
        key: 'serviceName',
        value: properties.serviceName,
        allowed: 'a-z, A-Z, 0-9, _, -, <space>'
      });
    }
    var sampleRate = properties.transactionSampleRate;
    if (typeof sampleRate !== 'undefined' && (typeof sampleRate !== 'number' || isNaN(sampleRate) || sampleRate < 0 || sampleRate > 1)) {
      errors.invalid.push({
        key: 'transactionSampleRate',
        value: sampleRate,
        allowed: 'Number between 0 and 1'
      });
    }
    return errors;
  };
  _proto.getLocalConfig = function getLocalConfig() {
    var storage = sessionStorage;
    if (this.config.session) {
      storage = localStorage;
    }
    var config = storage.getItem(LOCAL_CONFIG_KEY);
    if (config) {
      return JSON.parse(config);
    }
  };
  _proto.setLocalConfig = function setLocalConfig(config, merge) {
    if (config) {
      if (merge) {
        var prevConfig = this.getLocalConfig();
        config = _extends({}, prevConfig, config);
      }
      var storage = sessionStorage;
      if (this.config.session) {
        storage = localStorage;
      }
      storage.setItem(LOCAL_CONFIG_KEY, JSON.stringify(config));
    }
  };
  _proto.dispatchEvent = function dispatchEvent(name, args) {
    this.events.send(name, args);
  };
  _proto.observeEvent = function observeEvent(name, fn) {
    return this.events.observe(name, fn);
  };
  return Config;
}();
export default Config;