import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';
import { Observable } from 'rxjs';

import { PageComponent } from '@valk-nx/storyblok/components/page/src/lib/page.component';
import { MeetingroomOverviewComponent } from '@valk-nx/storyblok/pages/meetingroom-overview/src/lib/meetingroom-overview.component';
import { PackageDetailComponent } from '@valk-nx/storyblok/pages/package-detail/src/lib/package-detail.component';
import { PackageOverviewComponent } from '@valk-nx/storyblok/pages/package-overview/src/lib/package-overview.component';
import { RoomDetailComponent } from '@valk-nx/storyblok/pages/room-detail/src/lib/room-detail.component';
import { RoomOverviewComponent } from '@valk-nx/storyblok/pages/room-overview/src/lib/room-overview.component';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

const Components = {
	packageDetail: PackageDetailComponent,
	packageOverview: PackageOverviewComponent,
	page: PageComponent,
	meetingroomOverview: MeetingroomOverviewComponent,
	roomDetail: RoomDetailComponent,
	roomOverview: RoomOverviewComponent,
};
@Component({
	selector: 'sb-root',
	templateUrl: './root.component.html',
	standalone: true,
	imports: [CommonModule, DynamicModule, StoryblokDirective],
})
export class SbRootComponent {
	readonly story$: Observable<ISbStoryData>;
	components = Components;

	constructor(private sbService: StoryblokService) {
		this.story$ = this.sbService.story$;
	}
}
