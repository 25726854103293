<header
	[ngClass]="{ 'bg-white md:bg-transparent': isNegativeHeader }"
	class="header sticky top-0 z-20 md:relative"
	data-test-id="header"
>
	<vp-header-desktop
		[isNegativeHeader]="isNegativeHeader"
		[language]="translate.currentLang"
		[menuItems]="menuItems"
		[metaMenu]="metaMenu"
		[title]="title"
		(languageSwitch)="onLanguageSwitch($event)"
		class="hidden md:block"
	/>
	<vp-header-mobile
		[menuItems]="menuItems"
		[metaMenu]="metaMenu"
		[title]="title"
		(languageSwitch)="onLanguageSwitch($event)"
		class="md:hidden"
	/>
</header>
