/* istanbul ignore file */

import { Directive, Input, TemplateRef } from '@angular/core';
/**
 * Used for Storybook to prevent errors cannot find directive
 */
@Directive({
	selector: 'vp-tab-menu-item, [vp-tab-menu-item], [vpTabMenuItem]',
	standalone: true,
})
export class TabMenuItemDirective {
	@Input({ required: true }) label = '';
	@Input({ required: true }) name = '';
	@Input({ required: true }) content: TemplateRef<unknown> | null = null;
}
