<div
	[ngClass]="{
		'pricing-sm': size === 'sm',
		'pricing-md': size === 'base',
		'pricing-lg': size === 'lg',
		'pricing-xl': size === 'xl'
	}"
	class="pricing"
>
	<div class="pricing-combined" data-test-id="price">
		@if (fromPrice > 0) {
			<vp-price
				[currency]="currency"
				[locale]="translate.currentLang"
				[ngClass]="{
					'self-end':
						alignment === 'self-end' && isHorizontal === false,
					'self-start':
						(alignment === 'self-start' &&
							isHorizontal === false) ||
						isHorizontal === true
				}"
				[price]="fromPrice"
				[striked]="true"
			/>
		}
		<div [class]="alignment" class="flex">
			<vp-price
				[currency]="currency"
				[locale]="translate.currentLang"
				[price]="price"
				[size]="size"
			/>
		</div>

		@if (isHorizontal === false) {
			<ng-container [ngTemplateOutlet]="conditionsAndIndication" />
		}
	</div>

	@if (isHorizontal === true) {
		<ng-container [ngTemplateOutlet]="conditionsAndIndication" />
	}
</div>

<ng-template #conditionsAndIndication>
	@if (conditions) {
		<div
			[ngClass]="{
				'text-right': alignment === 'self-end'
			}"
			class="pricing-conditions"
			data-test-id="price-label"
		>
			@if (indication) {
				<span> {{ 'components.pricing.indication' | translate }} </span>
			}
			{{ conditions }}
		</div>
	}
</ng-template>
