<ul
	[ngClass]="{
		'control-segmented-horizontal': forceHorizontal,
		'[@media(min-width:408px)]:grid-cols-2': !forceHorizontal,
		'!border-error-100': hasError && !disabled,
		'!border-success-100': isValid && !disabled && value,
		'opacity-50': disabled,
	}"
	class="control-segmented"
	data-test-id="segmented-control"
>
	@for (option of segmentedOptions; track option; let i = $index) {
		<li
			[ngClass]="{
				'!border-error-100': hasError && !disabled,
				'!border-success-100':
					isValid && !disabled && value === option.value,
			}"
			class="control-segmented-item"
		>
			<input
				[attr.data-test-id]="id + '-option-' + option.value"
				[checked]="value === option.value"
				[disabled]="disabled"
				[id]="id + '-option-' + option.value"
				[name]="id + '-option'"
				[value]="option.value"
				(change)="selectLabel(option.value)"
				class="control-segmented-peer absolute opacity-0"
				type="radio"
			/>
			<label
				[for]="id + '-option-' + option.value"
				[ngClass]="{ 'cursor-not-allowed': disabled }"
				(click)="selectLabel(option.value)"
				class="control-segmented-label"
			>
				{{ option.name | translate }}
			</label>
		</li>
	}
</ul>
