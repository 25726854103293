import { IconPosition, InputInterface } from './input.interface';

export const datePickerInputConfig: InputInterface = {
	autocomplete: 'off',
	defaultStyle: true,
	disabled: false,
	hasError: false,
	icon: 'assets/icons/icon-calendar.svg',
	iconPosition: IconPosition.Left,
	initialValue: '',
	isValid: false,
	name: '',
	placeholder: '',
	readonly: true,
	required: false,
	type: 'text',
};
