import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SheetModule } from '@valk-nx/components/ui-sheet/src/lib/sheet.module';
import { ClickOutsideModule } from '@valk-nx/core/lib/directives/click-outside/click-outside.module';

import { TooltipComponent } from './tooltip.component';

@NgModule({
	declarations: [TooltipComponent],
	exports: [TooltipComponent],
	imports: [
		CommonModule,
		AngularSvgIconModule.forRoot(),
		LetDirective,
		SheetModule,
		ClickOutsideModule,
		TranslateModule.forChild(),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TooltipModule {}
