import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AlertComponent } from '@valk-nx/components/ui-alert/src/lib/alert.component';
import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import { Language } from '@valk-nx/core/lib/core';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { GiftcardService } from '@valk-nx/storyblok-services/src/lib/services/giftcard.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

import {
	form as formFields,
	orderFormGroup,
} from './order-giftcard-business-form';

@Component({
	selector: 'sb-order-giftcard-business',
	templateUrl: './order-giftcard-business.html',
	standalone: true,
	imports: [
		AlertComponent,
		CommonModule,
		StoryblokDirective,
		CommonModule,
		RecaptchaNotificationComponent,
		TranslateModule,
		SharedFormlyModule,
	],
})
export class OrderGiftcardBusinessComponent extends StoryblokRootDirective {
	@ViewChild('requestForm') requestForm!: NgForm;

	get fullName() {
		return `${this.form.value.firstName} ${
			this.form.value.insertion || ''
		} ${this.form.value.lastName}`;
	}

	formFields = formFields;
	form = orderFormGroup;
	options: FormlyFormOptions = {};
	/* eslint-disable  @typescript-eslint/no-explicit-any */
	model: any = {};

	language: Language;

	requestSubmitted = false;
	requestSubmitting = false;

	constructor(
		private readonly cd: ChangeDetectorRef,
		private readonly translate: TranslateService,
		private readonly giftcardService: GiftcardService,
		private readonly tagManagerFacade: TagManagerFacade,
	) {
		super();
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;
	}

	async onSubmit(): Promise<void> {
		if (this.form.valid) {
			this.requestSubmitting = true;

			this.giftcardService
				.createBusinessOrder(
					{
						firstName: this.form.value.firstName,
						insertion: this.form.value.insertion || '',
						lastName: this.form.value.lastName,
						phoneNumber: this.form.value.phoneNumber,
						emailAddress: this.form.value.emailAddress,
						companyName: this.form.value.companyName,
					},
					this.form.value.request,
				)
				.then((response) => {
					this.requestSubmitting = false;
					this.requestSubmitted = response.success;

					this.tagManagerFacade.giftcardOrderB2B(
						this.form.value.emailAddress,
						this.form.value.phoneNumber,
						this.form.value.companyName,
					);
					this.cd.detectChanges();
				})
				.catch(() => {
					this.requestSubmitting = false;
					this.cd.detectChanges();
				});
		} else {
			this.scrollToError();
		}
	}

	resetRequestForm(): void {
		this.requestSubmitted = false;
		this.requestSubmitting = false;

		this.form.reset();
		this.requestForm.resetForm();
	}

	scrollToError() {
		setTimeout(() => {
			GeneralHelper.scrollToElementWithClass(
				'form-field-error',
				undefined,
				'input',
			);
		}, 100);
	}
}
