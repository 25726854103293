var _excluded = ["tags"];
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
import { Url } from './url';
import { PAGE_LOAD, PAGE_EXIT, NAVIGATION } from './constants';
import { getServerTimingInfo, PERF, isPerfTimelineSupported } from './utils';
var LEFT_SQUARE_BRACKET = 91;
var RIGHT_SQUARE_BRACKET = 93;
var EXTERNAL = 'external';
var RESOURCE = 'resource';
var HARD_NAVIGATION = 'hard-navigation';
function getPortNumber(port, protocol) {
  if (port === '') {
    port = protocol === 'http:' ? '80' : protocol === 'https:' ? '443' : '';
  }
  return port;
}
function getResponseContext(perfTimingEntry) {
  var transferSize = perfTimingEntry.transferSize,
    encodedBodySize = perfTimingEntry.encodedBodySize,
    decodedBodySize = perfTimingEntry.decodedBodySize,
    serverTiming = perfTimingEntry.serverTiming;
  var respContext = {
    transfer_size: transferSize,
    encoded_body_size: encodedBodySize,
    decoded_body_size: decodedBodySize
  };
  var serverTimingStr = getServerTimingInfo(serverTiming);
  if (serverTimingStr) {
    respContext.headers = {
      'server-timing': serverTimingStr
    };
  }
  return respContext;
}
function getDestination(parsedUrl) {
  var port = parsedUrl.port,
    protocol = parsedUrl.protocol,
    hostname = parsedUrl.hostname;
  var portNumber = getPortNumber(port, protocol);
  var ipv6Hostname = hostname.charCodeAt(0) === LEFT_SQUARE_BRACKET && hostname.charCodeAt(hostname.length - 1) === RIGHT_SQUARE_BRACKET;
  var address = hostname;
  if (ipv6Hostname) {
    address = hostname.slice(1, -1);
  }
  return {
    service: {
      resource: hostname + ':' + portNumber,
      name: '',
      type: ''
    },
    address: address,
    port: Number(portNumber)
  };
}
function getResourceContext(data) {
  var entry = data.entry,
    url = data.url;
  var parsedUrl = new Url(url);
  var destination = getDestination(parsedUrl);
  return {
    http: {
      url: url,
      response: getResponseContext(entry)
    },
    destination: destination
  };
}
function getExternalContext(data) {
  var url = data.url,
    method = data.method,
    target = data.target,
    response = data.response;
  var parsedUrl = new Url(url);
  var destination = getDestination(parsedUrl);
  var context = {
    http: {
      method: method,
      url: parsedUrl.href
    },
    destination: destination
  };
  var statusCode;
  if (target && typeof target.status !== 'undefined') {
    statusCode = target.status;
  } else if (response) {
    statusCode = response.status;
  }
  context.http.status_code = statusCode;
  return context;
}
function getNavigationContext(data) {
  var url = data.url;
  var parsedUrl = new Url(url);
  var destination = getDestination(parsedUrl);
  return {
    destination: destination
  };
}
export function getPageContext() {
  return {
    page: {
      referer: document.referrer,
      url: location.href
    }
  };
}
export function addSpanContext(span, data) {
  if (!data) {
    return;
  }
  var type = span.type;
  var context;
  switch (type) {
    case EXTERNAL:
      context = getExternalContext(data);
      break;
    case RESOURCE:
      context = getResourceContext(data);
      break;
    case HARD_NAVIGATION:
      context = getNavigationContext(data);
      break;
  }
  span.addContext(context);
}
export function addTransactionContext(transaction, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
    tags = _ref.tags,
    configContext = _objectWithoutPropertiesLoose(_ref, _excluded);
  var pageContext = getPageContext();
  var responseContext = {};
  if (transaction.type === PAGE_EXIT) {
    transaction.ensureContext();
    if (transaction.context.page && transaction.context.page.url) {
      pageContext.page.url = transaction.context.page.url;
    }
  } else if (transaction.type === PAGE_LOAD && isPerfTimelineSupported()) {
    var entries = PERF.getEntriesByType(NAVIGATION);
    if (entries && entries.length > 0) {
      responseContext = {
        response: getResponseContext(entries[0])
      };
    }
  }
  transaction.addContext(pageContext, responseContext, configContext);
}