import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { StatusHeaderComponent } from './status-header';
@NgModule({
	declarations: [StatusHeaderComponent],
	exports: [StatusHeaderComponent],
	imports: [CommonModule, AngularSvgIconModule.forRoot()],
	providers: [provideHttpClient()],
})
export class StatusHeaderModule {}
