import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { debounceTime } from 'rxjs';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'vpInput',
	host: {
		'[attr.disabled]': 'disabled || null',
		'[class.is-disabled]': 'disabled',
	},
	standalone: true,
	selector: `vp-input`,
	templateUrl: './input.html',
	styleUrls: ['./input.scss'],
	imports: [
		AngularSvgIconModule,
		FormsModule,
		NgClass,
		NgxMaskDirective,
		ReactiveFormsModule,
		TranslateModule,
	],
	providers: [provideNgxMask()],
})
export class InputComponent implements OnInit, OnChanges {
	_disabled = false;
	@Input() set disabled(disabledVal: boolean) {
		if (this.formControl) {
			if (disabledVal) {
				this.formControl.disable();
			} else {
				this.formControl.enable();
			}
		}
		this._disabled = disabledVal;
	}
	get disabled() {
		return this._disabled;
	}

	@Input() autocomplete = 'on';
	@Input() defaultStyle = false;
	@Input() hasError = false;
	@Input() icon = '';
	@Input() isValid = false;
	@Input() iconPosition: 'left' | 'right' = 'left';
	@Input({ required: true }) name!: string;
	@Input() placeholder = '';
	@Input() readonly = false;
	@Input() required = false;
	@Input() type: 'text' | 'number' | 'email' | 'password' | null = 'text';
	@Input() initialValue = '';
	@Input() forceInitialValue = false;
	@Input({ required: true }) inputId!: string;
	@Input() isOptional = false;
	@Input() isLoading = false;
	@Input() maskOptions: {
		mask: string;
		prefix: string;
		showMaskedType: boolean;
	} = {
		mask: '',
		prefix: '',
		showMaskedType: false,
	};

	@Output() emittedValue = new EventEmitter<Event>();

	@Input() formControl = new FormControl();

	showPassword = false;

	constructor() {
		this.formControl.valueChanges
			.pipe(takeUntilDestroyed(), debounceTime(500))
			.subscribe((value) => {
				this.emittedValue.emit(value);
			});
	}
	ngOnInit(): void {
		this.formControl.setValue(this.initialValue);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['initialValue'] || this.forceInitialValue) {
			const formValue = this.forceInitialValue
				? changes['initialValue']?.currentValue || ''
				: changes['initialValue'].currentValue;

			this.formControl.setValue(formValue);
		}

		if (!this.type) {
			this.type = 'text';
		}
	}

	togglePasswordView(): void {
		this.showPassword = !this.showPassword;
	}
}
