import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	contentChildren,
	CUSTOM_ELEMENTS_SCHEMA,
	input,
	model,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

import { TabMenuItemDirective } from './tab-menu.directive';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-tab-menu`,
	templateUrl: './tab-menu.html',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		TabMenuItemDirective,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TabMenuComponent {
	activeTab = model(0);

	useButtonVariant = input(false);
	useScrollOnChange = input(false);
	useRouter = input(false);
	// Is only being used if useButtonVariant is true
	numberOfColumns = input<2 | 3 | 4>(3);
	// Is only being used if useButtonVariant is true
	hideTabs = input(false);

	tabMenuItems = contentChildren(TabMenuItemDirective);

	// Is only being used if useButtonVariant is true
	numberOfColumbsTab = computed<string>(() => {
		switch (this.numberOfColumns()) {
			case 2:
				return 'md:grid-cols-2';
			case 3:
				return 'md:grid-cols-3';
			case 4:
				return 'md:grid-cols-4';
		}
	});

	tabNames = computed<string[]>(
		() => this.tabMenuItems()?.map((item) => item.name) || [],
	);

	constructor(private readonly router: Router) {}

	setActiveTab(tabIndex: number) {
		this.activeTab.set(tabIndex);
		this.setHash(`#${this.tabNames()[this.activeTab()]}`);
	}

	setHash(hash: string): void {
		const url = this.router?.url.split('#')[0];

		if (this.useRouter()) {
			this.router.navigateByUrl(url + hash);
		} else {
			window.history.pushState(null, '', url + hash);
		}

		if (this.useScrollOnChange()) {
			setTimeout(() => {
				GeneralHelper.scrollToElementWithId(
					hash.substring(1),
					200,
					false,
				);
			});
		}
	}
}
