import { AbstractControl } from '@angular/forms';

export function passwordMatchValidator(control: AbstractControl): {
	passwordMatch: {
		message: string;
	};
} | null {
	const { password, confirmPassword } = control.value;
	// avoid displaying the message error when values are empty
	if (!confirmPassword || !password) {
		return null;
	}

	if (confirmPassword === password) {
		return null;
	}

	return { passwordMatch: { message: 'fields-do-not-match' } };
}
