import { Language } from '@valk-nx/core/lib/core';

export interface CookieManagerTranslation {
	title: string;
	content: string;
	extra: {
		content: string;
		link: string;
		url: string;
	};
	buttons: {
		contentText: string;
		footerText: string;
	};
	links: {
		footerText: string;
		contentText: string;
	};
	cookies: {
		analytics: {
			header: string;
			content: string;
		};
		functional: {
			header: string;
			content: string;
		};
		marketing: {
			header: string;
			content: string;
		};
	};
	active: string;
	inactive: string;
	showDetails: string;
	hideDetails: string;
}

export const defaultTranslations: Record<Language, CookieManagerTranslation> = {
	ca: {
		title: 'PREFERÈNCIES DE COOKIES',
		content:
			'Utilitzem cookies funcionals, analítiques i/o de màrqueting per millorar la seva experiència a la nostra pàgina web i mostrar anuncis personalitzats.',
		extra: {
			content:
				'Per obtenir més informació sobre les cookies llegiu la nostra',
			link: 'declaració de cookies i privacitat',
			url: 'https://www.valkverrast.nl/en/privacy',
		},
		buttons: {
			contentText: 'Acceptar las cookies',
			footerText: 'Acceptar totes les cookies',
		},
		links: {
			footerText: 'Desar ajustaments',
			contentText: 'Configuració de cookies',
		},
		cookies: {
			analytics: {
				header: 'Cookies analítiques',
				content:
					'Aquestes cookies recopilen dades anònimes perquè puguem millorar encara més la nostra pàgina web.',
			},
			functional: {
				header: 'Cookies funcionals',
				content:
					'Fem servir cookies funcionals per executar la funció bàsica de la web. Aquestes cookies mai recullen dades personals i sempre estan actives.',
			},
			marketing: {
				header: 'Cookies de màrqueting',
				content:
					'Aquestas cookies es poden utilitzar per mostrar pàgines personalitzades.',
			},
		},
		active: 'Actiu',
		inactive: 'Inactiu',
		showDetails: 'Mostrar detalls',
		hideDetails: 'Amagar',
	},
	nl: {
		title: 'COOKIE VOORKEUREN',
		content:
			'Wij maken gebruik van functionele, analytische en/of marketing cookies om uw ervaring op onze website nog beter te maken en gepersonaliseerde advertenties te tonen.',
		extra: {
			content: 'Voor meer uitleg lees ons',
			link: 'cookie- en privacyverklaring',
			url: 'https://www.valkverrast.nl/privacy',
		},
		buttons: {
			contentText: 'Cookies accepteren',
			footerText: 'Accepteer alle cookies',
		},
		links: {
			footerText: 'Instellingen opslaan',
			contentText: 'Cookie instellingen',
		},
		cookies: {
			analytics: {
				header: 'Analytische cookies',
				content:
					'Deze cookies verzamelen anoniem gegevens zodat we onze website verder kunnen verbeteren.',
			},
			functional: {
				header: 'Functionele cookies',
				content:
					'Functionele cookies gebruiken we om de basisfunctie van de site te kunnen laten draaien. Deze cookies verzamelen nooit persoonsgegevens en zijn altijd actief.',
			},
			marketing: {
				header: 'Marketing cookies',
				content:
					'Deze cookies worden gebruikt om gepersonaliseerde paginas te tonen.',
			},
		},
		active: 'Actief',
		inactive: 'Inactief',
		showDetails: 'Toon details',
		hideDetails: 'Verbergen',
	},
	en: {
		title: 'COOKIE PREFERENCES',
		content:
			'We use functional, analytical and/or marketing cookies to improve your experience on our website and display personalized advertisements.',
		extra: {
			content: 'For more explanation about cookies read our',
			link: 'cookie and privacy statement',
			url: 'https://www.valkverrast.nl/en/privacy',
		},
		buttons: {
			contentText: 'Accept cookies',
			footerText: 'Accept all cookies',
		},
		links: {
			footerText: 'Save settings',
			contentText: 'Cookie settings',
		},
		cookies: {
			analytics: {
				header: 'Analytical cookies',
				content:
					'These cookies collect anonymousdata so that we can further improve our website.',
			},
			functional: {
				header: 'Functional cookies cookies',
				content:
					'We use functional cookies to run the Cookie preferences basic function of the site. These cookies never collect personal data and are always active.',
			},
			marketing: {
				header: 'Marketing cookies',
				content:
					'These cookies can be used to display personalized pages.',
			},
		},
		active: 'Active',
		inactive: 'Inactive',
		showDetails: 'Show details',
		hideDetails: 'Hide',
	},
	es: {
		title: 'PREFERENCIAS DE COOKIES',
		content:
			'Utilizamos cookies funcionales, analíticas y/o de marketing para mejorar su experiencia en nuestra página web y mostrar anuncios personalizados y mostrar anuncios personalizados.',
		extra: {
			content:
				'Para obtener más información sobre las cookies, lea nuestra',
			link: 'declaración de cookies y privacidad',
			url: 'https://www.valkverrast.nl/en/privacy',
		},
		buttons: {
			contentText: 'Aceptar las cookies',
			footerText: 'Aceptar todas las cookies',
		},
		links: {
			footerText: 'Guardar ajustes',
			contentText: 'Configuración de cookies',
		},
		cookies: {
			analytics: {
				header: 'Cookies analíticas',
				content:
					'Estas cookies recopilan datos anónimos para que podamos mejorar aún más nuestra página web.',
			},
			functional: {
				header: 'Cookies funcionales',
				content:
					'Usamos cookies funcionales para ejecutar la función básica de la web. Estas cookies nunca recogen datos personales y siempre están activas.',
			},
			marketing: {
				header: 'Cookies de marketing',
				content:
					'Estas cookies se pueden utilizar para mostrar páginas personalizadas.',
			},
		},
		active: 'Activo',
		inactive: 'Inactivo',
		showDetails: 'Mostrar detalles',
		hideDetails: 'Ocultar',
	},
	de: {
		title: 'COOKIE-EINSTELLUNGEN',
		content:
			'Diese Website verwendet verschiedene Typen Cookies. Einzelne Cookies werden durch Drittanbieter gesetzt um Informationen auf unsere Website anzuzeigen und personalisierte Werbung anzuzeigen.',
		extra: {
			content: 'Für mehr Informationen über Cookies, lesen Sie unser',
			link: 'Cookie und Privacystatement',
			url: 'https://www.valkverrast.nl/de/privacy',
		},
		buttons: {
			contentText: 'Cookies akzeptieren',
			footerText: 'Alle Cookies akzeptieren',
		},
		links: {
			footerText: 'Einstellungen speichern',
			contentText: 'Cookie-Einstellungen',
		},
		cookies: {
			analytics: {
				header: 'Analytische Cookies',
				content:
					'Diese Cookies sammeln anonyme Daten um unsere Website weiter zu verbessern.',
			},
			functional: {
				header: 'Funktionelle Cookies',
				content:
					'Wir verwenden funktionelle Cookies um Basisfunktionalitäten der Website möglich zu machen. Diese Cookies sammeln nie persönliche Daten und sind immer aktiv.',
			},
			marketing: {
				header: 'Marketing Cookies',
				content:
					'Diese Cookies ermöglichen es um personalisierten Inhalt auf unserer Website anzuzeigen.',
			},
		},
		active: 'Aktiv',
		inactive: 'Inaktiv',
		showDetails: 'Details Zeigen',
		hideDetails: 'Verbergen',
	},
	fr: {
		title: 'PRÈFÈRENCES DES COOKIES',
		content:
			'Nous utilisons des cookies fonctionnels, analytiques et/ou marketing pour améliorer votre expérience sur notre site et afficher des publicités personnalisées.',
		extra: {
			content: "Pour plus d'informations sur les cookies, consultez",
			link: 'notre déclaration de confidentialité',
			url: 'https://www.valkverrast.nl/fr/privacy',
		},
		buttons: {
			contentText: 'Accepter les cookies',
			footerText: 'Accepter tous les cookies',
		},
		links: {
			footerText: 'Sauvegarder les paramètres',
			contentText: 'Paramètres des cookies',
		},
		cookies: {
			analytics: {
				header: 'Cookies analytiques',
				content:
					'Ces cookies collectent des données anonymes afin que nous puissions améliorer davantage notre site Web.',
			},
			functional: {
				header: 'Cookies fonctionnels',
				content:
					'Nous utilisons des cookies fonctionnels pour exécuter la fonction de base du site. Ces cookies ne collectent jamais de données personnelles et sont toujours actifs',
			},
			marketing: {
				header: 'Cookies marketing',
				content:
					'Ces cookies peuvent être utilisés pour afficher des pages personnalisées.',
			},
		},
		active: 'Actif',
		inactive: 'Inactif',
		showDetails: 'Voir les détails',
		hideDetails: 'Masquer',
	},
};
