<section
	[editableInStoryblok]="_editable"
	class="section-spacing"
	data-test-class="introduction-section"
>
	<div class="section-gap mx-auto max-w-screen-2xl">
		<sb-columns [colOne]="title" component="columnOne" />
		@if (uspWrapper.length > 0) {
			<sb-columns [colOne]="uspWrapper" component="columnOne" />
		}
	</div>
</section>
