import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'price',
	pure: true,
	standalone: true,
})
export class PricePipe implements PipeTransform {
	language: string;

	constructor(
		private readonly currencyPipe: CurrencyPipe,
		private readonly translate: TranslateService,
	) {
		// istanbul ignore next
		this.language =
			this.translate.currentLang || this.translate.defaultLang;
	}

	transform(priceInCents: number, currency: string): string {
		return this.currencyPipe.transform(
			priceInCents / 100,
			currency,
			'symbol-narrow',
			'1.2-2',
			this.language,
		);
	}
}
