import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	input,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [AngularSvgIconModule, CommonModule, TranslateModule],
	selector: 'vp-rating',
	templateUrl: './rating.html',
})
export class RatingComponent extends BaseDirective {
	score = input<number>(1);
	numberOfRatings = input<number>(0);
	isHorizontal = input<boolean>();

	constructor(public override translate: TranslateService) {
		super(translate);
	}
}
