import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { storyblokEditable } from '@storyblok/js';

/*
 * Link your components to Storyblok Visual Editor
 * https://github.com/storyblok/storyblok-js#3-link-your-components-to-storyblok-visual-editor
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[editableInStoryblok]',
	standalone: true,
})
export class StoryblokDirective implements AfterViewInit {
	@Input() editableInStoryblok: string | undefined;

	constructor(public el: ElementRef) {}

	ngAfterViewInit() {
		if (this.editableInStoryblok) {
			const options = storyblokEditable({
				_editable: this.editableInStoryblok,
			});
			this.el.nativeElement.setAttribute(
				'data-blok-c',
				options['data-blok-c'],
			);
			this.el.nativeElement.setAttribute(
				'data-blok-uid',
				options['data-blok-uid'],
			);
			this.el.nativeElement.classList.add('storyblok__outline');
		}
	}
}
