const textColors = {
	light: {
		quaternary: 'var(--color-text-light-quarternary, #FFFFFF4D)',
		tertiary: 'var(--color-text-light-tertiary, #FFFFFF8C)',
		secondary: 'var(--color-text-light-secondary, #FFFFFFA6)',
		primary: 'var(--color-text-light-primary, #FFFFFFBF)',
	},
	dark: {
		quaternary: 'var(--color-text-dark-quarternary, #0000004D)',
		tertiary: 'var(--color-text-dark-tertiary, #0000008C)',
		secondary: 'var(--color-text-dark-secondary, #000000A6)',
		primary: 'var(--color-text-dark-primary, #000000BF)',
	},
	// NOTE: not defined as color in figma
	grey: {
		100: 'var(--color-text-grey-100, #737373)',
		125: 'var(--color-text-grey-125, #595959)',
		150: 'var(--color-text-grey-150, #404040)',
	},
};

const colors = {
	transparent: 'transparent',
	current: 'currentColor',
	ui: {
		25: 'var(--color-ui-25, #F8F8F8)',
		50: 'var(--color-ui-50, #F3F3F3)',
		75: 'var(--color-ui-75, #EBEBEB)',
		100: 'var(--color-ui-100, #D8D8D8)',
		125: 'var(--color-ui-125, #C4C4C4)',
		150: 'var(--color-ui-150, #B1B1B1)',
		175: 'var(--color-ui-175, #9D9D9D)',
	},
	success: {
		25: 'var(--color-success-25, #E9FBEA)',
		50: 'var(--color-success-50, #C1F2C3)',
		75: 'var(--color-success-75, #83E687)',
		100: 'var(--color-success-100, #27D32E)',
		125: 'var(--color-success-125, #21B527)',
		150: 'var(--color-success-150, #018401)',
		175: 'var(--color-success-175, #0B3C0D)',
	},
	error: {
		25: 'var(--color-error-25, #FFEBEB)',
		50: 'var(--color-error-50, #FFD6D6)',
		75: 'var(--color-error-75, #FF9999)',
		100: 'var(--color-error-100, #FF3333)',
		125: 'var(--color-error-125, #CC2929)',
		150: 'var(--color-error-150, #921D1D)',
		175: 'var(--color-error-175, #490F0F)',
	},
	warning: {
		25: 'var(--color-warning-50, #FEFAF1)',
		50: 'var(--color-warning-50, #FFF2DA)',
		75: 'var(--color-warning-75, #FFDFA2)',
		100: 'var(--color-warning-100, #FFC045)',
		125: 'var(--color-warning-125, #CC9A37)',
		150: 'var(--color-warning-150, #8D7742)',
		175: 'var(--color-warning-175, #473B21)',
	},
	info: {
		25: 'var(--color-info-50 ,#E6F5FA)',
		50: 'var(--color-info-50 ,#CCEBF5)',
		75: 'var(--color-info-75, #80CCE5)',
		100: 'var(--color-info-100, #0099CC)',
		125: 'var(--color-info-125, #006D98)',
		150: 'var(--color-info-150, #3C7B90)',
		175: 'var(--color-info-175, #002C3A)',
	},
	select: {
		25: 'var(--color-select-50 ,#E6F5FA)',
		50: 'var(--color-select-50 ,#CCEBF5)',
		75: 'var(--color-select-75, #80CCE5)',
		100: 'var(--color-select-100, #0099CC)',
		125: 'var(--color-select-125, #006D98)',
		150: 'var(--color-select-150, #3C7B90)',
		175: 'var(--color-select-175, #002C3A)',
	},
	accent: {
		25: 'var(--color-accent-25, #F8F5EF)',
		50: 'var(--color-accent-50, #F1EADF)',
		75: 'var(--color-accent-75, #DCCBAF)',
		100: 'var(--color-accent-100, #BA975F)',
		125: 'var(--color-accent-125, #93703E)',
		150: 'var(--color-accent-125, #6A5636)',
		175: 'var(--color-accent-125, #352B1B)',
	},
	primary: {
		25: 'var(--color-primary-25, #FFF4E6)',
		50: 'var(--color-primary-50, #FFDEB6)',
		75: 'var(--color-primary-50, #FC9B59)',
		100: 'var(--color-primary-100, #FF6600)',
		125: 'var(--color-primary-125, #D66215)',
		150: 'var(--color-primary-125, #732F02)',
		175: 'var(--color-primary-125, #461D01)',
	},
	secondary: {
		25: 'var(--color-secondary-50 ,#E6F5FA)',
		50: 'var(--color-secondary-50 ,#CCEBF5)',
		75: 'var(--color-secondary-75, #80CCE5)',
		100: 'var(--color-secondary-100, #0099CC)',
		125: 'var(--color-secondary-125, #006D98)',
		150: 'var(--color-secondary-150, #3C7B90)',
		175: 'var(--color-secondary-175, #002C3A)',
	},
	// NOTE: not defined as color in figma
	focus: {
		100: 'var(--color-focus-100, #67DBFF)',
	},
	white: 'var(--color-white, #FFFFFF)',
	black: 'var(--color-black, #000000)',
};

export default { textColors, colors };
