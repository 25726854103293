import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageQueryPipe } from './image-query.pipe';

@NgModule({
	declarations: [ImageQueryPipe],
	exports: [ImageQueryPipe],
	imports: [CommonModule],
})
export class ImageQueryModule {}
