export interface CookieManagerResponse {
	succes: boolean;
	cookiestatus: Status;
}

export enum Status {
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
	EMTPY = '',
}
