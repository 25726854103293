@if (!useSpanEl) {
	<label
		[for]="identifier"
		[ngClass]="[
			position === 'left-aligned' || position === 'top-left-aligned'
				? 'block sm:flex'
				: 'block',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'top-left-aligned'
				? 'flex'
				: 'block'
		]"
	>
		<ng-container [ngTemplateOutlet]="labelContent" />
	</label>
}
@if (useSpanEl) {
	<span
		[ngClass]="[
			position === 'left-aligned' || position === 'top-left-aligned'
				? 'block sm:flex'
				: 'block',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'top-left-aligned'
				? 'flex'
				: 'block'
		]"
	>
		<ng-container [ngTemplateOutlet]="labelContent" />
	</span>
}
<div class="flex">
	@if (position === 'left-aligned' || position === 'top-left-aligned') {
		<div
			[class]="labelWidth + ' mr-3'"
			[ngClass]="[
				(position === 'left-aligned' ||
					position === 'top-left-aligned') &&
				mobilePosition !== 'left-aligned' &&
				mobilePosition !== 'top-left-aligned'
					? 'hidden sm:block'
					: ''
			]"
		></div>
	}
	<div [class]="contentWidth" class="pl-1">
		@if (showError) {
			<div class="mt-1">
				<ng-content select="vp-error" />
			</div>
		}
		@if (!!helperText) {
			<div
				[ngClass]="{ 'mt-1': !showError }"
				class="text-xs text-dark-tertiary first:mt-1 sm:text-sm"
				data-test-id="helper-text"
			>
				{{ helperText }}
			</div>
		}
	</div>
</div>

<ng-template #labelContent>
	<div
		[id]="inputName + '-label-block'"
		[ngClass]="[
			position === 'left-aligned' || position === 'top-left-aligned'
				? 'w-full mr-3 ' +
				  labelWidth +
				  ' items-center sm:flex-col sm:items-end'
				: position === 'top-aligned-inline'
				  ? ''
				  : 'items-baseline flex-col',
			position === 'left-aligned'
				? 'sm:justify-center'
				: position === 'top-aligned-inline'
				  ? 'items-center'
				  : '',
			mobilePosition === 'left-aligned' ? 'justify-center' : '',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'top-left-aligned'
				? '!items-end flex-col'
				: ''
		]"
		class="flex min-w-0 max-w-full flex-wrap"
	>
		<div
			[id]="inputName + '-label'"
			[ngClass]="[
				position === 'left-aligned' || position === 'top-left-aligned'
					? 'text-left mx-1 sm:text-right sm:mx-0'
					: 'text-left mx-1',
				mobilePosition === 'left-aligned' ||
				mobilePosition === 'top-left-aligned'
					? 'text-right mx-0'
					: 'text-left mx-1',
				truncate
					? 'overflow-x-clip text-ellipsis whitespace-nowrap'
					: ''
			]"
			class="max-w-full text-sm font-semibold sm:text-base"
			data-test-id="label-text"
		>
			{{ labelText }}
		</div>
		@if (requiredHelperText) {
			<div
				[ngClass]="[
					position === 'left-aligned' ||
					position === 'top-left-aligned'
						? 'text-left mx-1 sm:text-right sm:mx-0'
						: 'text-left mx-1',
					position === 'top-aligned-inline' ? 'mt-px' : '',
					mobilePosition === 'left-aligned' ||
					mobilePosition === 'top-left-aligned'
						? 'text-right mx-0'
						: 'text-left mx-1',
					position === 'left-aligned' ? '!leading-none' : '',
					truncate
						? 'overflow-x-clip text-ellipsis whitespace-nowrap'
						: ''
				]"
				class="max-w-full text-xs text-dark-tertiary sm:text-sm"
				data-test-id="required-helper-text"
			>
				{{ requiredHelperText }}
			</div>
		}
	</div>
	<div
		[ngClass]="[
			position === 'left-aligned' || position === 'top-left-aligned'
				? 'mt-1 sm:mt-0 w-full ' + contentWidth
				: 'mt-1',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'top-left-aligned'
				? 'mt-0'
				: ' mt-1'
		]"
	>
		<ng-content />
	</div>
</ng-template>
