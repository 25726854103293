<span class="flex justify-between gap-4" data-test-class="receipt-extra">
	<span class="my-auto" data-test-id="receipt-extra-title">
		@if (extra.amount) {
			{{ extra.amount }}
			<span aria-hidden="true">x</span>
		}
		@if (extra.name) {
			{{ extra.name }}
		} @else {
			{{ 'global.unspecified-extra-text' | translate }}
		}
		@if (extra.dates?.length) {
			<!-- prettier-ignore -->
			<span class="text-dark-tertiary">
				(@for (date of extra.dates; track date; let i = $index) {
					{{ date| dateparser: 'dd D MMM'	}}{{ i < extra.dates.length - 1 ? ', ' : '' }}
				})
			</span>
		}
	</span>
	<span class="my-auto" data-test-id="receipt-extra-price">
		{{ extra.price | price: currency }}
	</span>
</span>
