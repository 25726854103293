<vp-read-more [isDisabled]="!isReadMore">
	<markdown
		[class]="listStyle"
		[data]="content"
		[editableInStoryblok]="_editable"
		[ngClass]="{ 'text-center': isCentered }"
		class="markdown"
		data-test-class="markdown"
	/>
</vp-read-more>
