import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	input,
} from '@angular/core';

import { ISbRichtext } from '@storyblok/js';
import { MarkdownModule } from 'ngx-markdown';

import { HeadingDirective } from '@valk-nx/core/lib/directives/heading/heading.directive';
import { HeadingType } from '@valk-nx/core/lib/directives/heading/heading.type';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { ColumnComponentAlignments } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { ngSkipHydration: 'true' },
	selector: 'sb-heading-group',
	templateUrl: './heading-group.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		NgClass,
		HeadingDirective,
		MarkdownModule,
		RichTextComponent,
		StoryblokDirective,
	],
})
export class HeadingGroupComponent extends StoryblokRootDirective {
	@Input() overline = '';
	@Input({ required: true }) title!: ISbRichtext | string;
	@Input({ required: true }) headingType: HeadingType = 'h2';
	@Input({ required: true }) size: 'sm' | 'md' | 'lg' = 'md';

	alignment = input<ColumnComponentAlignments>('left');

	get headingSize() {
		switch (this.size) {
			case 'sm':
				return 'heading-group heading-group-sm';
			case 'lg':
				return 'heading-group heading-group-lg';
			case 'md':
			default:
				return 'heading-group ';
		}
	}
}
