import { Directive, Input } from '@angular/core';

@Directive({
	selector: '[sbRoot]',
})
export class StoryblokRootDirective {
	@Input() _editable!: string;
	@Input() _uid!: string;
	@Input() component!: string;
}
