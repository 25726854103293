<div
	[attr.data-test-id]="inputId"
	(click)="(readonly ? false : true)"
	class="flex w-full"
	id="checkbox"
>
	@if (!showAsToggle) {
		<input
			#checkboxInput
			[autocomplete]="autocomplete || 'off'"
			[formControl]="customFormControl"
			[id]="inputId"
			[name]="name"
			[ngClass]="{
				'!border-error-100':
					hasError && !disabled && !readonly && !isOptional,
				'!border-success-100':
					isValid && !disabled && !readonly && !isOptional,
			}"
			class="control-checkbox transition duration-200"
			type="checkbox"
		/>
		<label [attr.data-test-id]="'checkbox-label-text'" [for]="inputId">
			<ng-container [ngTemplateOutlet]="label" />
		</label>
	}
	@if (showAsToggle) {
		<label
			[for]="inputId"
			class="relative mb-4 flex h-6 items-center"
			data-test-id="toggle-label-text"
		>
			<input
				#checkboxInput
				[disabled]="disabled"
				[formControl]="customFormControl"
				[id]="inputId"
				class="control-toggle-peer sr-only"
				type="checkbox"
			/>
			<div class="control-toggle after:transition"></div>
			<ng-container [ngTemplateOutlet]="label" />
		</label>
	}
</div>

<ng-template #label>
	<span [ngClass]="{ 'cursor-pointer': !disabled }" class="ml-3 block flex-1">
		<ng-content />
	</span>
</ng-template>
