import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	Output,
	PLATFORM_ID,
} from '@angular/core';

import { createPopper, Instance, Placement } from '@popperjs/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ClickedDirective } from '@valk-nx/core/lib/directives/clicked/clicked.directive';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'vpPopover',
	selector: `vp-popover`,
	templateUrl: './popover.html',
})
export class PopoverComponent
	implements AfterViewChecked, AfterViewInit, OnDestroy
{
	@Input() alignment: Placement = 'bottom-start';
	@Input() disabled = false;
	@Input() showPopover = false;
	@Input() showHeader = true;
	@Input() boundaryTargetId = '';
	@Input() useFullWidthOfBoundaryTarget = true;
	@Input() popoverId = '';
	@Input() triggerId = '';
	@Input() popoverOffset: [number, number] = [0, 8];
	@Input() closeable = true;
	@Input() className = '';
	@Input() isFullHeight = true;
	@Input() overruleCreateInstance = false;

	@Input() excludeFromClosingRegex!: RegExp | string;

	@Output() isOpen = new EventEmitter<boolean>();

	@ContentChild(ClickedDirective)
	clickableElement!: ClickedDirective;

	popperInstance!: Instance;
	isSSRMode = false;

	boundaryInstance!: HTMLElement | null;
	popoverInstance!: HTMLElement;
	triggerInstance!: Element;

	isSmallTablet$: Observable<boolean>;

	destroy$ = new Subject<void>();

	constructor(
		@Inject(PLATFORM_ID) readonly platformId: string,
		private readonly cd: ChangeDetectorRef,
		private readonly viewport: ViewPortService,
	) {
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
	}

	ngAfterViewChecked(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.boundaryInstance = document.getElementById(
				this.boundaryTargetId,
			);
			this.popoverInstance = document.getElementById(
				this.popoverId,
			) as HTMLElement;
			this.triggerInstance = document.getElementById(
				this.triggerId,
			) as Element;

			if (!this.popperInstance || this.overruleCreateInstance) {
				this.popperInstance = createPopper(
					this.triggerInstance,
					this.popoverInstance,
					{
						modifiers: [
							{
								name: 'offset',
								options: {
									offset: this.popoverOffset,
								},
							},
							{
								name: 'preventOverflow',
								options: {
									boundary: this.boundaryInstance,
								},
							},
							{ enabled: false, name: 'flip' },
							{
								name: 'matchWidthToBoundaryInstance',
								enabled: true,
								fn: ({ state }) => {
									/* istanbul ignore next */
									if (
										this.boundaryInstance &&
										this.useFullWidthOfBoundaryTarget
									) {
										state.elements['popper'].classList.add(
											'boundary-width',
										);
										state.styles['popper'].width =
											`${this.boundaryInstance.offsetWidth}px`;
									} else {
										state.elements[
											'popper'
										].classList.remove('boundary-width');
									}
								},
								phase: 'beforeWrite',
								requires: ['computeStyles'],
							},
						],
						placement: this.alignment,
					},
				);
			}
		}
	}

	ngAfterViewInit(): void {
		this.clickableElement.clicked
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.toggle();
			});
		this.isSSRMode = isPlatformServer(this.platformId);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	show() {
		if (!this.disabled) {
			this.showPopover = true;
			this.isOpen.emit(true);
			this.cd.detectChanges();
		}
	}

	hide() {
		this.showPopover = false;
		this.isOpen.emit(false);
		this.cd.detectChanges();
	}

	toggle() {
		if (this.showPopover) {
			this.hide();
		} else {
			this.show();
		}
	}
}
