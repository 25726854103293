import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
	RECAPTCHA_LANGUAGE,
	RecaptchaFormsModule,
	RecaptchaModule,
} from 'ng-recaptcha';

import { CheckboxComponent } from '@valk-nx/components/ui-checkbox/src/lib/checkbox';
import { CheckboxGroupComponent } from '@valk-nx/components/ui-checkbox-group/src/lib/checkbox-group';
import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { InputComponent } from '@valk-nx/components/ui-input/src/lib/input';
import { LabelComponent } from '@valk-nx/components/ui-label/src/lib/label/label';
import { RadioGroupComponent } from '@valk-nx/components/ui-radio-group/src/lib/radio-group';
import { SegmentedControlComponent } from '@valk-nx/components/ui-segmented-control/src/lib/segmented-control.component';
import { StepperComponent } from '@valk-nx/components/ui-stepper/src/lib/stepper';
import { TextareaComponent } from '@valk-nx/components/ui-textarea/src/lib/textarea';
import { TooltipModule } from '@valk-nx/components/ui-tooltip/src/lib/tooltip.module';
import { emailValidator } from '@valk-nx/formly/lib/validators/email.validators';

import { CheckboxComponent as FormlyCheckboxComponent } from './fields/checkbox/checkbox.component';
import { CheckboxGroupComponent as FormlyCheckboxGroupComponent } from './fields/checkbox-group/checkbox-group.component';
import { DividerComponent } from './fields/divider/divider';
import { HeadingComponent } from './fields/heading/heading';
import { InputComponent as FormlyInputComponent } from './fields/input/input.component';
import { RadioGroupComponent as FormlyRadioGroupComponent } from './fields/radio-group/radio-group.component';
import { RecaptchaV2Component } from './fields/recaptcha/recaptchaV2.component';
import { SegmentedControlComponent as FormlySegmentedControlComponent } from './fields/segmented-control/segmented-control.component';
import { SelectComponent } from './fields/select/select.component';
import { StepperComponent as FormlyStepperComponent } from './fields/stepper/stepper.component';
import { TextareaComponent as FormlyTextareaComponent } from './fields/textarea/textarea.component';
import { passwordMatchValidator } from './validators/password-match.validator';
import { phoneValidator } from './validators/phone.validators';
import { LabelWrapperComponent } from './wrappers/label/label.component';
import { LabelSpanWrapperComponent } from './wrappers/label-span/label-span.component';
import { TooltipWrapperComponent } from './wrappers/tooltip/tooltip.component';
import { registerTranslateExtension } from './formly.config';

@NgModule({
	declarations: [
		FormlyCheckboxComponent,
		FormlyCheckboxGroupComponent,
		FormlyRadioGroupComponent,
		HeadingComponent,
		FormlyInputComponent,
		LabelSpanWrapperComponent,
		LabelWrapperComponent,
		FormlySegmentedControlComponent,
		SelectComponent,
		FormlyStepperComponent,
		RecaptchaV2Component,
		TooltipWrapperComponent,
		FormlyTextareaComponent,
	],
	exports: [FormlyModule, FormsModule, ReactiveFormsModule],
	imports: [
		CommonModule,
		FormsModule,
		FormlyModule.forRoot({
			extras: {
				immutable: true,
				resetFieldOnHide: false,
			},
			types: [
				{
					name: 'text',
					component: FormlyInputComponent,
					wrappers: ['label'],
				},
				{
					name: 'number',
					component: FormlyInputComponent,
					wrappers: ['label'],
				},
				{
					name: 'email',
					component: FormlyInputComponent,
					wrappers: ['label'],
					defaultOptions: {
						validators: {
							email: {
								expression: emailValidator,
							},
						},
					},
				},
				{
					name: 'password',
					component: FormlyInputComponent,
					wrappers: ['label'],
				},
				{
					name: 'tel',
					component: FormlyInputComponent,
					wrappers: ['label'],
					defaultOptions: {
						validators: {
							phone: {
								expression: phoneValidator,
							},
						},
					},
				},
				{
					name: 'segmentedControl',
					component: FormlySegmentedControlComponent,
					wrappers: ['labelspan'],
				},
				{
					name: 'select',
					component: SelectComponent,
					wrappers: ['label'],
				},
				{
					name: 'stepper',
					component: FormlyStepperComponent,
					wrappers: ['label'],
				},
				{
					name: 'textarea',
					component: FormlyTextareaComponent,
					wrappers: ['label'],
				},
				{
					name: 'checkbox',
					component: FormlyCheckboxComponent,
					wrappers: ['labelspan'],
				},
				{
					name: 'checkboxGroup',
					component: FormlyCheckboxGroupComponent,
				},
				{
					name: 'radioGroup',
					component: FormlyRadioGroupComponent,
					wrappers: ['labelspan'],
				},
				{
					name: 'recaptchaV2',
					component: RecaptchaV2Component,
				},
				{
					name: 'heading',
					component: HeadingComponent,
				},
				{
					name: 'divider',
					component: DividerComponent,
				},
			],
			validators: [
				{ name: 'passwordMatch', validation: passwordMatchValidator },
			],
			wrappers: [
				{ name: 'label', component: LabelWrapperComponent },
				{ name: 'labelspan', component: LabelSpanWrapperComponent },
				{ name: 'tooltip', component: TooltipWrapperComponent },
			],
		}),
		InputComponent,
		CheckboxComponent,
		CheckboxGroupComponent,
		RadioGroupComponent,
		LabelComponent,
		ReactiveFormsModule,
		SegmentedControlComponent,
		DropdownComponent,
		RecaptchaModule,
		RecaptchaFormsModule,
		StepperComponent,
		TooltipModule,
		TextareaComponent,
		TranslateModule.forChild({}),
	],
	providers: [
		{
			deps: [TranslateService],
			multi: true,
			provide: FORMLY_CONFIG,
			useFactory: registerTranslateExtension,
		},
		{
			deps: [TranslateService],
			provide: RECAPTCHA_LANGUAGE,
			useFactory: (translate: TranslateService) => translate.currentLang,
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedFormlyModule {}
