function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
import { HTTP_REQUEST_TIMEOUT } from '../constants';
import { isResponseSuccessful } from './response-status';
export var BYTE_LIMIT = 60 * 1000;
export function shouldUseFetchWithKeepAlive(method, payload) {
  if (!isFetchSupported()) {
    return false;
  }
  var isKeepAliveSupported = ('keepalive' in new Request(''));
  if (!isKeepAliveSupported) {
    return false;
  }
  var size = calculateSize(payload);
  return method === 'POST' && size < BYTE_LIMIT;
}
export function sendFetchRequest(method, url, _ref) {
  var _ref$keepalive = _ref.keepalive,
    keepalive = _ref$keepalive === void 0 ? false : _ref$keepalive,
    _ref$timeout = _ref.timeout,
    timeout = _ref$timeout === void 0 ? HTTP_REQUEST_TIMEOUT : _ref$timeout,
    payload = _ref.payload,
    headers = _ref.headers,
    sendCredentials = _ref.sendCredentials;
  var timeoutConfig = {};
  if (typeof AbortController === 'function') {
    var controller = new AbortController();
    timeoutConfig.signal = controller.signal;
    setTimeout(function () {
      return controller.abort();
    }, timeout);
  }
  var fetchResponse;
  return window.fetch(url, _extends({
    body: payload,
    headers: headers,
    method: method,
    keepalive: keepalive,
    credentials: sendCredentials ? 'include' : 'omit'
  }, timeoutConfig)).then(function (response) {
    fetchResponse = response;
    return fetchResponse.text();
  }).then(function (responseText) {
    var bodyResponse = {
      url: url,
      status: fetchResponse.status,
      responseText: responseText
    };
    if (!isResponseSuccessful(fetchResponse.status)) {
      throw bodyResponse;
    }
    return bodyResponse;
  });
}
export function isFetchSupported() {
  return typeof window.fetch === 'function' && typeof window.Request === 'function';
}
function calculateSize(payload) {
  if (!payload) {
    return 0;
  }
  if (payload instanceof Blob) {
    return payload.size;
  }
  return new Blob([payload]).size;
}