import { noop } from './utils';
var LoggingService = function () {
  function LoggingService(spec) {
    if (spec === void 0) {
      spec = {};
    }
    this.levels = ['trace', 'debug', 'info', 'warn', 'error'];
    this.level = spec.level || 'warn';
    this.prefix = spec.prefix || '';
    this.resetLogMethods();
  }
  var _proto = LoggingService.prototype;
  _proto.shouldLog = function shouldLog(level) {
    return this.levels.indexOf(level) >= this.levels.indexOf(this.level);
  };
  _proto.setLevel = function setLevel(level) {
    if (level === this.level) {
      return;
    }
    this.level = level;
    this.resetLogMethods();
  };
  _proto.resetLogMethods = function resetLogMethods() {
    var _this = this;
    this.levels.forEach(function (level) {
      _this[level] = _this.shouldLog(level) ? log : noop;
      function log() {
        var normalizedLevel = level;
        if (level === 'trace' || level === 'debug') {
          normalizedLevel = 'info';
        }
        var args = arguments;
        args[0] = this.prefix + args[0];
        if (console) {
          var realMethod = console[normalizedLevel] || console.log;
          if (typeof realMethod === 'function') {
            realMethod.apply(console, args);
          }
        }
      }
    });
  };
  return LoggingService;
}();
export default LoggingService;