import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom, map, switchMap, take } from 'rxjs';

import { AlertPosition } from '@valk-nx/components/ui-alert/src/lib/contracts/alert.types';
import { AlertService } from '@valk-nx/components/ui-alert/src/lib/services/alert.service';
import { CheckedOption } from '@valk-nx/core/lib/interfaces/form.interface';

export const FORM_SERVICE_URL = new InjectionToken<string>(
	'form-subscribe-service-url',
);

export const FORM_API_COOKIES = new InjectionToken<string[]>(
	'form_api-cookies',
);

export type Recipients = 'main' | 'bookings' | 'business' | 'support';

@Injectable({ providedIn: 'root' })
export class FormService {
	constructor(
		private readonly translate: TranslateService,
		private readonly http: HttpClient,
		private readonly alertService: AlertService,
		private readonly recaptchaV3Service: ReCaptchaV3Service,
		@Inject(FORM_SERVICE_URL) private readonly serviceUrl: string,
		@Inject(FORM_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	submitCustomForm(
		subject: string,
		params: {
			id: string;
			label: string;
			value: string | CheckedOption[];
		}[],
		recipient: Recipients,
		hotelSlug: string,
	): Promise<{
		success: boolean;
	}> {
		return firstValueFrom(
			this.recaptchaV3Service.execute('customForm').pipe(
				switchMap((token) => {
					return this.http.post<{ data: { success: true } }>(
						`${this.serviceUrl}/form/submit`,
						{ subject, params, token, type: recipient, hotelSlug },
						{
							...this.apiCookies,
						},
					);
				}),
				take(1),
				map((body) => {
					return {
						success: body.data.success,
					};
				}),
			),
		).catch(() => {
			this.alertService.error({
				closeable: true,
				duration: 5000,
				emphasized: false,
				hasElevation: true,
				position: AlertPosition.TopCenter,
				content: this.translate.instant('global.service.error'),
			});
			return { success: false };
		});
	}
}
