import { AbstractControl, Validators } from '@angular/forms';

// Regular expression for email with mandatory TLD of 2 characters or more
const tldRegex = /\.[a-zA-Z]{2,}$/;

export function emailValidator(control: AbstractControl): boolean {
	// Validators should only validate if there is a value. Otherwise the consuer
	// should use the required validator.
	if (!control.value) {
		return true;
	}

	const emailError = Validators.email(control);

	return emailError == null && tldRegex.test(control.value);
}
