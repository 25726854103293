import { QueryValue } from '../interfaces/query.interface';

export class StoreHelper {
	static getFullName(
		firstName: string,
		insertion: string,
		lastName: string,
	): string {
		return `${firstName} ${
			insertion ? insertion + ' ' : ''
		}${lastName}`.trim();
	}

	static createQueryParamString(params: Record<string, QueryValue>): string {
		const buildQuery = (key: string, value: QueryValue): string => {
			if (value === undefined) {
				return '';
			}

			if (Array.isArray(value)) {
				if (typeof value[0] === 'string') {
					const encodedArray = value.map((val) =>
						encodeURIComponent(val as string),
					);
					return `${encodeURIComponent(key)}=${encodedArray.join(',')}`;
				}
				return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
			} else if (typeof value === 'object' && value !== null) {
				return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
			} else {
				return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
			}
		};

		const queryString = Object.keys(params)
			.map((key) => buildQuery(key, params[key]))
			.filter((part) => part !== '')
			.join('&');

		return queryString ? `?${queryString}` : '';
	}
}
