import { NgClass } from '@angular/common';
import {
	Component,
	computed,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	input,
} from '@angular/core';

import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-running-head',
	templateUrl: './running-head.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [NgClass, StoryblokDirective],
	host: { class: 'w-full' },
})
export class RunningHeadComponent extends StoryblokRootDirective {
	@Input({ required: true }) title = '';
	size = input.required<'xs' | 'sm' | 'md' | 'lg' | 'xl'>();

	headingSize = computed<string>(() => {
		switch (this.size()) {
			case 'xs':
				return `running-head-xs`;
			case 'sm':
				return `running-head-sm`;
			case 'lg':
				return `running-head-lg`;
			case 'xl':
				return `running-head-xl`;
			case 'md':
			default:
				return `running-head`;
		}
	});
}
