"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "defaults", {
  enumerable: true,
  get: function () {
    return defaults;
  }
});
function defaults(target, ...sources) {
  for (let source of sources) {
    for (let k in source) {
      var _target_hasOwnProperty;
      if (!(target === null || target === void 0 ? void 0 : (_target_hasOwnProperty = target.hasOwnProperty) === null || _target_hasOwnProperty === void 0 ? void 0 : _target_hasOwnProperty.call(target, k))) {
        target[k] = source[k];
      }
    }
    for (let k of Object.getOwnPropertySymbols(source)) {
      var _target_hasOwnProperty1;
      if (!(target === null || target === void 0 ? void 0 : (_target_hasOwnProperty1 = target.hasOwnProperty) === null || _target_hasOwnProperty1 === void 0 ? void 0 : _target_hasOwnProperty1.call(target, k))) {
        target[k] = source[k];
      }
    }
  }
  return target;
}