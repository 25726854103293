<header
	[class]="alignment() === 'center' ? 'text-center' : 'text-left'"
	[editableInStoryblok]="_editable"
	class="font-display"
>
	<div [class]="headingSize" [heading]="headingType" vc-heading>
		<sb-richtext
			[content]="title"
			[richTextAlignment]="
				alignment() === 'center' ? 'text-center' : 'text-left'
			"
		/>
	</div>
	<p class="heading-group-overline" role="doc-subtitle">
		{{ overline }}
	</p>
</header>
