import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuItemsPipe } from './menu-items.pipe';

@NgModule({
	declarations: [MenuItemsPipe],
	exports: [MenuItemsPipe],
	imports: [CommonModule],
})
export class MenuItemsModule {}
