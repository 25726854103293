function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;
  _setPrototypeOf(subClass, superClass);
}
function _setPrototypeOf(o, p) {
  _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) {
    o.__proto__ = p;
    return o;
  };
  return _setPrototypeOf(o, p);
}
import SpanBase from './span-base';
import { addSpanContext } from '../common/context';
var Span = function (_SpanBase) {
  _inheritsLoose(Span, _SpanBase);
  function Span(name, type, options) {
    var _this;
    _this = _SpanBase.call(this, name, type, options) || this;
    _this.parentId = _this.options.parentId;
    _this.subtype = undefined;
    _this.action = undefined;
    if (_this.type.indexOf('.') !== -1) {
      var fields = _this.type.split('.', 3);
      _this.type = fields[0];
      _this.subtype = fields[1];
      _this.action = fields[2];
    }
    _this.sync = _this.options.sync;
    return _this;
  }
  var _proto = Span.prototype;
  _proto.end = function end(endTime, data) {
    _SpanBase.prototype.end.call(this, endTime);
    addSpanContext(this, data);
  };
  return Span;
}(SpanBase);
export default Span;