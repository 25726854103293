import plugin from 'tailwindcss/plugin';

const tagPlugin = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const white = theme('colors.white');
		const dark = theme('textColor.dark.primary');
		addComponents({
			[`.${e('tag')}`]: {
				borderRadius: '100px',
				color: white,
				display: 'inline-block',
				fontSize: theme('fontSize.xs'),
				letterSpacing: theme('letterSpacing.wider'),
				lineHeight: theme('lineHeight.3'),
				padding: `${theme('spacing.1')} ${theme('spacing')['1.5']}`,

				[`@media (min-width: ${theme('screens.md')})`]: {
					fontSize: theme('fontSize.sm'),
					lineHeight: theme('lineHeight.4'),
					padding: `${theme('spacing')['1.5']} ${theme('spacing.3')}`,
				},
			},
			[`.${e('tag-emphasized')}`]: {
				fontWeight: theme('fontWeight.semibold'),
				textTransform: 'uppercase',
			},

			[`.${e('tag-black')}`]: {
				background: `var(--tag-black-color-background, ${theme(
					'colors.black',
				)})`,
				color: `var(--tag-black-color-text, ${white})`,
			},

			[`.${e('tag-dark-blue')}`]: {
				background: `var(--tag-dark-blue-color-background, ${theme(
					'colors.info.100',
				)})`,
				color: `var(--tag-dark-blue-color-text,  ${white})`,
			},

			[`.${e('tag-blue')}`]: {
				background: `var(--tag-blue-color-background, ${theme(
					'colors.info.50',
				)})`,
				color: `var(--tag-blue-color-text,  ${dark})`,
			},

			[`.${e('tag-green')}`]: {
				background: `var(--tag-green-color-background, ${theme(
					'colors.success.125',
				)})`,
				color: `var(--tag-green-color-text,  ${white})`,
			},

			[`.${e('tag-light-green')}`]: {
				background: `var(--tag-light-green-color-background, ${theme(
					'colors.success.50',
				)})`,
				color: `var(--tag-light-green-color-text,  ${dark})`,
			},

			[`.${e('tag-light-lime')}`]: {
				background: `var(--tag-light-lime-color-background, ${theme(
					'colors.success.25',
				)})`,
				color: `var(--tag-light-lime-color-text, ${dark})`,
			},

			[`.${e('tag-yellow')}`]: {
				background: `var(--tag-yellow-color-background, ${theme(
					'colors.warning.75',
				)})`,
				color: `var(--tag-yellow-color-text, ${dark})`,
			},

			[`.${e('tag-orange')}`]: {
				background: `var(--tag-orange-color-background, ${theme(
					'colors.primary.75',
				)})`,
				color: `var(--tag-orange-color-text, ${dark})`,
			},

			[`.${e('tag-magenta')}`]: {
				background: `var(--tag-magenta-color-background, ${theme(
					'colors.error.75',
				)})`,
				color: `var(--tag-magenta-color-text, ${dark})`,
			},

			[`.${e('tag-pink')}`]: {
				background: `var(--tag-pink-color-background, #F4B6D5)`,
				color: `var(--tag-pink-color-text,  ${dark})`,
			},

			[`.${e('tag-dark-accent')}`]: {
				background: `var(--tag-dark-accent-color-background, ${theme(
					'colors.accent.100',
				)})`,
				color: `var(--tag-dark-accent-color-text, ${white})`,
			},

			[`.${e('tag-accent')}`]: {
				background: `var(--tag-accent-color-background, ${theme(
					'colors.accent.50',
				)})`,
				color: `var(--tag-accent-color-text, ${theme(
					'colors.accent.125',
				)})`,
			},
		});
	};
});

export default tagPlugin;
