import { Directive } from '@angular/core';
/**
 * Used for Storybook to prevent errors cannot find directive
 */
@Directive({
	selector: 'vp-popover-content, [vp-popover-content], [vpPopoverContent]',
})
export class PopoverContentDirective {}

@Directive({
	selector:
		'vp-popover-header-title, [vp-popover-header-title], [vpPopoverHeaderTitle]',
})
export class PopoverHeaderDirective {}

@Directive({
	selector: 'vp-popover-footer, [vp-popover-footer], [vpPopoverFooter]',
})
export class PopoverFooterDirective {}
