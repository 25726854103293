<vp-status-header
	className="mt-4 bg-ui-25 py-8"
	data-test-id="alert"
	icon="assets/icons/icon-spinner.svg"
	iconAnimationClass="animate-spin"
	iconType="default"
>
	<h1 data-test-id="title" vp-status-header-title>
		{{ 'storyblok.giftcard.payment-processing.title' | translate }}
	</h1>
	<p data-test-id="content" vp-status-header-content>
		{{ 'storyblok.giftcard.payment-processing.content' | translate }}
	</p>
</vp-status-header>
