import { stripQueryStringFromUrl } from '../../common/utils';
import { shouldCreateSpan } from './utils';
import { RESOURCE_INITIATOR_TYPES } from '../../common/constants';
import Span from '../span';
function createResourceTimingSpan(resourceTimingEntry) {
  var name = resourceTimingEntry.name,
    initiatorType = resourceTimingEntry.initiatorType,
    startTime = resourceTimingEntry.startTime,
    responseEnd = resourceTimingEntry.responseEnd;
  var kind = 'resource';
  if (initiatorType) {
    kind += '.' + initiatorType;
  }
  var spanName = stripQueryStringFromUrl(name);
  var span = new Span(spanName, kind);
  span._start = startTime;
  span.end(responseEnd, {
    url: name,
    entry: resourceTimingEntry
  });
  return span;
}
function isCapturedByPatching(resourceStartTime, requestPatchTime) {
  return requestPatchTime != null && resourceStartTime > requestPatchTime;
}
function isIntakeAPIEndpoint(url) {
  return /intake\/v\d+\/rum\/events/.test(url);
}
function createResourceTimingSpans(entries, requestPatchTime, trStart, trEnd) {
  var spans = [];
  for (var i = 0; i < entries.length; i++) {
    var _entries$i = entries[i],
      initiatorType = _entries$i.initiatorType,
      name = _entries$i.name,
      startTime = _entries$i.startTime,
      responseEnd = _entries$i.responseEnd;
    if (RESOURCE_INITIATOR_TYPES.indexOf(initiatorType) === -1 || name == null) {
      continue;
    }
    if ((initiatorType === 'xmlhttprequest' || initiatorType === 'fetch') && (isIntakeAPIEndpoint(name) || isCapturedByPatching(startTime, requestPatchTime))) {
      continue;
    }
    if (shouldCreateSpan(startTime, responseEnd, trStart, trEnd)) {
      spans.push(createResourceTimingSpan(entries[i]));
    }
  }
  return spans;
}
export { createResourceTimingSpans };