import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';

import { negativeHeader$ } from '@valk-nx/core/lib/core';
import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

import { HeaderDesktopComponent } from './header-desktop/header-desktop';
import { HeaderMobileComponent } from './header-mobile/header-mobile';
import { HeaderMenuItem, MetaMenu } from './header.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-header`,
	templateUrl: './header.component.html',
	standalone: true,
	imports: [CommonModule, HeaderDesktopComponent, HeaderMobileComponent],
})
export class HeaderComponent extends BaseDirective {
	@Input() title = '';
	@Input() menuItems: HeaderMenuItem[];
	@Input() metaMenu: MetaMenu;

	@Output() languageSwitch = new EventEmitter<string>();

	isNegativeHeader = false;

	constructor(
		public override translate: TranslateService,
		private readonly cd: ChangeDetectorRef,
	) {
		super(translate);

		negativeHeader$.pipe(takeUntilDestroyed()).subscribe((isNegative) => {
			this.isNegativeHeader = isNegative;
			this.cd.markForCheck();
		});
	}

	onLanguageSwitch(language: string) {
		this.languageSwitch.emit(language);
	}
}
