import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { map, Observable, switchMap, take } from 'rxjs';

import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { ReadMoreHelper } from '@valk-nx/components/ui-read-more/src/lib/read-more.helper';
import { RoomFacilitiesComponent } from '@valk-nx/components/ui-room-facilities/src/lib/room-facilities.component';
import { AvailabilityWidgetComponent } from '@valk-nx/compositions/ui-availability-widget/src/lib/availability-widget.component';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { HOTEL_SLUG, negativeHeader$ } from '@valk-nx/core/lib/core';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { AccommodationTypeModule } from '@valk-nx/core/lib/pipes/accommodation-type/accommodation-type.module';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { RoomData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'sb-room-detail',
	templateUrl: './room-detail.component.html',
	standalone: true,
	imports: [
		AccommodationTypeModule,
		AvailabilityWidgetComponent,
		CarouselComponent,
		CommonModule,
		MarkdownModule,
		PersuadeCardComponent,
		PricingComponent,
		ReadMoreComponent,
		RoomFacilitiesComponent,
		StoryblokDirective,
		TranslateModule,
	],
})
export class RoomDetailComponent
	extends StoryblokRootDirective
	implements OnInit, AfterViewInit
{
	@Input({ required: true }) canonical!: string;
	@ViewChild('contentRef', { read: ElementRef }) contentRef: ElementRef;

	roomData$: Observable<RoomData>;
	images: CarouselItem[] = [];
	roomFacilities: string[] = [];
	disabledReadmore = true;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private metadataService: MetadataService,
		private readonly viewportService: ViewPortService,
		readonly cd: ChangeDetectorRef,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();
		this.roomData$ = this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				return (
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0]
				);
			}),
			switchMap((slug) =>
				this.contentService.getRoom(
					slug.path.split('/').pop(),
					slug.lang.toLowerCase(),
				),
			),
			map((response) => {
				const roomData = response.body.data;
				this.metadataService.updateMetaDataBatch(roomData);

				this.images = roomData.images.map(
					(image: { src: string; alt: string }) => {
						return {
							image: {
								src: image.src,
								altText: image.alt,
							},
							heading: null,
							subHeading: null,
							link: null,
						};
					},
				);

				const { misc, generic, sleepingComfort, bathroom } =
					roomData.facilities;
				const optionalGeneric = generic || [];
				const facilities = [
					...sleepingComfort,
					...bathroom,
					...misc,
					...optionalGeneric,
				];
				this.roomFacilities = [...new Set(facilities)];

				if (this.images.length > 0) {
					negativeHeader$.next(true);
				}

				return roomData;
			}),
		);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.determineReadMore();
		});
	}

	ngOnInit() {
		negativeHeader$.next(false);
	}

	@HostListener('window:resize')
	@Debounce(300)
	determineReadMore() {
		this.viewportService.isSmallTablet$
			.pipe(take(1))
			.subscribe((isSmallTablet) => {
				this.disabledReadmore = ReadMoreHelper.disabledReadmore(
					this.contentRef.nativeElement.offsetHeight,
					isSmallTablet,
				);
				this.cd.detectChanges();
			});
	}
}
