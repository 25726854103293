import { Injectable } from '@angular/core';

import { ToastRef, ToastrService } from 'ngx-toastr';

import { AlertConfigInterface } from '../contracts/alert';
import { AlertPosition, AlertType } from '../contracts/alert.types';

import { AlertToastrComponent } from './../alert-toastr.component';
@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(private readonly toastrService: ToastrService) {}

	success(config: AlertConfigInterface) {
		return this.openAlert(config, 'dialog--success');
	}

	error(config: AlertConfigInterface) {
		return this.openAlert(config, 'dialog--error');
	}

	warning(config: AlertConfigInterface) {
		return this.openAlert(config, 'dialog--warning');
	}

	info(config: AlertConfigInterface) {
		return this.openAlert(config, 'dialog--info');
	}

	private openAlert(
		config: AlertConfigInterface,
		type: AlertType,
	): ToastRef<unknown> | null {
		if ((!config.title && !config.content) || !type) {
			return null;
		}

		const alert = { ...config, type };

		let timeOut = 5000;
		/* istanbul ignore next */
		if (typeof alert.duration !== 'undefined') {
			timeOut = alert.duration < 1 ? 0 : alert.duration;
		}
		const positionClass: string = alert.position || AlertPosition.TopRight;

		const toastRef = this.toastrService.show(alert.title, undefined, {
			positionClass,
			timeOut,
			disableTimeOut: timeOut === 0,
			tapToDismiss: false,
			toastComponent: AlertToastrComponent,
		}).toastRef;

		toastRef.componentInstance.title = alert.title;
		toastRef.componentInstance.content = alert.content;
		toastRef.componentInstance.type = alert.type;
		toastRef.componentInstance.closeable = alert.closeable ?? true;
		toastRef.componentInstance.emphasized = alert.emphasized ?? false;
		toastRef.componentInstance.hasElevation = alert.hasElevation ?? false;
		toastRef.componentInstance.size = alert.size ?? 'base';
		toastRef.componentInstance.animationState = 'hidden';

		return toastRef;
	}
}
