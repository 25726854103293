<div
	class="card flex flex-col gap-4 md:card-lg md:flex-row"
	data-test-id="availability-widget"
>
	<div class="flex size-full flex-col children:flex-1 md:flex-row">
		<vp-availability-widget-input-occupancy
			[className]="
				'focus:relative focus:z-10 md:rounded-r-none max-md:-mb-px max-md:rounded-b-none'
			"
			[defaultAdults]="defaultAdults"
			[initialOccupancy]="initialOccupancy"
			[isOpen]="isOccupancyOpen"
			[maxRooms]="occupancyRestrictions.maxOccupancies"
			[occupancyRestrictions]="occupancyRestrictions"
			(emitChooseDates)="onChooseDatesEmit($event)"
			(emitIsPopoverOpen)="onOccupancyOpenEmit($event)"
			(occupancyChanged)="onOccupancyEmit($event)"
			data-test-id="occupancy-selector"
		/>
		<vp-availability-widget-input-date-range
			[availability]="availability$ | async"
			[className]="
				'md:-ml-px md:rounded-l-none md:focus:border-l max-md:rounded-t-none'
			"
			[config]="{
				mode: 'range',
				showMonths: 2,
				minDate: minDate,
				maxDate: maxDate,
			}"
			[initialArrivalDate]="initialArrivalDate"
			[isLoading]="isLoading"
			[isOpen]="isDatePickerOpen"
			[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
			(datePickerModelChanged)="updateModel($event)"
			(emitIsPopoverOpen)="onDatePickerOpened($event)"
			(emitMonthChange)="onMonthChange($event)"
			(emitShowAvailabilityButton)="goToBookingtool()"
			(initialDatesNotAvailable)="recalculateAvailabilityForToday()"
		/>
	</div>
	<button
		(click)="goToBookingtool()"
		class="btn btn-primary w-full md:h-auto md:w-fit"
		data-test-id="cta-button"
	>
		{{ 'global.show-availability' | translate }}
	</button>
</div>
