<vp-cta-card
	[bulletList]="bulletList"
	[ctaLabel]="ctaLabel"
	[ctaUrl]="flatLink"
	[currency]="currency"
	[price]="price"
	[title]="title"
	[validFrom]="validFrom"
	[validUntil]="validUntil"
/>
