<div class="relative inline-block w-full">
	<textarea
		[attr.data-test-id]="'input-' + textareaId"
		[autocomplete]="autocomplete"
		[formControl]="formControl"
		[id]="textareaId"
		[name]="name"
		[ngClass]="{ error: hasError }"
		[placeholder]="placeholder"
		[readonly]="readonly"
		[rows]="rows"
		class="control-textarea"
	></textarea>
	@if (
		(!isOptional && (isValid || hasError)) ||
		(formControl.value && (isValid || hasError))
	) {
		<div
			class="absolute top-3 -ml-2.5 w-5 rounded-full border-2 border-white bg-white"
		>
			@if (isValid && !hasError) {
				<svg-icon
					class="text-success-100"
					data-test-id="valid-icon"
					src="assets/icons/icon-checkmark-circle-solid.svg"
				/>
			}
			@if (hasError) {
				<svg-icon
					class="text-error-100"
					data-test-id="error-icon"
					src="assets/icons/icon-exclamation-mark-circle-solid.svg"
				/>
			}
		</div>
	}
</div>
