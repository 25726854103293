import { CommonModule } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
	selector: 'vp-stepper',
	standalone: true,
	imports: [CommonModule, AngularSvgIconModule, TranslateModule],
	styles: [
		`
			input[type='number']::-webkit-inner-spin-button,
			input[type='number']::-webkit-outer-spin-button {
				margin: 0;
				-webkit-appearance: none;
			}
			input[type='number'] {
				-moz-appearance: textfield;
			}
		`,
	],
	templateUrl: './stepper.html',
})
export class StepperComponent {
	@Input() inputId = '';
	@Input() min: number | undefined;
	@Input() max: number | undefined;
	@Input() readonly = false;
	@Input() value = 1;

	@Input() ariaDecreaseLabel = '';
	@Input() ariaIncreaseLabel = '';

	@Input() disabled = false;
	@Output() valueChange = new EventEmitter<number>();

	@ViewChild('stepperInput')
	stepperInput!: ElementRef;

	constructor(public translate: TranslateService) {}

	increase() {
		if (
			(this.max !== undefined && this.value < this.max) ||
			this.max === undefined
		) {
			this.value++;
			this.valueChange.emit(this.value);
		}
	}

	decrease() {
		if (
			(this.min !== undefined && this.value > this.min) ||
			this.min === undefined
		) {
			this.value--;
			this.valueChange.emit(this.value);
		}
	}

	onInputChange(event: Event): void {
		const { valueAsNumber } = event.target as unknown as {
			valueAsNumber: number;
		};

		this.checkAndEmitValue(valueAsNumber);
	}

	checkAndEmitValue(value: number): void {
		if (Number.isNaN(value)) {
			value = this.min || 0;
		}
		if (this.max !== 0 && this.min !== 0) {
			if (this.max && +value > this.max) {
				value = this.max;
			}
			if (this.min && +value < this.min) {
				value = this.min;
			}
			this.value = +value;
		}
		this.stepperInput.nativeElement.value = this.value;

		this.valueChange.emit(this.value);
	}
}
