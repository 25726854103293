<div
	[editableInStoryblok]="_editable"
	class="card md:card-lg"
	data-test-id="order-giftcard-business"
>
	@if (requestSubmitted) {
		<vp-alert
			[content]="'storyblok.giftcard.request-success-message' | translate"
			[emphasized]="true"
			[title]="'storyblok.giftcard.request-success-title' | translate"
			data-test-id="alert"
			type="dialog--success"
		/>
	}
	<form
		#requestForm="ngForm"
		[formGroup]="form"
		[ngClass]="{
			hidden: requestSubmitted
		}"
		(ngSubmit)="onSubmit()"
		data-test-id="order-form"
	>
		<formly-form
			[fields]="formFields"
			[form]="form"
			[model]="model"
			[options]="options"
		/>
		<button
			[ngClass]="{
				'btn-is-loading': requestSubmitting
			}"
			class="btn btn-primary mt-6 w-full md:mt-8"
			data-test-id="request-button"
			type="submit"
		>
			{{ 'storyblok.giftcard.send-request' | translate }}
		</button>
		<div class="mt-3 md:mt-4">
			<vp-recaptcha-notification [currentLanguage]="language" />
		</div>
	</form>
	@if (requestSubmitted) {
		<div class="mt-4 md:mt-6" data-test-id="order-summary">
			<span class="block font-bold" data-test-id="full-name-label">
				{{ 'global.full-name.label' | translate }}
			</span>
			<span class="block" data-test-id="full-name"> {{ fullName }} </span>
			<span class="block font-bold" data-test-id="email-address-label">
				{{ 'global.email.label' | translate }}
			</span>
			<span class="block" data-test-id="email-address">
				{{ form.value.emailAddress }}
			</span>
			<span class="block font-bold" data-test-id="phone-number-label">
				{{ 'global.phonenumber.label' | translate }}
			</span>
			<span class="block" data-test-id="phone-number">
				{{ form.value.phoneNumber }}
			</span>
			<span class="block font-bold" data-test-id="company-name-label">
				{{ 'global.company-name.label' | translate }}
			</span>
			<span class="block" data-test-id="company-name">
				{{ form.value.companyName }}
			</span>
			<span class="block font-bold" data-test-id="request-message-label">
				{{
					'storyblok.giftcard.business-order-request-message'
						| translate
				}}
			</span>
			<span class="block" data-test-id="request-message">
				{{ form.value.request }}
			</span>
			<button
				(click)="resetRequestForm()"
				class="text-link mt-6 md:mt-8"
				data-test-id="summary-action"
			>
				{{ 'storyblok.giftcard.send-another-request' | translate }}
			</button>
		</div>
	}
</div>
