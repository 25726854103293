var Queue = function () {
  function Queue(onFlush, opts) {
    if (opts === void 0) {
      opts = {};
    }
    this.onFlush = onFlush;
    this.items = [];
    this.queueLimit = opts.queueLimit || -1;
    this.flushInterval = opts.flushInterval || 0;
    this.timeoutId = undefined;
  }
  var _proto = Queue.prototype;
  _proto._setTimer = function _setTimer() {
    var _this = this;
    this.timeoutId = setTimeout(function () {
      return _this.flush();
    }, this.flushInterval);
  };
  _proto._clear = function _clear() {
    if (typeof this.timeoutId !== 'undefined') {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    this.items = [];
  };
  _proto.flush = function flush() {
    this.onFlush(this.items);
    this._clear();
  };
  _proto.add = function add(item) {
    this.items.push(item);
    if (this.queueLimit !== -1 && this.items.length >= this.queueLimit) {
      this.flush();
    } else {
      if (typeof this.timeoutId === 'undefined') {
        this._setTimer();
      }
    }
  };
  return Queue;
}();
export default Queue;