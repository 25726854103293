import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { debounceTime, fromEvent, map, Observable, of, startWith } from 'rxjs';

import {
	isDesktop,
	isMobile,
	isSmallMobile,
	isSmallTablet,
	isTablet,
} from '@valk-nx/services/viewport/src/lib/determine-viewports';

@Injectable({
	providedIn: 'root',
})
export class ViewPortService {
	private window!: Window;
	private screenWidth$!: Observable<number>;

	isSmallMobile$: Observable<boolean>;
	isMobile$: Observable<boolean>;
	isSmallTablet$: Observable<boolean>;
	isTablet$: Observable<boolean>;
	isDesktop$: Observable<boolean>;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) private readonly platformId: string,
	) {
		if (isPlatformBrowser(this.platformId)) {
			this.window = this.document.defaultView as Window;
			this.screenWidth$ = fromEvent(this.window, 'resize').pipe(
				map(() => this.window.innerWidth),
				debounceTime(15),
				startWith(this.window.innerWidth),
			);

			this.isSmallMobile$ = this.screenWidth$.pipe(map(isSmallMobile));
			this.isMobile$ = this.screenWidth$.pipe(map(isMobile));
			this.isSmallTablet$ = this.screenWidth$.pipe(map(isSmallTablet));
			this.isTablet$ = this.screenWidth$.pipe(map(isTablet));
			this.isDesktop$ = this.screenWidth$.pipe(map(isDesktop));
		} else {
			this.isSmallMobile$ = of(false);
			this.isMobile$ = of(false);
			this.isSmallTablet$ = of(false);
			this.isTablet$ = of(false);
			this.isDesktop$ = of(true);
		}
	}
}
