import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { SheetModule } from '@valk-nx/components/ui-sheet/src/lib/sheet.module';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	selector: 'sb-sheet',
	templateUrl: './sheet.html',
	standalone: true,
	imports: [SheetModule, CommonModule, RichTextComponent, StoryblokDirective],
})
export class SheetComponent extends StoryblokRootDirective {
	@Input({ required: true }) description!: string;
	@Input({ required: true }) linkText!: string;
	@Input({ required: true }) sheetTitle!: string;
	@Input({ required: true }) sheetContent!: string;
}
