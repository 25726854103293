<ng-content />
<div
	[ngClass]="horizontal ? 'flex-col sm:flex-row' : 'flex-col'"
	class="flex gap-x-6 gap-y-3"
>
	@for (option of options; track option) {
		<div class="flex">
			<input
				#radioInput
				[attr.aria-describedby]="option.inputId"
				[attr.aria-labelledby]="option.inputId"
				[attr.data-test-id]="option.inputId"
				[checked]="option.selected"
				[disabled]="disabled"
				[id]="option.inputId"
				[name]="name"
				[ngClass]="{
					'!shadow-error-100': hasError && !disabled && !readonly,
					'!shadow-success-100': isValid && !disabled && !readonly,
				}"
				(change)="radioChangedValue(option.inputId)"
				class="control-radio shrink-0 shadow-border shadow-ui-100"
				type="radio"
			/>
			<label
				[for]="option.inputId"
				[ngClass]="{ 'cursor-pointer': !disabled }"
				class="ml-3"
			>
				{{
					shouldTranslate ? (option.label | translate) : option.label
				}}
			</label>
		</div>
	}
</div>
