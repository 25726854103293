<vp-newsletter
	[content]="content"
	[editableInStoryblok]="_editable"
	[newsletterSubmitError]="newsletterSubmitError"
	[newsletterSubmitting]="newsletterSubmitting"
	[photos]="photos"
	(formSubmit)="onSubmit($event)"
>
	<header>
		<h3 class="heading-group">
			{{ title }}
		</h3>
		<p class="heading-group-overline" role="doc-subtitle">{{ overline }}</p>
	</header>
</vp-newsletter>
