import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { Extra } from '@valk-nx/compositions/ui-receipt-summary/src/lib/receipt-summary.interface';
import { DateParserModule } from '@valk-nx/core/lib/pipes/date-parser/date-parser.module';
import { PricePipe } from '@valk-nx/core/lib/pipes/price/price.pipe';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, DateParserModule, PricePipe, TranslateModule],
	selector: `vp-receipt-summary-extra`,
	standalone: true,
	templateUrl: './extra.component.html',
})
export class ReceiptSummaryExtraComponent {
	@Input({ required: true }) extra: Extra;
	@Input({ required: true }) currency = 'EUR';
}
