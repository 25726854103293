import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HashService {
	async hashString(stringToHash: string): Promise<string> {
		const encoder = new TextEncoder();
		const data = encoder.encode(stringToHash);
		const hashBuffer = await globalThis.crypto?.subtle?.digest(
			'SHA-256',
			data,
		);

		const hashArray = Array.from(new Uint8Array(hashBuffer));
		/* istanbul ignore next */
		const hash = hashArray
			.map((item) => item.toString(16).padStart(2, '0'))
			.join('');

		return hash;
	}
}
