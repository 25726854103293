export class ObjectHelper {
	static getCheckInTimes(
		checkInTime: string,
	): { value: string; label: string }[] {
		const checkInTimes = [
			{ value: '11:00', label: '11:00' },
			{ value: '12:00', label: '12:00' },
			{ value: '13:00', label: '13:00' },
			{ value: '14:00', label: '14:00' },
			{ value: '15:00', label: '15:00' },
			{ value: '16:00', label: '16:00' },
			{ value: '17:00', label: '17:00' },
			{ value: '18:00', label: '18:00' },
			{ value: '19:00', label: '19:00' },
			{ value: '20:00', label: '20:00' },
			{ value: '21:00', label: '21:00' },
			{ value: '22:00', label: '22:00' },
			{ value: '23:00', label: '23:00' },
		];
		const index = checkInTimes.findIndex((x) => x.value === checkInTime);
		return index === -1 ? checkInTimes : checkInTimes.slice(index);
	}
}
