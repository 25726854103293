import { getDuration, PERF } from '../common/utils';
import { PAGE_LOAD, TRUNCATED_TYPE } from '../common/constants';
var pageLoadBreakdowns = [['domainLookupStart', 'domainLookupEnd', 'DNS'], ['connectStart', 'connectEnd', 'TCP'], ['requestStart', 'responseStart', 'Request'], ['responseStart', 'responseEnd', 'Response'], ['domLoading', 'domComplete', 'Processing'], ['loadEventStart', 'loadEventEnd', 'Load']];
function getValue(value) {
  return {
    value: value
  };
}
function calculateSelfTime(transaction) {
  var spans = transaction.spans,
    _start = transaction._start,
    _end = transaction._end;
  if (spans.length === 0) {
    return transaction.duration();
  }
  spans.sort(function (span1, span2) {
    return span1._start - span2._start;
  });
  var span = spans[0];
  var spanEnd = span._end;
  var spanStart = span._start;
  var lastContinuousEnd = spanEnd;
  var selfTime = spanStart - _start;
  for (var i = 1; i < spans.length; i++) {
    span = spans[i];
    spanStart = span._start;
    spanEnd = span._end;
    if (spanStart > lastContinuousEnd) {
      selfTime += spanStart - lastContinuousEnd;
      lastContinuousEnd = spanEnd;
    } else if (spanEnd > lastContinuousEnd) {
      lastContinuousEnd = spanEnd;
    }
  }
  if (lastContinuousEnd < _end) {
    selfTime += _end - lastContinuousEnd;
  }
  return selfTime;
}
function groupSpans(transaction) {
  var spanMap = {};
  var transactionSelfTime = calculateSelfTime(transaction);
  spanMap['app'] = {
    count: 1,
    duration: transactionSelfTime
  };
  var spans = transaction.spans;
  for (var i = 0; i < spans.length; i++) {
    var span = spans[i];
    var duration = span.duration();
    if (duration === 0 || duration == null) {
      continue;
    }
    var type = span.type,
      subtype = span.subtype;
    var key = type.replace(TRUNCATED_TYPE, '');
    if (subtype) {
      key += '.' + subtype;
    }
    if (!spanMap[key]) {
      spanMap[key] = {
        duration: 0,
        count: 0
      };
    }
    spanMap[key].count++;
    spanMap[key].duration += duration;
  }
  return spanMap;
}
function getSpanBreakdown(transactionDetails, _ref) {
  var details = _ref.details,
    _ref$count = _ref.count,
    count = _ref$count === void 0 ? 1 : _ref$count,
    duration = _ref.duration;
  return {
    transaction: transactionDetails,
    span: details,
    samples: {
      'span.self_time.count': getValue(count),
      'span.self_time.sum.us': getValue(duration * 1000)
    }
  };
}
export function captureBreakdown(transaction, timings) {
  if (timings === void 0) {
    timings = PERF.timing;
  }
  var breakdowns = [];
  var name = transaction.name,
    type = transaction.type,
    sampled = transaction.sampled;
  var transactionDetails = {
    name: name,
    type: type
  };
  if (!sampled) {
    return breakdowns;
  }
  if (type === PAGE_LOAD && timings) {
    for (var i = 0; i < pageLoadBreakdowns.length; i++) {
      var current = pageLoadBreakdowns[i];
      var start = timings[current[0]];
      var end = timings[current[1]];
      var duration = getDuration(start, end);
      if (duration === 0 || duration == null) {
        continue;
      }
      breakdowns.push(getSpanBreakdown(transactionDetails, {
        details: {
          type: current[2]
        },
        duration: duration
      }));
    }
  } else {
    var spanMap = groupSpans(transaction);
    Object.keys(spanMap).forEach(function (key) {
      var _key$split = key.split('.'),
        type = _key$split[0],
        subtype = _key$split[1];
      var _spanMap$key = spanMap[key],
        duration = _spanMap$key.duration,
        count = _spanMap$key.count;
      breakdowns.push(getSpanBreakdown(transactionDetails, {
        details: {
          type: type,
          subtype: subtype
        },
        duration: duration,
        count: count
      }));
    });
  }
  return breakdowns;
}