import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicModule } from 'ng-dynamic-component';

import { CarouselComponent as SbCarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { CarouselWithAvailabilityWidgetComponent } from '@valk-nx/storyblok/components/carousel-with-availability-widget/src/lib/carousel-with-availability-widget';
import { CarouselWithBookingWidgetComponent } from '@valk-nx/storyblok/components/carousel-with-booking-widget/src/lib/carousel-with-booking-widget';
import { StoryblokIntroductionSectionComponent } from '@valk-nx/storyblok/components/introduction-section/src/lib/introduction-section';
import { StoryblokItemsSliderComponent } from '@valk-nx/storyblok/components/items-slider-section/src/lib/items-slider-section';
import { StoryblokMasonryComponent } from '@valk-nx/storyblok/components/masonry-section/src/lib/masonry-section';
import { NewsletterComponent as SbNewsletterComponent } from '@valk-nx/storyblok/components/newsletter/src/lib/newsletter';
import { SectionComponent } from '@valk-nx/storyblok/components/section/src/lib/section';
import { StoryblokStoryTellingComponent } from '@valk-nx/storyblok/components/storytelling-section/src/lib/storytelling-section';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

const Components = {
	carousel: SbCarouselComponent,
	carouselWithAvailabilityWidget: CarouselWithAvailabilityWidgetComponent,
	carouselWithWidget: CarouselWithBookingWidgetComponent,
	introductionSection: StoryblokIntroductionSectionComponent,
	itemsSliderSection: StoryblokItemsSliderComponent,
	masonrySection: StoryblokMasonryComponent,
	newsletter: SbNewsletterComponent,
	section: SectionComponent,
	storytellingSection: StoryblokStoryTellingComponent,
};

@Component({
	selector: 'sb-page',
	templateUrl: './page.component.html',
	standalone: true,
	imports: [CommonModule, DynamicModule, StoryblokDirective],
})
export class PageComponent extends StoryblokRootDirective {
	components: typeof Components = Components;

	@Input({ required: true }) content!: unknown[];

	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) robotsNoFollow!: boolean;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) canonical!: string;
}
