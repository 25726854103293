<vp-booking-widget
	*ngrxLet="isMobile$ | async as isMobile"
	[bookingtoolUrl]="bookingtoolUrl"
	[calendarType]="calendarType"
	[currency]="currency"
	[dealGUID]="dealGUID"
	[defaultAdults]="defaultAdults"
	[hotelGUID]="hotelGUID"
	[initialArrivalDate]="(initialArrivalDate$ | async) || undefined"
	[initialNumberOfNights]="initialNumberOfNights$ | async"
	[initialOccupancy]="
		(initialOccupancy$ | async) || [
			{ adults: defaultAdults, children: 0, infants: 0 },
		]
	"
	[maxDate]="maxDate"
	[maxNights]="maxNights"
	[minDate]="minDate"
	[minNights]="minNights"
	[occupancyRestrictions]="{
		adults: {
			min: minAdults,
			max: maxAdults,
		},
		children: {
			min: minChildren,
			max: maxChildren,
		},
		infants: {
			min: minInfants,
			max: maxInfants,
		},
	}"
	[showPrices]="showPrices"
	[validFrom]="validFrom"
	[validUntil]="validUntil"
	(bookingWidgetButtonClicked)="onBookingWidgetButtonClicked($event)"
/>
