<vp-hotel-by-deal-card
	[ctaUrl]="ctaUrl"
	[currency]="currency"
	[editableInStoryblok]="_editable"
	[hotelName]="hotelName"
	[image]="flatImage"
	[numberOfRatings]="numberOfRatings"
	[numberOfStars]="numberOfStars"
	[price]="price"
	[rating]="rating"
	[ribbonColor]="ribbonColor"
	[ribbonText]="ribbonText"
	[tags]="flatTags"
	[usps]="flatUsps"
	[validFrom]="validFrom"
	[validUntil]="validUntil"
/>
