import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { IconVariantType, USPInterface } from './usp-bar.interface';

@Component({
	selector: 'vp-usp-bar',
	standalone: true,
	imports: [CommonModule, AngularSvgIconModule, TranslateModule],
	templateUrl: './usp-bar.component.html',
})
export class UspBarComponent {
	@Input({ required: true }) usps: USPInterface[] | undefined;
	@Input() isElevatedIcon = true;
	@Input() isStacked = false;
	@Input() isVertical = false;
	@Input({ transform: iconTypeClass }) iconType: '' | 'solid' | 'shaded' = '';
	@Input({ transform: iconVariantClass }) iconVariant: IconVariantType =
		'default';
}

function iconVariantClass(variant: string): string {
	switch (variant) {
		case 'accent':
			return 'icon-container-accent';
		case 'success':
			return 'icon-container-success';
		case 'warning':
			return 'icon-container-warning';
		case 'error':
			return 'icon-container-error';
		case 'info':
			return 'icon-container-info';
		default:
			return 'default';
	}
}

function iconTypeClass(iconType: string): string {
	switch (iconType) {
		case 'shaded':
			return 'icon-container-shaded';
		case 'solid':
			return 'icon-container-solid';
		default:
			return '';
	}
}
