export function Debounce(delay = 15): MethodDecorator {
	return function (
		_target: unknown,
		_propertyKey: string,
		descriptor: PropertyDescriptor,
	) {
		const timeoutKey = Symbol();

		const original = descriptor.value;

		descriptor.value = function (...args) {
			clearTimeout(this[timeoutKey]);
			this[timeoutKey] = setTimeout(
				() => original.apply(this, args),
				delay,
			);
		};

		return descriptor;
	};
}
