<div [ngClass]="{ 'pricing-striked': striked }" class="flex">
	<ng-content />
	<div class="pricing-value">
		@for (priceFormat of priceFormatted; track priceFormat) {
			@switch (priceFormat.type) {
				@case ('currency') {
					<span class="first:mr-1 last:ml-1">
						{{ priceFormat.value }}
					</span>
				}
				@case ('minusSign') {
					<span> - </span>
				}
				@case ('integer') {
					<span> {{ priceFormat.value }} </span>
				}
				@case ('group') {
					<span> {{ priceFormat.value.trim() }} </span>
				}
				@case ('fraction') {
					<span class="pricing-cents">
						{{ priceFormat.value }}
					</span>
				}
			}
		}
	</div>
</div>
