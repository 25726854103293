import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, Config } from './config';
/**
 * Provides a singular way to retrieve the various variables,
 * things such as var templating and inheritance can be handled here.
 *
 * This also prevents from users accidentally changing values and allows for simple unit testing.
 */
@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	constructor(
		@Inject(APP_CONFIG) private readonly config: Config,
		@Inject(DOCUMENT) private readonly document: Document,
	) {
		if (this.config.theme) {
			this.document.documentElement.classList.add(this.config.theme);
		}
	}

	get apiCookies() {
		return this.config.apiCookies;
	}

	get apiUrl() {
		return this.config.apiUrl;
	}

	get assetsRoot() {
		return this.config.assetsRoot;
	}

	get bookingtool() {
		return this.config.bookingtool;
	}

	get environment() {
		return this.config.environment;
	}

	get gtmCode() {
		return this.config.gtmCode;
	}

	get host() {
		return this.config.host;
	}

	get hotelSlug() {
		return this.config.hotelSlug;
	}

	get name() {
		return this.config.name;
	}

	get origin() {
		return this.config.origin;
	}

	get pollingTime() {
		return this.config.pollingTime;
	}

	get recaptchaKey() {
		return this.config.recaptchaKey;
	}

	get storyblokEndpoint() {
		return this.config.storyblokEndpoint;
	}

	get storyblokAccesstoken() {
		return this.config.storyblokAccesstoken;
	}
}
