import { isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

import { ObjectHelper } from '@valk-nx/helpers/lib/object/object.helper';
import { CountryService } from '@valk-nx/services/countries/src/lib/countries.service';

@Component({
	selector: 'formly-field-select',
	template: `
		<vp-dropdown
			[formControl]="formControl"
			[hasError]="showError"
			[inputId]="field.id"
			[isRequired]="to.required"
			[isValid]="formControl.valid && formControl.touched"
			[name]="field.key"
			[placeholder]="to.placeholder"
			[searchable]="to['searchable']"
			[selectData]="to['selectOptions']"
			[useAsFormControl]="true"
			[useAutoComplete]="to['useAutoComplete']"
			[value]="formControl.value"
			[variant]="to['variant']"
			ngDefaultControl
		/>
	`,
})
export class SelectComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	constructor(
		public translate: TranslateService,
		public countryService: CountryService,
		private readonly ref: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private readonly platformId: string,
	) {
		super();
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			if (
				this.field.props['selectType'] === 'country' ||
				this.field.props['selectType'] === 'giftcardCountries'
			) {
				this.countryService
					.getCountries(this.translate.currentLang)
					.pipe(take(1))
					.subscribe((response) => {
						let selectOptions = response;
						if (
							this.field.props['selectType'] ===
							'giftcardCountries'
						) {
							const giftcardOptions = this.field.props[
								'selectOptions'
							].map((option) => {
								return response.find(
									(giftcard) =>
										giftcard.value === option.value,
								);
							});

							selectOptions = giftcardOptions;
						}

						this.field.props['selectOptions'] = selectOptions;
						this.ref.markForCheck();
					});
			} else if (this.field.props['selectType'] === 'arrivalTime') {
				this.field.props['selectOptions'] =
					ObjectHelper.getCheckInTimes(this.field.defaultValue);

				this.ref.markForCheck();
			}
		}
	}
}
