import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Instance } from 'flatpickr/dist/types/instance';

import { FlatpickrInterface } from './flatpickr.interface';

export interface FlatpickrState extends EntityState<FlatpickrInterface> {
	instance: Instance;
	startDate: Date;
	endDate: Date;
	nights: number;
	price: number;
	entities: Record<string, FlatpickrInterface> | null;
}

export const updateInstance = (state, instance: Instance) => ({
	...state,
	instance,
});
export const updateStartDate = (state, startDate: Date) => ({
	...state,
	startDate,
});
export const updateEndDate = (state, endDate: Date) => ({
	...state,
	endDate,
});
export const updateDates = (state, dates: Date[]) => ({
	...state,
	startDate: dates[0],
	endDate: dates[1],
});
export const updateNights = (state, nights: number) => ({
	...state,
	nights,
});
export const updatePrice = (state, price: number) => ({
	...state,
	price,
});

export const adapter: EntityAdapter<FlatpickrInterface> =
	createEntityAdapter<FlatpickrInterface>();

export const initialState: FlatpickrState = adapter.getInitialState({
	instance: undefined,
	startDate: undefined,
	endDate: undefined,
	nights: undefined,
	price: undefined,
});
