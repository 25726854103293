const SMALL_MOBILE_MAX_WIDTH = 320;
const MOBILE_MAX_WIDTH = 639;
const SMALL_TABLET_MAX_WIDTH = 767;
const TABLET_MAX_WIDTH = 1023;

export const TABLET = SMALL_TABLET_MAX_WIDTH + 1;
export const SMALL_DESKTOP = TABLET_MAX_WIDTH + 1;

export function isSmallMobile(currentScreenWidth: number) {
	return currentScreenWidth <= SMALL_MOBILE_MAX_WIDTH;
}

export function isMobile(currentScreenWidth: number) {
	return currentScreenWidth <= MOBILE_MAX_WIDTH;
}

export function isSmallTablet(currentScreenWidth: number) {
	return currentScreenWidth <= SMALL_TABLET_MAX_WIDTH;
}

export function isTablet(currentScreenWidth: number) {
	return currentScreenWidth <= TABLET_MAX_WIDTH;
}
export function isDesktop(currentScreenWidth: number) {
	return currentScreenWidth > TABLET_MAX_WIDTH;
}
