<vp-popover
	*ngrxLet="{
		isSmallMobile: isSmallMobile$,
		isSmallTablet: isSmallTablet$,
		numberOfNights: numberOfNights$,
		selectedDates: selectedDates$,
		prettyDate: prettyDate$,
	} as model"
	[boundaryTargetId]="boundaryTargetId"
	[disabled]="disabled"
	[isFullHeight]="model.isSmallMobile"
	[overruleCreateInstance]="true"
	[popoverOffset]="popoverOffset"
	[showHeader]="false"
	[showPopover]="isPopoverOpen"
	(isOpen)="popoverStateChanged($event)"
	className="!p-4 sm:!p-6"
	popoverId="popover-date-range-picker-with-stepper"
	triggerId="trigger-button-date-range-picker-with-stepper"
>
	<div
		#popoverTrigger
		class="flex flex-col"
		data-test-id="date-range-picker-with-stepper"
		id="trigger-button-date-range-picker-with-stepper"
		vc-clicked
	>
		<vp-label
			[inputName]="inputConfig.name"
			[labelText]="inputLabelSettings.text"
			[position]="inputLabelSettings.position"
			class="z-0 focus-within:z-10"
			data-test-id="date-input"
		>
			<vp-input
				[autocomplete]="inputConfig.autocomplete"
				[defaultStyle]="inputConfig.defaultStyle"
				[disabled]="inputConfig.disabled"
				[hasError]="isPastDate || inputConfig.hasError"
				[icon]="inputConfig.icon"
				[iconPosition]="inputConfig.iconPosition"
				[initialValue]="model.prettyDate"
				[inputId]="'trigger-button-' + inputConfig.name"
				[isValid]="inputConfig.isValid"
				[name]="inputConfig.name"
				[placeholder]="inputConfig.placeholder"
				[readonly]="inputConfig.readonly"
				[required]="inputConfig.required"
				borderRadiusStyle="rounded"
			/>
		</vp-label>
	</div>

	@if (model.isSmallTablet) {
		<vp-popover-header-title>
			<span class="text-lg font-semibold text-dark-primary">
				{{ 'global.period-of-stay' | translate }}
			</span>
		</vp-popover-header-title>
	}

	<vp-popover-content>
		<div
			[class.datepicker--hidden]="!isPopoverOpen"
			id="popover-input-date-range-picker-with-stepper"
		>
			<div
				class="mb-6 flex flex-row items-center justify-between gap-3 md:justify-start"
				data-test-id="stepper-label"
			>
				<label
					class="font-semibold text-dark-primary"
					for="night-stepper"
					>{{ 'global.number-of-nights' | translate }}
				</label>
				<vp-stepper
					[max]="maxNights"
					[min]="minNights"
					[readonly]="true"
					[(value)]="model.numberOfNights"
					(valueChange)="updateNumberOfNights($event)"
					data-test-id="nights-stepper"
					inputId="night-stepper"
				/>
			</div>
			<vp-calendar
				[calendarData]="calendarData"
				[config]="config$ | async"
				[isLoading]="isLoading"
				[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
				(datePickerEmitter)="onDatePickerEmitter($event)"
				(dayCreate)="addPricingToDayElement($event)"
				(emitHasError)="onEmitHasError($event)"
				(emitMonthChange)="onEmitMonthChange($event)"
			/>
			@if (showPrices) {
				<p class="mt-4 text-end text-sm text-dark-tertiary">
					{{
						'components.booking-widget.price-disclosure' | translate
					}}
				</p>
			}
		</div>
	</vp-popover-content>
	@if (showButtonOnMobile && model.isSmallTablet) {
		<vp-popover-footer>
			<div class="mt-4 flex flex-col gap-2">
				<div class="text-center">
					<div class="justify-center text-center">
						@switch (model.selectedDates.length) {
							@case (0) {
								<div
									class="mr-auto text-base text-dark-tertiary"
								>
									{{
										'global.choose-arrival-date' | translate
									}}
								</div>
							}
							@case (2) {
								<div class="text-base text-dark-tertiary">
									{{ model.numberOfNights }}
									{{
										(model.numberOfNights === 1
											? 'global.night'
											: 'global.nights'
										) | translate
									}}
								</div>
							}
						}
					</div>
				</div>

				<button
					[disabled]="!model.prettyDate"
					(click)="emitShowAvailabilityButton.emit($event)"
					class="btn btn-primary w-full"
					data-test-id="show-availability-button"
				>
					{{ 'global.show-availability' | translate }}
				</button>
			</div>
		</vp-popover-footer>
	}
</vp-popover>
