import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	input,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { TagComponent } from '@valk-nx/components/ui-tag/src/lib/tag';

import { LinkListItem } from './link-list.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-link-list`,
	templateUrl: './link-list.component.html',
	standalone: true,
	imports: [
		AngularSvgIconModule,
		CommonModule,
		RouterModule,
		TagComponent,
		TranslateModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LinkListComponent {
	linkListItems = input.required<LinkListItem[]>();

	showIcons = input(true);
	showDivider = input(true);
	showBorderTop = input(false);
	useChevron = input(false);
}
