import { Injectable } from '@angular/core';

import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/operators';
import dayjs from 'dayjs';
import { Instance } from 'flatpickr/dist/types/instance';
import { filter, Observable, tap } from 'rxjs';

import {
	FlatpickrState,
	initialState,
	updateDates,
	updateEndDate,
	updateInstance,
	updateNights,
	updatePrice,
	updateStartDate,
} from './flatpickr.reducers';
import {
	selectEndDate,
	selectInstance,
	selectNights,
	selectPrice,
	selectSelectedDates,
	selectStartDate,
} from './flatpickr.selectors';

@Injectable()
export class FlatpickrFacade extends ComponentStore<FlatpickrState> {
	constructor() {
		super(initialState);
	}

	/** Selectors */
	readonly selectInstance$: Observable<Instance> =
		this.select(selectInstance);
	readonly selectStartDate$: Observable<Date> = this.select(selectStartDate);
	readonly selectEndDate$: Observable<Date> = this.select(selectEndDate);
	readonly selectNights$: Observable<number> = this.select(selectNights);
	readonly selectPrice$: Observable<number> = this.select(selectPrice);
	readonly selectSelectedDates$: Observable<Date[]> =
		this.select(selectSelectedDates);

	/** Reducers */
	readonly setInstance = this.updater(updateInstance);
	readonly setStartDate = this.updater(updateStartDate);
	readonly setEndDate = this.updater(updateEndDate);
	readonly setDates = this.updater(updateDates);
	readonly setNights = this.updater(updateNights);
	readonly setPrice = this.updater(updatePrice);

	/** Effects */
	readonly setEndDateAccordingToNights = this.effect(
		(setEndDateAccordingToNights$: Observable<void>) =>
			setEndDateAccordingToNights$.pipe(
				concatLatestFrom(() => [
					this.selectStartDate$,
					this.selectNights$,
				]),
				filter(
					([_trigger, startDate, nights]) => !!(startDate && nights),
				),
				tap(([_trigger, startDate, nights]) => {
					const endDate = dayjs(startDate)
						.add(nights, 'days')
						.toDate();

					this.setDatesAndUpdateInCalendar([startDate, endDate]);
				}),
			),
	);

	readonly setDatesAndUpdateNights = this.effect(
		(setDatesAndUpdateNights$: Observable<Date[]>) =>
			setDatesAndUpdateNights$.pipe(
				tap((selectedDates: Date[]) => {
					this.setDatesAndUpdateInCalendar(selectedDates);

					let nights = 0;
					if (selectedDates.length === 2) {
						nights = dayjs(selectedDates[1]).diff(
							selectedDates[0],
							'day',
						);
					}
					this.setNights(nights);
				}),
			),
	);

	readonly clearFlatpickrInstance = this.effect(
		(clearFlatpickrInstance$: Observable<void>) =>
			clearFlatpickrInstance$.pipe(
				concatLatestFrom(() => [this.selectInstance$]),
				filter(([_trigger, instance]) => !!instance),
				tap(([_trigger, instance]) => {
					this.setDates([]);
					instance.clear(true, false);
				}),
			),
	);

	readonly jumpToDate = this.effect((jumpToDate$: Observable<Date>) =>
		jumpToDate$.pipe(
			concatLatestFrom(() => [this.selectInstance$]),
			filter(([_trigger, instance]) => !!instance),
			tap(([date, instance]) => {
				instance.jumpToDate(date);
			}),
		),
	);

	readonly setDatesAndUpdateInCalendar = this.effect(
		(updateDatesInCalendar$: Observable<Date[]>) =>
			updateDatesInCalendar$.pipe(
				concatLatestFrom(() => [this.selectInstance$]),
				filter(([_dates, instance]) => !!instance),
				tap(([dates, instance]) => {
					this.setDates(dates);
					instance.selectedDates = dates;
					instance.redraw();
				}),
			),
	);

	readonly redrawFlatpickr = this.effect(
		(redrawFlatpickr$: Observable<void>) =>
			redrawFlatpickr$.pipe(
				concatLatestFrom(() => [this.selectInstance$]),
				filter(([_trigger, instance]) => !!instance),
				tap(([_trigger, instance]) => {
					instance.redraw();
				}),
			),
	);

	readonly updatePrice = this.effect((updatePrice$: Observable<void>) =>
		updatePrice$.pipe(
			concatLatestFrom(() => [this.selectInstance$]),
			filter(([_trigger, instance]) => !!instance),
			tap(([_trigger, instance]) => {
				/* Read the price from the dayElement in the calendar */
				if (instance?.selectedDateElem?.childNodes.length > 1) {
					const price = parseInt(
						instance.selectedDateElem.childNodes[1].textContent.replace(
							/\D/g,
							'',
						),
					);
					this.setPrice(price);
				} else {
					this.setPrice(0);
				}
			}),
		),
	);

	readonly updateShowMonths = this.effect(
		(updateShowMonths$: Observable<number>) =>
			updateShowMonths$.pipe(
				concatLatestFrom(() => [this.selectInstance$]),
				filter(([_showmonths, instance]) => !!instance),
				tap(([showmonths, instance]) => {
					instance.set('showMonths', showmonths);
				}),
			),
	);
}
