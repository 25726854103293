import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import { map } from 'rxjs';

import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';

import {
	AvailabilityDealsRequest,
	AvailabilityDealsResponse,
} from './availability-deal.interface';

export const AVAILABILITY_DEAL_SERVICE_URL = new InjectionToken<string[]>(
	'availability-deal_service-url',
);

export const AVAILABILITY_DEAL_API_COOKIES = new InjectionToken<string[]>(
	'availability-deal_service-cookies',
);

/*
 * @deprecated should use the more reusable AvailabilityDatesService
 */
@Injectable({
	providedIn: 'root',
})
export class AvailabilityDealService {
	constructor(
		private readonly http: HttpClient,
		@Inject(AVAILABILITY_DEAL_SERVICE_URL)
		private readonly serviceUrl: string,
		@Inject(AVAILABILITY_DEAL_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	getDealAvailability(request: AvailabilityDealsRequest) {
		return this.http
			.post<HALResponse<AvailabilityDealsResponse>>(
				`${this.serviceUrl}availability/deals`,
				request,
				{
					...this.apiCookies,
					observe: 'body',
					responseType: 'json',
				},
			)
			.pipe(map((resp) => resp.data));
	}
}
