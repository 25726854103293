import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-input',
	template: `
		<vp-input
			[forceInitialValue]="to['forceInitialValue'] || false"
			[formControl]="formControl"
			[hasError]="showError"
			[icon]="to['icon']"
			[iconPosition]="to['iconPosition']"
			[initialValue]="formControl.value"
			[inputId]="field.id"
			[isLoading]="to['isLoading']"
			[isOptional]="to['optional']"
			[isValid]="formControl.valid"
			[maskOptions]="to['maskOptions']"
			[name]="field.id"
			[placeholder]="to.placeholder"
			[readonly]="to.readonly"
			[type]="field.type"
			ngDefaultControl
		/>
	`,
})
export class InputComponent extends FieldType<FieldTypeConfig> {}
