import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
	NavigationEnd,
	NavigationExtras,
	Params,
	Router,
} from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { fallbackLanguage } from '@valk-nx/core/lib/core';
import { GoogleTagManagerService } from '@valk-nx/services/google-tag-manager/src/lib/google-tag-manager.service';

import * as RouterActions from './router.actions';

@Injectable()
export class RouterEffects {
	navigate$: Observable<{
		path: string | string[];
		query?: Params;
		extras?: NavigationExtras;
	}>;
	navigateBack$: Observable<Action>;
	navigateForward$: Observable<Action>;
	pageView$: Observable<NavigationEnd>;

	constructor(
		private readonly actions$: Actions,
		private readonly router: Router,
		private readonly location: Location,
		private readonly gtmService: GoogleTagManagerService,
	) {
		this.navigate$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(RouterActions.go),
					tap(
						(action: {
							path: string | string[];
							query?: Params;
							extras?: NavigationExtras;
						}) => {
							const routerPath = [`/${action.path}`];
							this.router.navigate(routerPath, {
								queryParams: action.query,
								...action.extras,
							});
						},
					),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.navigateBack$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(RouterActions.back),
					tap(() => this.location.back()),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.navigateForward$ = createEffect(
			() =>
				this.actions$.pipe(
					ofType(RouterActions.forward),
					tap(() => this.location.forward()),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);

		this.pageView$ = createEffect(
			() =>
				this.router.events.pipe(
					filter((event) => event instanceof NavigationEnd),
					tap((event: NavigationEnd) => {
						const page = this.parseGtmSecureUrl(
							event.urlAfterRedirects,
						);
						const segments = page.path.split('/');
						/* istanbul ignore next */
						const siteLanguage =
							segments[1]?.length === 2
								? segments[1]
								: fallbackLanguage;

						this.gtmService.push({
							event: 'pageview',
							page: {
								fullUrl: page.fullUrl,
								path: page.path,
								queryParams: page.queryParams,
							},
							siteLanguage: siteLanguage.toUpperCase(),
						});
					}),
				),
			{ dispatch: false, useEffectsErrorHandler: true },
		);
	}

	private parseGtmSecureUrl(url: string): {
		path: string;
		fullUrl: string;
		queryParams: Params;
	} {
		const splittedEventUrl = url.split('?');
		const params = new URLSearchParams(splittedEventUrl[1]);
		params.delete('reservationToken');

		const parsedParams = params.toString().length > 0 ? `?${params}` : '';

		return {
			path: splittedEventUrl[0],
			fullUrl: `${splittedEventUrl[0]}${parsedParams}`,
			queryParams: Object.fromEntries(params.entries()),
		};
	}
}
