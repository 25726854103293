import plugin from 'tailwindcss/plugin';

/**
 * This generates a not-empty: pseudoselector to be used within tailwind.
 * For example: `not-empty:mb-4` will generate `:not(:empty) { margin-bottom: 4rem }`
 */

const notSelector = plugin(({ addVariant, e }) => {
	addVariant('not-empty', ({ modifySelectors, separator }) => {
		modifySelectors(({ className }) => {
			const element = e(`not-empty${separator}${className}`);
			return `.${element}:not(:empty)`;
		});
	});

	// Allows for classes like group-not-open:block to be used
	addVariant('group-not-open', ':merge(.group):not([open]) &');
});

export default notSelector;
