import plugin from 'tailwindcss/plugin';

const textLink = plugin.withOptions(() => {
	return function ({ addComponents, theme, e }) {
		const variants = {
			color: `var(--text-link-color, ${theme('colors.select.100')})`,
			activeColor: `var(--text-link-color-active, ${theme(
				'colors.select.125',
			)})`,
			focusColor: `var(--text-link-color-focus, ${theme(
				'colors.select.125',
			)})`,
			hoverColor: `var(--text-link-color-hover, ${theme(
				'colors.select.125',
			)})`,
			visitedColor: `var(--text-link-color-visited, ${theme(
				'colors.select.100',
			)})`,
		};

		const linkCss = {
			color: variants.color,
			'text-decoration': 'underline',
			cursor: 'pointer',
			'&:active': {
				color: variants.activeColor,
			},
			'&:focus': {
				color: variants.focusColor,
			},
			'&:hover': {
				color: variants.hoverColor,
			},
			'&:visited': {
				color: variants.visitedColor,
			},
		};

		addComponents({
			[`.${e('text-link')}`]: linkCss,
		});

		addComponents({
			[`.${e('markdown')}`]: { a: linkCss },
		});
	};
});

export default textLink;
