import { BEFORE_EVENT, AFTER_EVENT } from './constants';
var EventHandler = function () {
  function EventHandler() {
    this.observers = {};
  }
  var _proto = EventHandler.prototype;
  _proto.observe = function observe(name, fn) {
    var _this = this;
    if (typeof fn === 'function') {
      if (!this.observers[name]) {
        this.observers[name] = [];
      }
      this.observers[name].push(fn);
      return function () {
        var index = _this.observers[name].indexOf(fn);
        if (index > -1) {
          _this.observers[name].splice(index, 1);
        }
      };
    }
  };
  _proto.sendOnly = function sendOnly(name, args) {
    var obs = this.observers[name];
    if (obs) {
      obs.forEach(function (fn) {
        try {
          fn.apply(undefined, args);
        } catch (error) {
          console.log(error, error.stack);
        }
      });
    }
  };
  _proto.send = function send(name, args) {
    this.sendOnly(name + BEFORE_EVENT, args);
    this.sendOnly(name, args);
    this.sendOnly(name + AFTER_EVENT, args);
  };
  return EventHandler;
}();
export default EventHandler;