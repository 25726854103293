import plugin from 'tailwindcss/plugin';

const typographyPlugin = plugin(function ({ addBase, addDefaults, theme, e }) {
	const variants = {
		h1: {
			fontSize: `var(--h1-font-size, ${theme('fontSize.3xl')})`,
			fontSizeBreakpoint: `var(--h1-font-size-desktop, ${theme(
				'fontSize.4xl',
			)})`,
			lineHeight: `var(--h1-line-height, ${theme('lineHeight.tight')})`,
			lineHeightBreakpoint: `var(--h1-line-height-desktop, ${theme(
				'lineHeight.tight',
			)})`,
			marginBottom: `var(--h1-margin-bottom, ${theme('spacing.6')})`,
		},
		h2: {
			fontSize: `var(--h2-font-size, ${theme('fontSize.2xl')})`,
			fontSizeBreakpoint: `var(--h2-font-size-desktop, ${theme(
				'fontSize.3xl',
			)})`,
			lineHeight: `var(--h2-line-height, ${theme('lineHeight.tight')})`,
			lineHeightBreakpoint: `var(--h2-line-height-desktop, ${theme(
				'lineHeight.tight',
			)})`,
			marginBottom: `var(--h2-margin-bottom, ${theme('spacing.5')})`,
		},
		h3: {
			fontSize: `var(--h3-font-size, ${theme('fontSize.xl')})`,
			fontSizeBreakpoint: `var(--h3-font-size-desktop, ${theme(
				'fontSize.2xl',
			)})`,
			lineHeight: `var(--h3-line-height, ${theme('lineHeight.snug')})`,
			lineHeightBreakpoint: `var(--h3-line-height-desktop, ${theme(
				'lineHeight.tight',
			)})`,
			marginBottom: `var(--h3-margin-bottom, ${theme('spacing.4')})`,
		},
		h4: {
			fontSize: `var(--h4-font-size, ${theme('fontSize.lg')})`,
			fontSizeBreakpoint: `var(--h4-font-size-desktop, ${theme(
				'fontSize.xl',
			)})`,
			lineHeight: `var(--h4-line-height, ${theme('lineHeight.snug')})`,
			lineHeightBreakpoint: `var(--h4-line-height-desktop, ${theme(
				'lineHeight.snug',
			)})`,
			marginBottom: `var(--h4-margin-bottom, ${theme('spacing.3')})`,
		},
		h5: {
			fontSize: `var(--h5-font-size, ${theme('fontSize.base')})`,
			fontSizeBreakpoint: `var(--h5-font-size-desktop, ${theme(
				'fontSize.lg',
			)})`,
			lineHeight: `var(--h5-line-height, ${theme('lineHeight.normal')})`,
			lineHeightBreakpoint: `var(--h5-line-height-desktop, ${theme(
				'lineHeight.snug',
			)})`,
			marginBottom: `var(--h5-margin-bottom, ${theme('spacing.2')})`,
		},
		h6: {
			fontSize: `var(--h6-font-size, ${theme('fontSize.sm')})`,
			fontSizeBreakpoint: `var(--h6-font-size-desktop, ${theme(
				'fontSize.base',
			)})`,
			lineHeight: `var(--h6-line-height, ${theme('lineHeight.normal')})`,
			lineHeightBreakpoint: `var(--h6-line-height-desktop, ${theme(
				'lineHeight.relaxed',
			)})`,
			marginBottom: `var(--h6-margin-bottom, ${theme('spacing.2')})`,
		},
	};

	Object.keys(variants).forEach((key) => {
		const heading = `--vp-heading-${key}`;
		// NOTE: Expose the variables to the CSS root so that they can be used also elsewhere in other plugins
		addDefaults('headings', {
			[`${heading}-font-size`]: variants[key].fontSize,
			[`${heading}-font-size-breakpoint`]:
				variants[key].fontSizeBreakpoint,
			[`${heading}-line-height`]: variants[key].lineHeight,
			[`${heading}-line-height-breakpoint`]:
				variants[key].lineHeightBreakpoint,
			[`${heading}-margin-bottom`]: variants[key].marginBottom,
		});

		addBase({
			// NOTE: As an example this generates h1 and .h1 as classes
			[`${e(key)}, .${e(key)}`]: {
				fontSize: `var(${heading}-font-size)`,
				fontWeight: theme('fontWeight.semibold'),
				lineHeight: `var(${heading}-line-height)`,
				marginBottom: `var(${heading}-margin-bottom)`,

				[`@media (min-width: ${theme('screens.md')})`]: {
					fontSize: `var(${heading}-font-size-breakpoint)`,
					lineHeight: `var(${heading}-line-height-breakpoint)`,
				},
			},
		});
	});
});

export default typographyPlugin;
