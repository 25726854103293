import plugin from 'tailwindcss/plugin';

const footerPlugin = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const buttonColorBackground = `var(--footer-button-color-background, #005442)`;
		const buttonColorText = `var(--footer-button-color-text, ${theme(
			'colors.accent.75',
		)})`;
		const footerColorBackground = `var(--footer-color-background, #092924)`;
		// NOTE: should contain full string; `url('asset.svg')`
		const footerBackgroundImage = `var(--footer-image-background,
			url('/assets/images/misc/toucan-illustration-green.svg')
		)`;
		const footnoteColorBackground = `var(--footer-footnote-color-background, #051B17)`;
		const footerColorBrand = `var(--footer-brand-color, ${theme(
			'colors.accent.100',
		)})`;

		const buttonFont = theme('fontSize')['lg'];
		addComponents({
			[`.${e('footer')}`]: {
				background: footerColorBackground,
				backgroundImage: footerBackgroundImage,
				backgroundPosition: 'right bottom 60px',
				backgroundRepeat: 'no-repeat',
				backgroundSize: '600px 500px',
				color: theme('colors.white'),
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			},

			[`.${e('footer-button')}`]: {
				alignItems: 'center',
				background: buttonColorBackground,
				borderBottomRightRadius: theme('borderRadius.DEFAULT'),
				borderBottomLeftRadius: theme('borderRadius.DEFAULT'),
				color: buttonColorText,
				display: 'flex',
				fontSize: buttonFont[0],
				fontWeight: theme('fontWeight.semibold'),
				height: theme('height.11'),
				lineHeight: buttonFont[1]['lineHeight'],
				padding: `${theme('spacing.2')} ${theme('spacing.6')}`,
			},

			[`.${e('footer-divider')}`]: {
				borderTopColor: footerColorBrand,
			},

			[`.${e('footer-text-brand')}`]: {
				color: footerColorBrand,
			},

			[`.${e('footer-bg-brand')}`]: {
				backgroundColor: footerColorBrand,
			},

			[`.${e('footnote')}`]: {
				background: footnoteColorBackground,
				borderColor: footerColorBrand,
				borderBottomWidth: theme('borderWidth.4'),
			},
		});
	};
});

export default footerPlugin;
