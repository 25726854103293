<div class="relative inline-block w-full">
	<input
		[attr.data-test-id]="'input-' + inputId"
		[autocomplete]="autocomplete"
		[email]="type === 'email'"
		[formControl]="formControl"
		[id]="inputId"
		[mask]="maskOptions?.mask"
		[name]="name"
		[ngClass]="[
			icon
				? iconPosition === 'left' || type === 'password'
					? 'pl-11'
					: 'pr-11'
				: '',
			maskOptions?.prefix && type === 'number' ? 'pl-7' : '',
			hasError ? 'error' : '',
		]"
		[placeholder]="placeholder"
		[prefix]="maskOptions?.prefix || ''"
		[readonly]="readonly"
		[showMaskTyped]="maskOptions?.showMaskedType || false"
		[type]="showPassword ? 'text' : type"
		class="control-input"
	/>
	@if (type === 'password') {
		<div
			class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5"
		>
			<button
				[disabled]="disabled"
				(click)="togglePasswordView()"
				class="btn btn-sm"
				type="button"
			>
				{{
					(showPassword
						? 'global.password.hide-label'
						: 'global.password.show-label'
					) | translate
				}}
			</button>
		</div>
	}
	<div
		[ngClass]="[
			iconPosition === 'left' || type === 'password'
				? 'left-4 top-3'
				: 'right-4 top-3',
			disabled ? 'opacity-50' : 'opacity-100',
			isLoading ? 'opacity-50' : 'opacity-100',
		]"
		class="pointer-events-none absolute inline-block size-5"
	>
		<svg-icon [src]="icon" />
	</div>
	@if (maskOptions?.prefix && type === 'number') {
		<div
			[ngClass]="[
				disabled ? 'opacity-50' : 'opacity-100',
				isLoading ? 'opacity-50' : 'opacity-100',
			]"
			class="pointer-events-none absolute left-4 top-3 inline-block h-5 w-2 leading-5 text-dark-secondary"
		>
			{{ maskOptions?.prefix }}
		</div>
	}
	@if (
		(!isOptional && (isValid || hasError)) ||
		(formControl.value && (isValid || hasError))
	) {
		<div
			class="absolute top-3 -ml-2.5 size-5 rounded-full border-2 border-white bg-white"
		>
			@if (isValid) {
				<svg-icon
					class="text-success-100"
					data-test-id="valid-icon"
					src="assets/icons/icon-checkmark-circle-solid.svg"
				/>
			}
			@if (hasError) {
				<svg-icon
					class="text-error-100"
					data-test-id="error-icon"
					src="assets/icons/icon-exclamation-mark-circle-solid.svg"
				/>
			}
		</div>
	}
</div>
