function filePathToFileName(fileUrl) {
  var origin = window.location.origin || window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  if (fileUrl.indexOf(origin) > -1) {
    fileUrl = fileUrl.replace(origin + '/', '');
  }
  return fileUrl;
}
function cleanFilePath(filePath) {
  if (filePath === void 0) {
    filePath = '';
  }
  if (filePath === '<anonymous>') {
    filePath = '';
  }
  return filePath;
}
function isFileInline(fileUrl) {
  if (fileUrl) {
    return window.location.href.indexOf(fileUrl) === 0;
  }
  return false;
}
function normalizeStackFrames(stackFrames) {
  return stackFrames.map(function (frame) {
    if (frame.functionName) {
      frame.functionName = normalizeFunctionName(frame.functionName);
    }
    return frame;
  });
}
function normalizeFunctionName(fnName) {
  var parts = fnName.split('/');
  if (parts.length > 1) {
    fnName = ['Object', parts[parts.length - 1]].join('.');
  } else {
    fnName = parts[0];
  }
  fnName = fnName.replace(/.<$/gi, '.<anonymous>');
  fnName = fnName.replace(/^Anonymous function$/, '<anonymous>');
  parts = fnName.split('.');
  if (parts.length > 1) {
    fnName = parts[parts.length - 1];
  } else {
    fnName = parts[0];
  }
  return fnName;
}
function isValidStackTrace(stackTraces) {
  if (stackTraces.length === 0) {
    return false;
  }
  if (stackTraces.length === 1) {
    var stackTrace = stackTraces[0];
    return 'lineNumber' in stackTrace;
  }
  return true;
}
export function createStackTraces(stackParser, errorEvent) {
  var error = errorEvent.error,
    filename = errorEvent.filename,
    lineno = errorEvent.lineno,
    colno = errorEvent.colno;
  var stackTraces = [];
  if (error) {
    try {
      stackTraces = stackParser.parse(error);
    } catch (e) {}
  }
  if (!isValidStackTrace(stackTraces)) {
    stackTraces = [{
      fileName: filename,
      lineNumber: lineno,
      columnNumber: colno
    }];
  }
  var normalizedStackTraces = normalizeStackFrames(stackTraces);
  return normalizedStackTraces.map(function (stack) {
    var fileName = stack.fileName,
      lineNumber = stack.lineNumber,
      columnNumber = stack.columnNumber,
      _stack$functionName = stack.functionName,
      functionName = _stack$functionName === void 0 ? '<anonymous>' : _stack$functionName;
    if (!fileName && !lineNumber) {
      return {};
    }
    if (!columnNumber && !lineNumber) {
      return {};
    }
    var filePath = cleanFilePath(fileName);
    var cleanedFileName = filePathToFileName(filePath);
    if (isFileInline(filePath)) {
      cleanedFileName = '(inline script)';
    }
    return {
      abs_path: fileName,
      filename: cleanedFileName,
      function: functionName,
      lineno: lineNumber,
      colno: columnNumber
    };
  });
}
export function filterInvalidFrames(frames) {
  return frames.filter(function (_ref) {
    var filename = _ref.filename,
      lineno = _ref.lineno;
    return typeof filename !== 'undefined' && typeof lineno !== 'undefined';
  });
}