import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ClickOutsideModule } from '@valk-nx/core/lib/directives/click-outside/click-outside.module';

import { PopoverComponent } from './popover';
import {
	PopoverContentDirective,
	PopoverFooterDirective,
	PopoverHeaderDirective,
} from './popover.directive';

@NgModule({
	declarations: [
		PopoverComponent,
		PopoverContentDirective,
		PopoverHeaderDirective,
		PopoverFooterDirective,
	],
	exports: [
		PopoverComponent,
		PopoverContentDirective,
		PopoverHeaderDirective,
		PopoverFooterDirective,
	],
	imports: [
		CommonModule,
		ClickOutsideModule,
		LetDirective,
		AngularSvgIconModule.forRoot(),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PopoverModule {}
