import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { PackageDetailInterface } from './package-details.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [TranslateModule],
	selector: 'vp-package-details',
	templateUrl: './package-details.html',
})
export class PackageDetailsComponent {
	@Input({ required: true }) package: PackageDetailInterface | undefined;
	@Input() className: string | undefined;
}
