<span class="flex flex-col gap-2" data-test-class="receipt-item">
	<div class="flex justify-between gap-4">
		<span class="my-auto" data-test-id="receipt-item-title">
			@if (totalNumberOfItems > 1 && item.accommodationTypes?.length) {
				<span data-test-id="receipt-item-counter">
					{{
						item.accommodationTypes | accommodationType | titlecase
					}}
					{{ index + 1 }}:
				</span>
			}
			@if (item.amount) {
				{{ item.amount }}
				<span aria-hidden="true">x</span>
			}
			<span data-test-id="receipt-item-name">
				{{ item.name }}
			</span>
			@if (item.helperText) {
				<span class="inline text-dark-tertiary">
					<span aria-hidden="true">&bull;</span>&nbsp;<span
						data-test-id="receipt-item-helpertext"
						>{{ item.helperText }}&nbsp;</span
					>
				</span>
			}
			@if (item.moreInfo) {
				<vp-tooltip
					[alignment]="'top-start'"
					class="inline-block pb-[0.2em] align-middle"
					iconSize="w-4"
				>
					<vp-tooltip-content>
						{{ item.moreInfo }}
					</vp-tooltip-content>
				</vp-tooltip>
			}
		</span>
		@if (item.price || item.price === 0) {
			<span
				[ngClass]="{
					'font-bold': someItemHasExtras && !itemWithExtras
				}"
				class="my-auto shrink-0"
				data-test-id="receipt-item-price"
			>
				{{ item.price | price: currency }}
			</span>
		}
	</div>
	@if (itemWithExtras) {
		<ul class="flex flex-col gap-1">
			@for (extra of itemWithExtras.extras; track extra) {
				<li>
					<vp-receipt-summary-extra
						[currency]="currency"
						[extra]="extra"
					/>
				</li>
			}
		</ul>
	}
	@if (
		itemWithExtras && itemWithExtras.totalPrice && totalNumberOfItems > 1
	) {
		<div class="text-right font-bold">
			{{ itemWithExtras.totalPrice | price: currency }}
		</div>
	}
	@if (index < totalNumberOfItems - 1) {
		<hr class="divider h-0.5" />
	}
</span>
