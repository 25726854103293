@if (package) {
	<div
		[class]="className"
		class="border-l-2 border-accent-100 pl-4"
		data-test-id="package"
	>
		@if (package.type) {
			<h3
				class="mb-0 text-sm font-normal text-dark-secondary"
				data-test-id="package-type"
			>
				{{
					'global.' + package.type.toLowerCase() + '-label'
						| translate
				}}
			</h3>
		}

		<p
			class="mb-0 text-base font-bold text-dark-primary"
			data-test-id="package-title"
		>
			{{ package.title }}
		</p>
		<p
			class="mb-0 text-sm font-normal text-dark-secondary"
			data-test-id="package-subtitle"
		>
			{{ package.variation }}
		</p>
	</div>
}
