import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { getContentConfiguration } from '@valk-nx/core/lib/ssr/ssr.helper';

import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/config/config';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

function bootstrap() {
	const key = getContentConfiguration(window.location.host);

	fetch(environment.sitedata + key)
		.then((resp) => resp.json())
		.then((config) => {
			platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
				.bootstrapModule(AppModule, {
					defaultEncapsulation: ViewEncapsulation.None,
				})
				.then(() => {
					if ('serviceWorker' in navigator) {
						navigator.serviceWorker
							.getRegistrations()
							.then(function (registrations) {
								if (registrations.length) {
									for (const registration of registrations) {
										registration.unregister();
									}
								}
							});
					}
				})
				.catch((err) => console.error(err));
		})
		.catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
	bootstrap();
} else {
	document.addEventListener('DOMContentLoaded', bootstrap);
}
