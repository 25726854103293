import { Pipe, PipeTransform } from '@angular/core';

import { ColumnComponentAlignments } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Pipe({
	name: 'columnAlignment',
	pure: true,
	standalone: true,
})
export class ColumnAlignment implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transform(blok: any, alignment: ColumnComponentAlignments): any {
		const alignmentBloks = ['headingGroup', 'richText'];

		if (!alignmentBloks.includes(blok.component)) {
			return blok;
		}

		return {
			...blok,
			alignment: alignment === 'center' ? 'center' : 'left',
		};
	}
}
