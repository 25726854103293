import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { debounceTime, ReplaySubject, takeUntil } from 'rxjs';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'vpCheckbox',
	host: {
		'[attr.disabled]': 'disabled || null',
		'[class.is-disabled]': 'disabled',
	},
	standalone: true,
	selector: `vp-checkbox`,
	templateUrl: './checkbox.html',
	imports: [NgClass, NgTemplateOutlet, FormsModule, ReactiveFormsModule],
})
export class CheckboxComponent implements OnInit, OnChanges, OnDestroy {
	_disabled = false;
	@Input() set disabled(disabledVal: boolean) {
		if (this.customFormControl) {
			if (disabledVal) {
				this.customFormControl.disable();
			} else {
				this.customFormControl.enable();
			}
		}
		this._disabled = disabledVal;
	}
	get disabled() {
		return this._disabled;
	}
	@Input() autocomplete: string | null = null;
	@Input() hasError = false;
	@Input() initialValue = false;
	@Input({ required: true }) inputId = '';
	@Input() isValid = false;
	@Input() isOptional = false;
	@Input() name = '';
	@Input() readonly = false;
	@Input() showAsToggle = false;
	@Input() customFormControl = new FormControl();

	@Output() emittedValue = new EventEmitter<boolean>();

	@ViewChildren('checkboxInput') checkboxInputs!: ElementRef[];

	private destroyed$ = new ReplaySubject<boolean>(1);

	ngOnInit(): void {
		this.customFormControl.setValue(this.initialValue);

		this.customFormControl.valueChanges
			.pipe(debounceTime(100), takeUntil(this.destroyed$))
			.subscribe((value) => {
				this.emittedValue.emit(value);
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['initialValue']) {
			this.customFormControl.setValue(
				changes['initialValue'].currentValue,
			);
		}

		// NOTE: Reset the nativeElement when the firstChange is false and the currentValue is false
		if (
			changes['isValid'] &&
			!changes['isValid'].firstChange &&
			!changes['isValid'].currentValue
		) {
			this.checkboxInputs.forEach((input) => {
				input.nativeElement.checked = false;
			});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
