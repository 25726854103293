import { isPlatformBrowser, NgClass, NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	Inject,
	Input,
	input,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';

import {
	SMALL_DESKTOP,
	TABLET,
} from '@valk-nx/services/viewport/src/lib/determine-viewports';

import { ItemSlideComponent } from './item-slide/item-slide';
import { ItemSlide } from './items-slider.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		AngularSvgIconModule,
		ItemSlideComponent,
		NgClass,
		NgTemplateOutlet,
	],
	selector: 'vp-items-slider',
	templateUrl: './items-slider.html',
	styleUrls: ['./items-slider.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ItemsSliderComponent implements AfterViewInit {
	@Input() config: Partial<SwiperOptions> = {};
	@ViewChild('swiper') swiperRef: ElementRef | undefined;

	uid = input<string>('' + Math.floor(Math.random() * 1000).toString());
	items = input<ItemSlide[]>([]);

	swiper: SwiperContainer | null = null;
	swiperConfig: SwiperOptions = {
		pagination: {
			el: '.pagination',
			clickable: true,
			bulletClass: 'size-1.5 bg-ui-175',
			bulletActiveClass: '!size-2 !bg-primary-100',
			lockClass: 'hidden',
			renderBullet: /* istanbul ignore next */ function (
				_index,
				className,
			) {
				return `<div class="${className} rounded-full cursor-pointer"></div>`;
			},
		},
		navigation: {
			nextEl: '.slide-next',
			prevEl: '.slide-prev',
		},
		grabCursor: true,
		lazyPreloadPrevNext: 1,
		centerInsufficientSlides: true,
		spaceBetween: 8,
		slidesPerView: 1.4,
		breakpoints: {
			[TABLET]: {
				slidesPerView: 2.4,
			},
			[SMALL_DESKTOP]: {
				slidesPerView: 3.4,
			},
		},
	};

	constructor(@Inject(PLATFORM_ID) readonly platformId: string) {
		register();
	}

	ngAfterViewInit() {
		this.initializeSwiper();
	}

	initializeSwiper() {
		this.swiper = this.swiperRef?.nativeElement;

		this.swiperConfig = {
			...this.swiperConfig,
			// If being set in the first definition uid is not defined
			a11y: {
				enabled: true,
				id: 'items-slider-' + this.uid(),
			},
			...this.config,
			keyboard: { enabled: true, onlyInViewport: true },
		};

		// istanbul ignore next
		if (this.swiper && isPlatformBrowser(this.platformId)) {
			Object.assign(this.swiper, this.swiperConfig);
			this.swiper?.classList.add('slider');
			this.swiper.initialize();
		}
	}
}
