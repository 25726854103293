import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-checkbox',
	template: `
		<vp-checkbox
			[customFormControl]="formControl"
			[disabled]="to.disabled"
			[hasError]="showError"
			[initialValue]="formControl.value"
			[inputId]="field.id"
			[isOptional]="to['optional']"
			[isValid]="formControl.valid"
			[name]="field.key"
			[readonly]="to.readonly"
			[showAsToggle]="to['showAsToggle']"
			ngDefaultControl
		>
			{{ to['checkboxLabel'] | translate }}
		</vp-checkbox>
	`,
})
export class CheckboxComponent extends FieldType<FieldTypeConfig> {}
