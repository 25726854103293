import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';

import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

import { CtaCardComponent } from './cta-card';

@NgModule({
	declarations: [CtaCardComponent],
	exports: [CtaCardComponent],
	imports: [
		CommonModule,
		LetDirective,
		PricingComponent,
		MarkdownModule.forRoot({}),
		TranslateModule.forChild({}),
	],
	providers: [ViewPortService],

	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CtaCardModule {}
