import { Instance } from 'flatpickr/dist/types/instance';

export function addOptionalPricing(
	instance: Instance,
	dayElement: HTMLElement,
	price: string,
): void {
	const { classList } = instance.calendarContainer;

	if (!classList.contains('flatpickr-calendar--prices')) {
		classList.add('flatpickr-calendar--prices');
	}

	// Add wrapper around day__date
	const dateNode = document.createElement('div');
	dateNode.setAttribute('class', 'flatpickr-day__date');
	dateNode.textContent = dayElement.textContent;
	dayElement.textContent = '';
	dayElement.appendChild(dateNode);

	// Add price to dayElement
	const priceNode = document.createElement('div');
	priceNode.setAttribute('class', 'flatpickr-day__price');
	priceNode.textContent = price;
	dayElement.appendChild(priceNode);
}
