<div class="relative mb-3" data-test-class="items-slider">
	<ng-container [ngTemplateOutlet]="sliderNav" />
	<div [id]="'items-slider-' + uid()">
		<swiper-container
			#swiper
			class="relative flex flex-row lg:px-8"
			init="false"
		>
			@for (item of items(); track item; let i = $index) {
				<swiper-slide lazy="true">
					<vp-item-slide [item]="item" />
				</swiper-slide>
			}
		</swiper-container>
	</div>
	<ng-container
		[ngTemplateOutlet]="sliderNav"
		[ngTemplateOutletContext]="{ isRight: true }"
	/>
	<div
		class="pagination absolute -bottom-6 left-1/2 flex -translate-x-1/2 flex-row items-center gap-2 justify-self-center"
		data-test-id="pagination"
	></div>
</div>
<ng-template #sliderNav let-isRight="isRight">
	<button
		[attr.aria-label]="isRight ? 'Next slide' : 'Previous slide'"
		[attr.data-test-id]="isRight ? 'slide-next' : 'slide-prev'"
		[ngClass]="{
			'lg:flex': items().length > 3,
			'slide-prev left-1.5': !isRight,
			'slide-next right-1.5': isRight,
		}"
		class="absolute top-1/2 z-10 hidden size-12 -translate-y-1/2 items-center justify-center bg-primary-100 hover:bg-primary-125 active:bg-primary-150 disabled:cursor-not-allowed disabled:bg-primary-100 disabled:opacity-50"
		role="button"
		type="button"
	>
		<svg-icon
			[src]="
				isRight
					? 'assets/icons/icon-chevron-right.svg'
					: 'assets/icons/icon-chevron-left.svg'
			"
			class="block size-5 text-white"
		/>
	</button>
</ng-template>
