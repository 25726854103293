@if (useCarousel) {
	<vp-carousel-modal
		[images]="images"
		class="relative block"
		data-test-id="image-carousel-button"
	>
		<ng-container [ngTemplateOutlet]="grid" />
		<div
			class="absolute bottom-0 right-0 rounded-br-sm rounded-tl-2xl bg-[#000]/50 pb-2 pl-3 pr-2 pt-3"
		>
			<svg-icon
				class="block size-4 text-white"
				src="assets/icons/icon-looking-glass.svg"
				svgAriaLabel="Open"
			/>
		</div>
	</vp-carousel-modal>
}
@if (!useCarousel) {
	<ng-container [ngTemplateOutlet]="grid" />
}

<ng-template #grid>
	<div class="relative block">
		<div
			[class]="aspectRatio"
			class="grid grid-cols-2 grid-rows-2 gap-1.5 overflow-hidden rounded-sm"
		>
			@for (
				photo of images | slice: 0 : 3;
				track photo;
				let first = $first
			) {
				<div
					[ngClass]="{
						'col-span-full': images.length < 3 || first,
						'row-span-full': images.length === 1,
						'md:row-span-full':
							size !== 'small' && (images.length === 2 || first),
						'md:col-auto': images.length > 1 && size !== 'small',
					}"
				>
					<vp-image
						[className]="'h-full w-full object-cover'"
						[image]="{
							src: photo.src,
							width: 500,
							height: 500,
						}"
					/>
				</div>
			}
		</div>

		@if (sticker?.src) {
			<div
				[ngClass]="{
					'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform':
						images.length > 1,
					'bottom-4 right-4': images.length <= 1,
				}"
				class="absolute size-20"
			>
				<vp-image
					[className]="'h-full w-full object-cover'"
					[image]="{
						src: sticker.src,
						width: 80,
						height: 80,
					}"
				/>
			</div>
		}
	</div>
</ng-template>
