<div
	[editableInStoryblok]="_editable"
	class="flex flex-col items-center gap-4 text-center md:gap-6"
>
	@if (topFigure) {
		<svg-icon
			[src]="topFigure"
			class="icon-container icon-container-xl icon-container-xl-full icon-container-accent shrink-0"
		/>
	}
	<sb-heading-group
		[headingType]="headingType"
		[overline]="overline"
		[title]="title"
		alignment="center"
		size="lg"
	/>
	@if (content) {
		<markdown [data]="content" class="text-base md:text-lg" />
	}
	@if (bottomFigure) {
		<svg-icon
			[src]="bottomFigure"
			class="icon-container icon-container-xl icon-container-xl-full icon-container-accent shrink-0"
		/>
	}
</div>
