import flattenColorPalette from 'tailwindcss/lib/util/flattenColorPalette';
import plugin from 'tailwindcss/plugin';

const textBorder = plugin(function ({ addDefaults, matchUtilities, theme }) {
	addDefaults('text-border', {
		'--vp-text-border-x': '1px',
		'--vp-text-border-y': '1px',
		'--vp-text-border-blur': '4px',
		'--vp-text-border-color': theme('colors.black'),
	});

	matchUtilities(
		{
			'text-border': (value) => ({
				[`--vp-text-border-color`]: value,
				'text-shadow': `var(--vp-text-border-x) var(--vp-text-border-y) var(--vp-text-border-blur) var(--vp-text-border-color)`,
			}),
		},
		{
			values: flattenColorPalette({
				...theme('colors'),
				...theme('textColor'),
			}),
			type: ['color', 'any'],
		},
	);
});

export default textBorder;
