import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WhatsappPipe } from './whatsapp.pipe';

@NgModule({
	declarations: [WhatsappPipe],
	exports: [WhatsappPipe],
	imports: [CommonModule],
})
export class WhatsappModule {}
