import { InjectionToken } from '@angular/core';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { RouterStateUrlInterface } from '@valk-nx/router-store/router.interface';

export interface StateInterface {
	routerState: RouterReducerState<RouterStateUrlInterface>;
}

export const rootReducer: ActionReducerMap<StateInterface> = {
	routerState: routerReducer,
};

/* istanbul ignore next */
export function getRootReducer(): ActionReducerMap<StateInterface> {
	return rootReducer;
}

export const reducerToken = new InjectionToken<
	ActionReducerMap<StateInterface>
>('rootReducer');

export const ReducerProvider = {
	provide: reducerToken,
	useFactory: getRootReducer,
};
