import plugin from 'tailwindcss/plugin';

/**
 * This plugin renders different form options to use in your project
 * The base exists of just the `form-field` class.
 */

const form = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const baseYGap = `${theme('spacing')['3']}`;
		const baseXGap = `${theme('spacing')['3']}`;

		addComponents({
			[`.${e('form-group')}`]: {
				gap: `var(--form-field-horizontal-gap, ${baseXGap})`,
			},
		});
		addComponents({
			[`.${e('form-group-field-spaced')}`]: {
				marginLeft: `var(--form-field-horizontal-gap, ${baseXGap})`,
			},
		});

		addComponents({
			[`.${e('form-field-spaced')}`]: {
				marginBottom: `var(--form-field-vertical-gap, ${baseYGap})`,
			},
		});

		addComponents({
			[`.${e('form-field-divider')}`]: {
				marginTop: `var(--form-field-divider-spacing-y, ${theme('spacing')['6']})`,
				marginBottom: `var(--form-field-divider-spacing-y, ${theme('spacing')['6']})`,
			},
		});
	};
});

export default form;
