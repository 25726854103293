import { FormControl, FormGroup } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';

export const orderFormGroup = new FormGroup({
	firstName: new FormControl(),
	insertion: new FormControl(),
	lastName: new FormControl(),
	emailAddress: new FormControl(),
	phoneNumber: new FormControl(),
	companyName: new FormControl(),
	request: new FormControl(),
});

export const form: FormlyFieldConfig[] = [
	{
		props: {
			label: 'storyblok.giftcard.business-order-request-title',
		},
		type: 'heading',
	},
	{
		id: 'companyName',
		key: 'companyName',
		props: {
			label: 'global.company-name.label',
			required: true,
			translate: true,
			validation: {
				messages: {
					required: 'global.company-name.required-text',
				},
			},
		},
		type: 'text',
	},
	{
		id: 'firstName',
		key: 'firstName',
		props: {
			label: 'global.firstname.label',
			required: true,
			translate: true,
			validation: {
				messages: {
					required: 'global.firstname.required-text',
				},
			},
		},
		type: 'text',
	},
	{
		fieldGroupClassName: 'grid grid-cols-2 gap-x-4 lg:gap-x-6 2xl:gap-x-8',
		fieldGroup: [
			{
				id: 'insertion',
				key: 'insertion',
				props: {
					label: 'global.insertion.label',
					labelExtras: {
						requiredHelperText: 'global.optional',
					},
					required: false,
					translate: true,
					optional: true,
				},
				type: 'text',
			},
		],
	},
	{
		id: 'lastName',
		key: 'lastName',
		props: {
			label: 'global.lastname.label',
			required: true,
			translate: true,
			validation: {
				messages: {
					required: 'global.lastname.required-text',
				},
			},
		},
		type: 'text',
	},
	{
		id: 'emailAddress',
		key: 'emailAddress',
		props: {
			label: 'global.email.label',
			required: true,
			translate: true,
			validation: {
				messages: {
					required: 'global.email.required-text',
				},
			},
		},
		type: 'email',
	},
	{
		id: 'phoneNumber',
		key: 'phoneNumber',
		props: {
			label: 'global.phonenumber.label',
			required: true,
			translate: true,
			validation: {
				messages: {
					required: 'global.phonenumber.required-text',
				},
			},
		},
		type: 'tel',
		wrappers: ['label'],
	},
	{
		id: 'request',
		key: 'request',
		props: {
			label: 'storyblok.giftcard.business-order-request-message',
			labelExtras: {
				helperText: 'storyblok.giftcard.business-order-request-helper',
			},
			required: true,
			translate: true,
			rows: 5,
		},
		type: 'textarea',
	},
];
