<vp-availability-widget-input
	*ngrxLet="isSmallTablet$ as isSmallTablet"
	[className]="className"
	[header]="'global.period-of-stay' | translate"
	[isOpen]="_isOpen"
	[label]="'global.period-of-stay' | translate"
	[placeholder]="'global.choose-dates' | translate"
	[value]="prettyCombinedDates$ | async"
	(emitIsOpen)="popoverStateChanged($event)"
	iconName="icon-calendar"
	inputName="availability-date-range"
>
	<div
		[class.datepicker--hidden]="!_isOpen"
		id="popover-input-date-range-picker"
	>
		<vp-calendar
			[calendarData]="filteredCalendarData$ | async"
			[config]="config$ | async"
			[isLoading]="isLoading"
			[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
			(datePickerEmitter)="onDatePickerEmitter($event)"
			(emitHasError)="onEmitHasError($event)"
			(emitMonthChange)="onEmitMonthChange($event)"
		/>
	</div>
</vp-availability-widget-input>
