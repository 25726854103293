import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-textarea',
	template: `
		<vp-textarea
			[autocomplete]="to['autocomplete']"
			[formControl]="formControl"
			[hasError]="showError"
			[initialValue]="formControl.value"
			[isOptional]="to['optional']"
			[isValid]="formControl.valid"
			[name]="field.id"
			[placeholder]="to.placeholder"
			[readonly]="to.readonly"
			[rows]="to['rows']"
			[textareaId]="field.id"
			ngDefaultControl
		/>
	`,
})
export class TextareaComponent extends FieldType<FieldTypeConfig> {}
