import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Observable } from 'rxjs';

import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { DropdownOption } from '@valk-nx/components/ui-dropdown/src/lib/dropdown.interface';
import { OccupancyRestrictionsInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';
import { OccupancyInterface } from '@valk-nx/helpers/lib/interfaces/occupancy.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

import { AvailabilityWidgetInputComponent } from '../availability-input/availability-input';

import {
	OccupancyRange,
	OccupancyTypes,
} from './availability-input-occupancy.types';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-availability-widget-input-occupancy`,
	standalone: true,
	templateUrl: './availability-input-occupancy.html',
	imports: [
		AngularSvgIconModule,
		AvailabilityWidgetInputComponent,
		CommonModule,
		DropdownComponent,
		LetDirective,
		TranslateModule,
	],
})
export class AvailabilityWidgetInputOccupancyComponent implements OnInit {
	@Input() className = '';
	@Input() maxRooms = 5;
	@Input() defaultAdults = 2;
	@Input() initialOccupancy: OccupancyInterface[] = [
		{ adults: 2, children: 0, infants: 0 },
	];
	@Input() excludeFromClosingRegex: RegExp | string = /ng-option/;

	@Input()
	set isOpen(value: boolean) {
		this._isOpen = value;
	}

	@Input()
	set occupancyRestrictions(
		occupancyRestrictions: OccupancyRestrictionsInterface,
	) {
		this.selectableData = {
			adults: this.generateOccupancyList(occupancyRestrictions.adults),
			children: this.generateOccupancyList(
				occupancyRestrictions.children,
			),
			infants: this.generateOccupancyList(occupancyRestrictions.infants),
		};
		this._occupancyRestrictions = occupancyRestrictions;
	}
	get occupancyRestrictions(): OccupancyRestrictionsInterface {
		return this._occupancyRestrictions;
	}

	@Output() occupancyChanged = new EventEmitter<OccupancyInterface[]>();
	@Output() emitChooseDates = new EventEmitter<Event>();
	@Output() emitIsPopoverOpen = new EventEmitter<boolean>();

	private _occupancyRestrictions: OccupancyRestrictionsInterface = {
		adults: {
			min: 1,
			max: 2,
		},
		children: {
			min: 0,
			max: 2,
		},
		infants: {
			min: 0,
			max: 2,
		},
		maxPersons: 2,
	};

	isSmallTablet$: Observable<boolean>;
	occupancy: OccupancyInterface[] = [];
	_isOpen = false;

	selectableData: Record<OccupancyTypes, DropdownOption[]> = {
		adults: [],
		children: [],
		infants: [],
	};

	constructor(
		private readonly translate: TranslateService,
		private readonly viewport: ViewPortService,
	) {
		this.isSmallTablet$ = this.viewport.isSmallTablet$;
	}

	ngOnInit(): void {
		if (
			this.initialOccupancy &&
			this.initialOccupancy.length <= this.maxRooms
		) {
			const { adults, children, infants } = this._occupancyRestrictions;
			this.occupancy = this.initialOccupancy.map((occupancy) => {
				return {
					adults: this.limitOccupancy(
						occupancy.adults,
						adults,
						this.defaultAdults,
					),
					children: this.limitOccupancy(
						occupancy.children,
						children,
						children.min,
					),
					infants: this.limitOccupancy(
						occupancy.infants,
						infants,
						infants.min,
					),
				};
			});
		} else {
			this.occupancy = [this.createRoomOccupancy()];
		}
		this.occupancyChanged.emit(this.occupancy);
	}

	popoverStateChanged(isOpen: boolean) {
		this._isOpen = isOpen;

		this.emitIsPopoverOpen.emit(this._isOpen);
	}

	selectDropdownValue(value: string, type: OccupancyTypes, index: number) {
		this.occupancy[index][type] = +value;
		this.occupancyChanged.emit(this.occupancy);
	}

	onRemoveRoom(event: Event, index: number) {
		this.occupancy = [
			...this.occupancy.slice(0, index),
			...this.occupancy.slice(index + 1),
		];
		this.occupancyChanged.emit(this.occupancy);
		event.stopPropagation();
	}

	onAddRoom(event: Event) {
		this.occupancy.push(this.createRoomOccupancy());
		this.occupancyChanged.emit(this.occupancy);
		event.stopPropagation();
	}

	generateOccupancyList(range: OccupancyRange): DropdownOption[] {
		return Array.from({ length: range.max - range.min + 1 }, (_, index) => {
			const value = (range.min + index).toString();
			return {
				value,
				label: value,
				selectLabel: value,
			};
		});
	}

	get roomLabel(): string {
		const translationKey =
			this.occupancy.length === 1 ? 'global.room' : 'global.rooms';

		return `${this.occupancy.length} ${this.translate.instant(
			translationKey,
		)}`;
	}

	get personLabel(): string {
		const sum = (totalSoFar: number, value: number) => totalSoFar + value;

		const amountOfPersons = this.occupancy
			.map((room) => Object.values(room).reduce(sum, 0))
			.reduce(sum, 0);

		const translationKey =
			amountOfPersons === 1 ? 'global.person' : 'global.persons';

		return `${amountOfPersons} ${this.translate.instant(translationKey)}`;
	}

	private limitOccupancy(
		numOccupants: number,
		occupancyRange: OccupancyRange,
		defaultValue: number,
	) {
		return numOccupants >= occupancyRange.min &&
			numOccupants <= occupancyRange.max
			? numOccupants
			: defaultValue;
	}

	private createRoomOccupancy(): OccupancyInterface {
		const {
			defaultAdults,
			occupancyRestrictions: { adults, children, infants },
		} = this;

		const limit = (value: number, min: number, max: number): number =>
			Math.min(Math.max(value, min), max);
		return {
			adults: limit(defaultAdults, adults.min, adults.max),
			children: children.min,
			infants: infants.min,
		};
	}
}
