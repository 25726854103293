import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	Output,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { LazyImageLoadingModule } from '@valk-nx/core/lib/directives/lazy-image/lazy-image.module';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { ImageQueryModule } from '@valk-nx/core/lib/pipes/image-query/image-query.module';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	selector: 'vp-carousel-modal',
	styleUrls: ['./carousel-modal.scss'],
	templateUrl: './carousel-modal.html',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		AngularSvgIconModule,
		CommonModule,
		ImageQueryModule,
		LazyImageLoadingModule,
	],
})
export class CarouselModalComponent implements AfterViewInit {
	@Input() config: Partial<SwiperOptions> = {};
	@Input() images: PhotoInterface[] = [];
	@Input() showCarousel = false;
	@Input() className = '';

	@Output() carouselEmitter = new EventEmitter<{
		index: number | undefined;
		isOpen: boolean;
		function: string;
	}>();

	@ViewChild('swiper') swiperRef: ElementRef | undefined;

	swiper: SwiperContainer | null = null;
	swiperConfig: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 50,
	};

	scrollPosition = 0;

	imagesLoaded: boolean[] = [];
	isCustomImages: boolean[] = [];

	constructor(@Inject(PLATFORM_ID) readonly platformId: string) {
		register();
	}

	ngAfterViewInit() {
		this.imagesLoaded = new Array(this.images.length).fill(false);
		this.isCustomImages = new Array(this.images.length).fill(true);

		this.swiperConfig = {
			...this.swiperConfig,
			...this.config,
			keyboard: { enabled: true, onlyInViewport: true },
			loop: this.images.length > 1,
			navigation: false,
		};

		this.swiper = this.swiperRef?.nativeElement;

		if (this.swiper && isPlatformBrowser(this.platformId)) {
			Object.assign(this.swiper, this.swiperConfig);
			this.swiper.initialize();
		}
	}

	onImageLoad(index: number) {
		this.imagesLoaded[index] = true;
	}

	slideNext() {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const swiperEl = this.swiper!.swiper;
		swiperEl.slideNext(this.swiperConfig.speed);
		this.carouselEmitter.emit({
			index: swiperEl.activeIndex,
			isOpen: this.showCarousel,
			function: 'slideNext',
		});
	}

	slidePrev() {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const swiperEl = this.swiper!.swiper;
		swiperEl.slidePrev(this.swiperConfig.speed);
		this.carouselEmitter.emit({
			index: swiperEl.activeIndex,
			isOpen: this.showCarousel,
			function: 'slidePrev',
		});
	}

	@HostListener('window:keydown.escape', ['$event'])
	toggleCarousel(event?: MouseEvent | Event): void {
		const target = event?.target as unknown as {
			getAttribute: (attr: string) => boolean;
		};
		const keyBoardEvent = event as KeyboardEvent;

		if (
			!event ||
			target.getAttribute('data-close-carousel') ||
			(keyBoardEvent?.key === 'Escape' && this.showCarousel)
		) {
			this.showCarousel = !this.showCarousel;

			if (this.showCarousel) {
				this.scrollPosition = window.scrollY;
			}
			GeneralHelper.bodyLock(this.showCarousel, this.scrollPosition);

			this.carouselEmitter.emit({
				index: this.swiper?.swiper.activeIndex || 0,
				isOpen: this.showCarousel,
				function: 'toggleCarousel',
			});
		}
	}

	setImageFallback(index: number) {
		this.isCustomImages[index] = false;
		this.images[index] = {
			...this.images[index],
			src: '/assets/images/application-icons/icon-transparent-96x96.png',
		};
	}
}
