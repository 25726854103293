import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-deal-card`,
	templateUrl: './deal-card.component.html',
})
export class DealCardComponent {
	@Input() ribbonText: string;
	@Input() overline: string;
	@Input() title: string;
	@Input() headingType: 'h2' | 'h3' | 'h4' = 'h3';
	@Input() content: string;
	@Input() price: number;
	@Input() tags: string[] | undefined;
	@Input() images: PhotoInterface[];
	@Input() sticker: PhotoInterface | undefined;
	@Input() dealUrl: { href: string; target?: string } = { href: '' };
	@Input() currency = 'EUR';

	@Input() size: 'small' | 'base' | 'large';

	get containerClass(): string {
		if (this.size === 'large') {
			return 'w-3/12 flex-col items-end justify-end';
		} else if (this.size === 'base') {
			return 'w-full flex-row';
		} else {
			return 'flex-col items-start justify-start';
		}
	}

	isMobile$: Observable<boolean>;

	constructor(private readonly viewport: ViewPortService) {
		this.isMobile$ = this.viewport.isMobile$;
	}
}
