import { AbstractControl } from '@angular/forms';

export function phoneValidator(
	control: Pick<AbstractControl<string>, 'value'>,
) {
	// Validators should only validate if there is a value.
	// If an empty value is not allowed, the form field should have a required validator instead.
	if (!control.value) {
		return true;
	}

	const withoutSpaces = control.value?.replace(/\s+/g, '');

	/* eslint-disable no-useless-escape */
	return /^\(?\+?[\d\(\)-]{6,20}$/gm.test(withoutSpaces);
}
