import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';

@Component({
	selector: 'formly-field-recaptcha',
	template: `
		<re-captcha
			#recaptcha
			[errorMode]="to['errorMode']"
			[siteKey]="to['siteKey']"
			[size]="to['size']"
			(error)="onError($event)"
			(resolved)="onResolve($event)"
			class="my-4 block"
			ngDefaultControl
		/>
	`,
})
export class RecaptchaV2Component extends FieldType<FieldTypeConfig> {
	constructor() {
		super();
	}

	onResolve(captchaResponse: string): void {
		this.formControl.patchValue(captchaResponse);
	}

	onError(errorDetails: RecaptchaErrorParameters): void {
		console.warn(`reCAPTCHA error: ${errorDetails}`);
	}
}
