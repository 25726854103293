export interface ImageInterface {
	src: string;
	altText?: string;
	style?: string;
	width: number;
	height: number;
}

export interface ImageSourceInterface {
	min: Sizes | null;
	max: Sizes | null;
	height: number;
	width: number;
}

export enum Sizes {
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
	xxl = '2xl',
}

export enum SizeValues {
	xs = 320,
	sm = 640,
	md = 768,
	lg = 1024,
	xl = 1280,
	'2xl' = 1536,
}

export type ImageAspectRatio = '1:1' | '16:9' | '4:3' | '3:2' | '21:9';
