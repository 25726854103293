<section
	[editableInStoryblok]="_editable"
	class="section-spacing"
	data-test-class="masonry-section"
>
	<div class="section-gap mx-auto max-w-screen-2xl">
		<sb-columns [colOne]="titleWithFigures" component="columnOne" />
		<vp-masonry [images]="vpImages" />
	</div>
</section>
