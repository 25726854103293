<div
	[ngClass]="{
		'bg-accent-100 text-white': variant === 'accent',
		'bg-success-75 text-dark-primary': variant === 'success',
		'bg-error-75 text-dark-primary': variant === 'error',
		'bg-select-75 text-dark-primary': variant === 'info',
		'bg-warning-75 text-dark-primary': variant === 'warning',
	}"
	class="relative z-50 p-2 text-center"
	data-test-id="top-banner"
	id="top-banner"
>
	<ng-content />
</div>
