<div
	[editableInStoryblok]="_editable"
	class="card card-lg"
	data-test-id="activate-giftcard"
>
	@if (giftcardSubmitted && activationSuccess) {
		<vp-alert
			[content]="
				'storyblok.giftcard.activation-success-title' | translate
			"
			[emphasized]="true"
			data-test-id="alert"
			type="dialog--success"
		/>
	}
	@if (giftcardSubmitted && !activationSuccess) {
		<vp-alert
			[content]="'storyblok.giftcard.activation-warning-text' | translate"
			[emphasized]="true"
			[title]="'storyblok.giftcard.activation-warning-title' | translate"
			data-test-id="alert"
			type="dialog--warning"
		/>
	}
	<form
		#activateForm="ngForm"
		[formGroup]="form"
		[ngClass]="{
			hidden: giftcardSubmitted
		}"
		(ngSubmit)="form.valid && onSubmit($any(form.value))"
	>
		<formly-form [fields]="formFields" [form]="form" />
		<button
			[ngClass]="{
				'btn-is-loading': giftcardSubmitting
			}"
			class="btn btn-primary mt-6 w-full md:mt-8"
			data-test-id="activate-button"
			type="submit"
		>
			{{ 'storyblok.giftcard.activate' | translate }}
		</button>
		<div class="mt-3 md:mt-4">
			<vp-recaptcha-notification [currentLanguage]="language" />
		</div>
	</form>
	@if (showSummarySuccess) {
		<div class="mt-4 md:mt-6">
			<span class="block font-bold" data-test-id="card-number-label">
				{{ 'storyblok.giftcard.activated-card' | translate }}
			</span>
			<span class="block" data-test-id="card-number">
				{{ form.value['cardNumber'] }}
			</span>
			<button
				(click)="resetGiftcardForm()"
				class="text-link mt-4 md:mt-6"
				data-test-id="summary-action"
			>
				{{ 'storyblok.giftcard.activate-new-card' | translate }}
			</button>
		</div>
	}
	@if (showSummaryWarning) {
		<div class="mt-4 md:mt-6">
			<span class="block font-bold" data-test-id="card-number-label">
				{{ 'storyblok.giftcard.card-number.label' | translate }}
			</span>
			<span class="block" data-test-id="card-number">
				{{ form.value['cardNumber'] }}
			</span>
			<span class="block font-bold" data-test-id="activation-code-label">
				{{ 'storyblok.giftcard.activation-code.label' | translate }}
			</span>
			<span class="block" data-test-id="activation-code">
				{{ form.value['activationCode'] }}
			</span>
			<button
				(click)="changeGiftCardForm()"
				class="text-link mt-4 md:mt-6"
				data-test-id="summary-action"
			>
				{{ 'storyblok.giftcard.change-data' | translate }}
			</button>
		</div>
	}
</div>
