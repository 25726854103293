<div data-test-id="package-overview">
	@for (blok of content; track blok) {
		<div>
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		</div>
	}
</div>
<section class="section-spacing bg-accent-25">
	<div
		class="section-gap mx-auto max-w-screen-2xl children:w-full children:md:mx-auto children:lg:w-10/12"
	>
		<h3 class="running-head" data-test-class="running-head">
			{{ 'global.packages-label' | translate }}
		</h3>
		@if (packages$ | async; as packages) {
			@for (package of packages; track $index) {
				<vp-persuade-card
					[cardUrl]="currentUrl + '/' + package.slug"
					[content]="package.bulletContent"
					[ctaText]="'storyblok.pages.package-card.view' | translate"
					[ctaUrl]="currentUrl + '/' + package.slug"
					[currency]="package.price.currency"
					[facilities]="package.facilities"
					[images]="package.images"
					[price]="package.price.amount"
					[title]="package.name"
					imageVariant="mini-carousel"
				/>
			}
		}
	</div>
</section>
