import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SbRootComponent } from '@valk-nx/storyblok/components/root/src/lib/root.component';

@NgModule({
	imports: [
		RouterModule.forRoot(
			[
				{
					path: '**',
					component: SbRootComponent,
				},
			],
			{
				enableTracing: false,
				onSameUrlNavigation: 'reload',
				scrollPositionRestoration: 'enabled',
			},
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
