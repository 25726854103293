import { DropdownOption } from './dropdown.interface';

const equalDropDownOption = (a: DropdownOption, b: DropdownOption) =>
	a.label === b.label && a.value === b.value && a.data?.icon === b.data?.icon;

const equalDropDownOptions = (a: DropdownOption[], b: DropdownOption[]) =>
	a?.length === b?.length &&
	a.every((_, index) => equalDropDownOption(a[index], b[index]));

export { equalDropDownOption, equalDropDownOptions };
