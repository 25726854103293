import { BehaviorSubject } from 'rxjs';

import { AlertPosition } from '@valk-nx/components/ui-alert/src/lib/contracts/alert.types';
import { ContentHotelInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';

export const alertErrorSettings = {
	closeable: true,
	duration: 5000,
	emphasized: false,
	hasElevation: true,
	position: AlertPosition.TopCenter,
};

export const hotelInfoSubject: BehaviorSubject<ContentHotelInterface> =
	new BehaviorSubject<ContentHotelInterface>({
		GUID: '',
		address: {
			city: '',
			street: '',
			streetNumber: '',
			streetNumberAddition: '',
			postalCode: '',
			gps: '',
		},
		contacts: [],
		currency: '',
		name: '',
		slug: '',
	});
