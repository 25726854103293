import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-segmented-control',
	template: `
		<vp-segmented-control
			[forceHorizontal]="to['forceHorizontal']"
			[hasError]="showError"
			[id]="field.id"
			[isValid]="formControl.valid"
			[segmentedOptions]="to['segmentedOptions']"
			[value]="formControl.value"
			(selectedLabel)="updateValue($event)"
			ngDefaultControl
		/>
	`,
})
export class SegmentedControlComponent extends FieldType<FieldTypeConfig> {
	updateValue(value: string): void {
		this.formControl.patchValue(value);
	}
}
