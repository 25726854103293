var _excluded = ["tags"];
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
import { createStackTraces, filterInvalidFrames } from './stack-trace';
import { generateRandomId, merge, extend } from '../common/utils';
import { getPageContext } from '../common/context';
import { truncateModel, ERROR_MODEL } from '../common/truncate';
import stackParser from 'error-stack-parser';
var IGNORE_KEYS = ['stack', 'message'];
var PROMISE_REJECTION_PREFIX = 'Unhandled promise rejection: ';
function getErrorProperties(error) {
  var propertyFound = false;
  var properties = {};
  Object.keys(error).forEach(function (key) {
    if (IGNORE_KEYS.indexOf(key) >= 0) {
      return;
    }
    var val = error[key];
    if (val == null || typeof val === 'function') {
      return;
    }
    if (typeof val === 'object') {
      if (typeof val.toISOString !== 'function') return;
      val = val.toISOString();
    }
    properties[key] = val;
    propertyFound = true;
  });
  if (propertyFound) {
    return properties;
  }
}
var ErrorLogging = function () {
  function ErrorLogging(apmServer, configService, transactionService) {
    this._apmServer = apmServer;
    this._configService = configService;
    this._transactionService = transactionService;
  }
  var _proto = ErrorLogging.prototype;
  _proto.createErrorDataModel = function createErrorDataModel(errorEvent) {
    var frames = createStackTraces(stackParser, errorEvent);
    var filteredFrames = filterInvalidFrames(frames);
    var culprit = '(inline script)';
    var lastFrame = filteredFrames[filteredFrames.length - 1];
    if (lastFrame && lastFrame.filename) {
      culprit = lastFrame.filename;
    }
    var message = errorEvent.message,
      error = errorEvent.error;
    var errorMessage = message;
    var errorType = '';
    var errorContext = {};
    if (error && typeof error === 'object') {
      errorMessage = errorMessage || error.message;
      errorType = error.name;
      var customProperties = getErrorProperties(error);
      if (customProperties) {
        errorContext.custom = customProperties;
      }
    }
    if (!errorType) {
      if (errorMessage && errorMessage.indexOf(':') > -1) {
        errorType = errorMessage.split(':')[0];
      }
    }
    var currentTransaction = this._transactionService.getCurrentTransaction();
    var transactionContext = currentTransaction ? currentTransaction.context : {};
    var _this$_configService$ = this._configService.get('context'),
      tags = _this$_configService$.tags,
      configContext = _objectWithoutPropertiesLoose(_this$_configService$, _excluded);
    var pageContext = getPageContext();
    var context = merge({}, pageContext, transactionContext, configContext, errorContext);
    var errorObject = {
      id: generateRandomId(),
      culprit: culprit,
      exception: {
        message: errorMessage,
        stacktrace: filteredFrames,
        type: errorType
      },
      context: context
    };
    if (currentTransaction) {
      errorObject = extend(errorObject, {
        trace_id: currentTransaction.traceId,
        parent_id: currentTransaction.id,
        transaction_id: currentTransaction.id,
        transaction: {
          type: currentTransaction.type,
          sampled: currentTransaction.sampled
        }
      });
    }
    return truncateModel(ERROR_MODEL, errorObject);
  };
  _proto.logErrorEvent = function logErrorEvent(errorEvent) {
    if (typeof errorEvent === 'undefined') {
      return;
    }
    var errorObject = this.createErrorDataModel(errorEvent);
    if (typeof errorObject.exception.message === 'undefined') {
      return;
    }
    this._apmServer.addError(errorObject);
  };
  _proto.registerListeners = function registerListeners() {
    var _this = this;
    window.addEventListener('error', function (errorEvent) {
      return _this.logErrorEvent(errorEvent);
    });
    window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
      return _this.logPromiseEvent(promiseRejectionEvent);
    });
  };
  _proto.logPromiseEvent = function logPromiseEvent(promiseRejectionEvent) {
    var reason = promiseRejectionEvent.reason;
    if (reason == null) {
      reason = '<no reason specified>';
    }
    var errorEvent;
    if (typeof reason.message === 'string') {
      var name = reason.name ? reason.name + ': ' : '';
      errorEvent = {
        error: reason,
        message: PROMISE_REJECTION_PREFIX + name + reason.message
      };
    } else {
      errorEvent = this._parseRejectReason(reason);
    }
    this.logErrorEvent(errorEvent);
  };
  _proto.logError = function logError(messageOrError) {
    var errorEvent = {};
    if (typeof messageOrError === 'string') {
      errorEvent.message = messageOrError;
    } else {
      errorEvent.error = messageOrError;
    }
    return this.logErrorEvent(errorEvent);
  };
  _proto._parseRejectReason = function _parseRejectReason(reason) {
    var errorEvent = {
      message: PROMISE_REJECTION_PREFIX
    };
    if (Array.isArray(reason)) {
      errorEvent.message += '<object>';
    } else if (typeof reason === 'object') {
      try {
        errorEvent.message += JSON.stringify(reason);
        errorEvent.error = reason;
      } catch (error) {
        errorEvent.message += '<object>';
      }
    } else if (typeof reason === 'function') {
      errorEvent.message += '<function>';
    } else {
      errorEvent.message += reason;
    }
    return errorEvent;
  };
  return ErrorLogging;
}();
export default ErrorLogging;