<ng-content />
<div
	[ngClass]="horizontal ? 'flex-col sm:flex-row' : 'flex-col'"
	class="flex gap-3 sm:gap-x-6 sm:gap-y-3"
>
	@for (option of options; track option) {
		<vp-checkbox
			[autocomplete]="autocomplete || 'off'"
			[disabled]="disabled"
			[hasError]="hasError"
			[initialValue]="option.initialValue"
			[inputId]="option.inputId"
			[isOptional]="option.isOptional"
			[isValid]="isValid"
			[name]="name"
			[readonly]="readonly"
			(emittedValue)="checkboxChangedValue(option.inputId, $event)"
		>
			{{ shouldTranslate ? (option.label | translate) : option.label }}
		</vp-checkbox>
	}
</div>
