import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { PricePipe } from '@valk-nx/core/lib/pipes/price/price.pipe';

import {
	AlertType,
	OrderStatus,
	OrderSteps,
	OrderSummary,
} from '../order-giftcard.types';

@Component({
	selector: 'sb-order-giftcard-summary',
	templateUrl: 'order-giftcard-summary.html',
})
export class OrderGiftcardSummaryComponent {
	@Input() currency = 'EUR';
	@Input() language!: string;
	@Input() orderStatus!: OrderStatus;
	@Input() orderStep = OrderSteps.Form;
	@Input({ required: true }) orderSummary!: OrderSummary;
	@Input() showAlert = false;

	readonly orderSteps = OrderSteps;
	readonly AlertType = AlertType;

	get receiptItems() {
		const { cards, packaging, administration } = this.orderSummary;
		return [
			{
				amount: cards.amount,
				name: `${this.translate.instant(
					'storyblok.giftcard.card-value-of',
				)} ${this.pricePipe.transform(cards.price, this.currency)}`,
				helperText: `${cards.taxPercentage}% ${this.translate.instant(
					'global.value-added-tax-abbr',
				)}`,
				price: cards.totalIncl,
			},
			{
				amount: packaging.amount,
				name: `${this.translate.instant(
					'storyblok.giftcard.package-at',
				)} ${this.pricePipe.transform(packaging.price, this.currency)}`,
				helperText: `${this.translate.instant(
					'global.including-abbr',
				)} ${packaging.taxPercentage}% ${this.translate.instant(
					'global.value-added-tax-abbr',
				)}`,
				price: packaging.totalIncl,
			},
			{
				name: `${this.translate.instant(
					'storyblok.giftcard.administration-shipping-costs',
				)}`,
				helperText: `${this.translate.instant(
					'global.including-abbr',
				)} ${administration.taxPercentage}% ${this.translate.instant(
					'global.value-added-tax-abbr',
				)}`,
				price: administration.totalIncl,
			},
		];
	}

	constructor(
		private readonly translate: TranslateService,
		private readonly pricePipe: PricePipe,
	) {}
}
