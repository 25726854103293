import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';

import { catchError, map, Observable, of } from 'rxjs';

import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';

import { AddressInterface, GetAddressInterface } from './postal-code.interface';

//Env variable
export const POSTALCODE_SERVICE_URL = new InjectionToken<string[]>(
	'postalCode_service-url',
);

export const POSTALCODE_API_COOKIES = new InjectionToken<string[]>(
	'postalCode_api-cookies',
);

@Injectable({ providedIn: 'root' })
export class PostalCodeService {
	constructor(
		private readonly http: HttpClient,
		@Inject(POSTALCODE_SERVICE_URL) private readonly url: string,
		@Inject(POSTALCODE_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	getAddress({
		country,
		postalCode,
		houseNumber,
		houseNumberAddition,
	}: GetAddressInterface): Observable<Partial<AddressInterface>> {
		const headers = new HttpHeaders();
		return this.http
			.get<
				HALResponse<AddressInterface>
			>(`${this.url}address/${country}/${postalCode.replace(/\s/g, '')}/${houseNumber}${houseNumberAddition ? `/${houseNumberAddition}` : ''}`, { ...this.apiCookies, headers, observe: 'response' })
			.pipe(
				map((response) => response.body?.data),
				map((data) => {
					return {
						...data,
						houseNumber: data?.houseNumber?.toString(),
					};
				}),
				catchError(() => of({})),
			);
	}
}
