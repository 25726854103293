<div class="card md:card-lg" data-test-id="booking-widget" id="widget">
	@if (isDealAvailable) {
		<div class="flex flex-col gap-4 align-bottom md:flex-row">
			<div class="md:w-1/2" data-test-id="occupancy-selector">
				<vp-occupancy-selector
					[defaultAdults]="defaultAdults"
					[initialOccupancy]="initialOccupancy"
					[isPopoverOpen]="isOccupancyOpen"
					[maxRooms]="maxRooms"
					[occupancyRestrictions]="occupancyRestrictions"
					[popoverOffset]="occupancyPopoverOffset"
					(emitChooseDates)="onChooseDatesEmit($event)"
					(emitIsPopoverOpen)="onOccupancyOpenEmit($event)"
					(occupancyChanged)="onOccupancyEmit($event)"
					boundaryTargetId="widget"
				/>
			</div>
			<div class="md:w-1/2">
				@if (calendarType === 'range') {
					<vp-date-range-picker
						[availability]="availability$ | async"
						[config]="{
							mode: 'range',
							showMonths: 2,
							minDate: minDate,
							maxDate: maxDate
						}"
						[endInputConfig]="{
							icon: '',
							name: 'booking-widget',
							placeholder: 'global.choose-date' | translate
						}"
						[endLabelSettings]="{
							text: 'global.checkout-label' | translate,
							position: labelPositions.Left
						}"
						[initialArrivalDate]="initialArrivalDate"
						[initialNumberOfNights]="initialNumberOfNights"
						[isLoading]="isLoading"
						[isPopoverOpen]="isDatePickerOpen"
						[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
						[maxNights]="maxNights"
						[minNights]="minNights"
						[popoverOffset]="datepickerPopoverOffset"
						[showButtonOnMobile]="true"
						[startInputConfig]="{
							icon: '',
							name: 'booking-widget',
							placeholder: 'global.choose-date' | translate
						}"
						[startLabelSettings]="{
							text: 'global.checkin-label' | translate,
							position: labelPositions.Left
						}"
						(datePickerModelChanged)="updateModel($event)"
						(emitIsPopoverOpen)="onDatePickerOpened($event)"
						(emitMonthChange)="onMonthChange($event)"
						(emitShowAvailabilityButton)="goToBookingtool()"
						(initialDatesNotAvailable)="
							recalculateAvailabilityForToday()
						"
						boundaryTargetId="widget"
						data-test-id="date-range-picker"
						popoverAlignment="bottom"
					/>
				}
				@if (calendarType === 'stepper') {
					<vp-date-range-picker-with-stepper
						[availability]="availability$ | async"
						[config]="{
							mode: 'range',
							showMonths: 2,
							minDate: minDate,
							maxDate: maxDate
						}"
						[currency]="currency"
						[initialArrivalDate]="initialArrivalDate"
						[initialNumberOfNights]="initialNumberOfNights"
						[inputConfig]="{
							icon: '',
							name: 'booking-widget',
							placeholder: 'global.choose-dates' | translate
						}"
						[inputLabelSettings]="{
							text: 'global.period-of-stay' | translate,
							position: labelPositions.Left
						}"
						[isLoading]="isLoading"
						[isPopoverOpen]="isDatePickerOpen"
						[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
						[maxNights]="maxNights"
						[minNights]="minNights"
						[popoverOffset]="datepickerPopoverOffset"
						[showButtonOnMobile]="true"
						[showPrices]="showPrices"
						(datePickerModelChanged)="updateModel($event)"
						(emitIsPopoverOpen)="onDatePickerOpened($event)"
						(emitMonthChange)="onMonthChange($event)"
						(emitShowAvailabilityButton)="goToBookingtool()"
						(initialDatesNotAvailable)="
							recalculateAvailabilityForToday()
						"
						(stepperUpdated)="getAvailability()"
						boundaryTargetId="widget"
						data-test-id="date-range-picker-with-stepper"
						popoverAlignment="bottom"
					/>
				}
			</div>
			<div class="flex w-full items-end md:w-auto">
				<button
					(click)="goToBookingtool()"
					class="btn btn-primary w-full md:w-auto"
					data-test-id="cta-button"
				>
					{{ 'global.show-availability' | translate }}
				</button>
			</div>
		</div>
	} @else if (!isDealAvailable && !isServer) {
		<div data-test-id="unavailable-widget">
			<h2 class="h3" data-test-id="title">
				{{
					(isPastDeal
						? 'components.booking-widget.unavailable.past.title'
						: isFutureDeal
							? 'components.booking-widget.unavailable.future.title'
							: ''
					) | translate
				}}
			</h2>
			<div
				class="flex flex-col items-end justify-between gap-4 md:flex-row md:gap-6"
			>
				<p class="m-0 text-base md:text-lg" data-test-id="content">
					{{
						(isPastDeal
							? 'components.booking-widget.unavailable.past.content'
							: isFutureDeal
								? 'components.booking-widget.unavailable.future.content'
								: ''
						) | translate
					}}
				</p>

				<a
					[href]="unavailableCtaUrl"
					class="btn btn-primary w-full md:w-auto"
					data-test-id="cta-button"
				>
					{{
						'components.booking-widget.unavailable.show-other-deals'
							| translate
					}}
				</a>
			</div>
		</div>
	}
</div>
