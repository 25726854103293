import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	ViewEncapsulation,
} from '@angular/core';

interface Ribbon {
	main: string;
	triangle: string;
	text: string;
}

export type RibbonColor = 'yellow';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-ribbon',
	standalone: true,
	templateUrl: './ribbon.html',
})
export class RibbonComponent implements OnChanges {
	@Input() text: string;
	@Input() maxLength = 10;

	ribbonText: string;
	mappedColor: Ribbon;

	ngOnChanges(): void {
		this.ribbonText = this.text.slice(0, this.maxLength);
	}
}
