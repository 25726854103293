import plugin from 'tailwindcss/plugin';

const baseScss = plugin(function ({ addBase, theme }) {
	addBase({
		body: {
			fontFamily: theme('fontFamily.sans-serif'),
			fontSize: theme('fontSize.base'),
			color: theme('textColor.dark.primary'),
		},
	});
});

export default baseScss;
