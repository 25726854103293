import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-radio-group',
	template: `
		<div [attr.data-test-id]="field.id" class="my-3">
			<vp-radio-group
				[customFormControl]="formControl"
				[disabled]="to.disabled"
				[hasError]="showError"
				[horizontal]="to['horizontal']"
				[initialValue]="formControl.value"
				[inputId]="field.id"
				[isValid]="formControl.valid"
				[name]="field.key"
				[options]="to['radioOptions']"
				[readonly]="to.readonly"
				[shouldTranslate]="to['translate']"
				ngDefaultControl
			/>
		</div>
	`,
})
export class RadioGroupComponent extends FieldType {}
