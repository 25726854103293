export type AlertType =
	| 'dialog--error'
	| 'dialog--warning'
	| 'dialog--success'
	| 'dialog--info';

export enum AlertPosition {
	TopLeft = 'alert--top-left',
	TopCenter = 'alert--top-center',
	TopRight = 'alert--top-right',
	BottomLeft = 'alert--bottom-left',
	BottomCenter = 'alert--bottom-center',
	BottomRight = 'alert--bottom-right',
}
