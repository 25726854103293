<div
	[ngClass]="{
		'line-clamp-6 md:line-clamp-4': !isOpen && !isDisabled,
	}"
>
	<ng-content />
</div>
@if (!isDisabled) {
	<button
		(click)="toggleIsOpen()"
		class="text-link mt-2"
		data-test-id="toggle-read-more"
	>
		{{ (isOpen ? 'global.read-less' : 'global.read-more') | translate }}
	</button>
}
