import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';

import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { RibbonComponent } from '@valk-nx/components/ui-ribbon/src/lib/ribbon';
import { SocialProofComponent } from '@valk-nx/components/ui-social-proof/src/lib/social-proof';
import { TagComponent } from '@valk-nx/components/ui-tag/src/lib/tag';
import { SharedModule } from '@valk-nx/core/lib/modules/shared.module';

import { HotelByDealCardComponent } from './hotel-by-deal-card.component';

@NgModule({
	declarations: [HotelByDealCardComponent],
	exports: [HotelByDealCardComponent],
	imports: [
		AngularSvgIconModule.forRoot(),
		CommonModule,
		ImageComponent,
		LetDirective,
		PricingComponent,
		RibbonComponent,
		MarkdownModule.forRoot({}),
		SharedModule,
		SocialProofComponent,
		TagComponent,
		TranslateModule.forChild({}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HotelByDealCardModule {}
