import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { MarkdownModule } from 'ngx-markdown';

import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
@Component({
	selector: 'sb-markdown',
	templateUrl: './markdown.html',
	standalone: true,
	imports: [
		CommonModule,
		MarkdownModule,
		ReadMoreComponent,
		StoryblokDirective,
	],
})
export class MarkdownComponent extends StoryblokRootDirective {
	@Input({ required: true }) content!: string;
	@Input() listStyle: 'list-checkmarks' | 'list-bullets' = 'list-bullets';
	@Input() isCentered = false;
	@Input() isReadMore = false;
}
