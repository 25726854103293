import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';
import { Observable } from 'rxjs';

import { CarouselModalComponent } from '@valk-nx/components/ui-carousel-modal/src/lib/carousel-modal';
import { ImageComponent } from '@valk-nx/components/ui-image/src/lib/image.component';
import { ImageInterface } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { MiniCarouselComponent } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { RoomFacilitiesComponent } from '@valk-nx/components/ui-room-facilities/src/lib/room-facilities.component';
import { TagComponent } from '@valk-nx/components/ui-tag/src/lib/tag';
import { ImageGridComponent } from '@valk-nx/compositions/ui-image-grid/src/lib/image-grid.component';
import { HeadingDirective } from '@valk-nx/core/lib/directives/heading/heading.directive';
import { HeadingType } from '@valk-nx/core/lib/directives/heading/heading.type';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { RoomCardFacilitiesInterface } from '@valk-nx/rooms-store/rooms.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { ngSkipHydration: 'true' },
	imports: [
		AngularSvgIconModule,
		CarouselModalComponent,
		CommonModule,
		HeadingDirective,
		ImageComponent,
		ImageGridComponent,
		LetDirective,
		MarkdownModule,
		MiniCarouselComponent,
		RoomFacilitiesComponent,
		RouterModule,
		PricingComponent,
		TagComponent,
		TranslateModule,
	],
	selector: `vp-persuade-card`,
	standalone: true,
	templateUrl: './persuade-card.component.html',
})
export class PersuadeCardComponent implements OnInit, AfterViewInit {
	@Input() images: PhotoInterface[] | undefined;
	@Input() imageVariant: 'mini-carousel' | 'single' | 'grid' = 'single';
	@Input() overline: string | undefined;
	@Input({ required: true }) title: string;
	@Input() facilities: RoomCardFacilitiesInterface | undefined;
	@Input() roomSurface: number | undefined;
	@Input() content: string | undefined;
	@Input() price: number | undefined;
	@Input() pricingCondition = 'global.price-per-night';
	@Input() tags: string[] | undefined;
	@Input() currency = 'EUR';
	@Input() ctaUrl: string | undefined;
	@Input() ctaText = 'global.book-directly';
	@Input() ctaType: 'btn-primary' | 'btn-secondary' = 'btn-primary';
	@Input() ctaVariant: '' | 'btn-outline' | 'btn-text' = '';
	@Input() cardUrl: string | undefined;
	@Input() reversedCtaColumn = false;
	@Input() useVerticalCard = false;
	@Input() showReadMore = false;
	@Input() headingType: HeadingType = 'h3';

	isMobile$: Observable<boolean>;
	isTablet$: Observable<boolean>;
	height = 0;

	carouselItems: ImageInterface[] | undefined = [];
	@ViewChild('persuadeRef') persuadeRef: ElementRef;

	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		readonly cd: ChangeDetectorRef,
		private readonly viewport: ViewPortService,
	) {
		this.isMobile$ = this.viewport.isMobile$;
		this.isTablet$ = this.viewport.isSmallTablet$;
	}

	ngOnInit() {
		this.carouselItems = this.images?.map((image) => {
			return {
				src: image.src,
				altText: image.alt,
				width: 0,
				height: 0,
			};
		});
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			setTimeout(() => {
				this.height = this.persuadeRef.nativeElement.offsetHeight;
				this.cd.detectChanges();
			});
		}
	}
}
