import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	Inject,
	Injectable,
	InjectionToken,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';

declare global {
	interface Window {
		dataLayer: unknown[];
		hasLoadedGtmTags?: boolean;
	}
}

export const GTM_CODE = new InjectionToken<string[]>('gtm-code');

@Injectable({
	providedIn: 'root',
})
export class GoogleTagManagerService {
	private readonly renderer: Renderer2;
	private readonly window: Window | null;

	constructor(
		@Inject(DOCUMENT) public document: Document,
		@Inject(PLATFORM_ID) public platformId: string,
		readonly rendererFactory: RendererFactory2,
		@Inject(GTM_CODE) public readonly gtmCode: string,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
		this.window = this.document.defaultView;
	}

	init() {
		if (!this.gtmCode) {
			return;
		}
		if (
			isPlatformBrowser(this.platformId) &&
			this.window?.hasLoadedGtmTags
		) {
			return;
		}

		this.createGoogleTagManagerScript();
		this.createGoogleTagManagerNoScript();

		//NOTE: To transfer the hasLoadedGtmTags from ssr to client
		const verificationScriptTag = this.renderer.createElement('script');
		verificationScriptTag.innerHTML = 'window.hasLoadedGtmTags = true;';
		this.renderer.appendChild(this.document.head, verificationScriptTag);
	}

	createGoogleTagManagerScript() {
		if (!this.gtmCode) {
			return;
		}
		const head: HTMLElement = this.document.head;
		const scriptElement = this.renderer.createElement('script');

		scriptElement.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${this.gtmCode}');`;

		this.renderer.insertBefore(head, scriptElement, head.firstChild);
	}

	createGoogleTagManagerNoScript() {
		if (!this.gtmCode) {
			return;
		}
		const body: HTMLElement = this.document.body;
		const noScriptElement = this.renderer.createElement('noscript');
		noScriptElement.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmCode}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
		this.renderer.insertBefore(body, noScriptElement, body.firstChild);
	}

	public push<T>(event: T): void {
		if (!this.gtmCode || !isPlatformBrowser(this.platformId)) {
			return;
		}

		this.window?.dataLayer?.push(event);
	}
}
