import { FormlyFieldConfig } from '@ngx-formly/core';

export const form: FormlyFieldConfig[] = [
	{
		id: 'cardNumber',
		key: 'cardNumber',
		props: {
			label: 'storyblok.giftcard.card-number.label',
			labelExtras: {
				helperText: 'storyblok.giftcard.card-number.helper-text',
				noPadding: true,
			},
			maskOptions: {
				prefix: '',
				mask: '0000-0000-0000-0000-000',
				showMaskedType: true,
			},
			pattern: /^\d{4}-\d{4}-\d{4}-\d{4}-\d{3}$/,
			required: true,
			translate: true,
			validation: {
				messages: {
					pattern: 'storyblok.giftcard.card-number.invalid',
				},
			},
		},
		type: 'text',
	},
	{
		id: 'pinCode',
		key: 'pinCode',
		className: 'mt-3 block',
		props: {
			label: 'storyblok.giftcard.pin-code.label',
			labelExtras: {
				inputWidth: 'w-1/2 sm:w-1/3',
				helperText: 'storyblok.giftcard.pin-code.helper-text',
				noPadding: true,
			},
			maskOptions: {
				mask: '000000',
				prefix: '',
				showMaskedType: false,
			},
			pattern: /^\d{6}$/,
			validation: {
				messages: {
					pattern: 'storyblok.giftcard.pin-code.invalid',
				},
			},
			required: true,
			translate: true,
		},
		type: 'text',
	},
];
