import { Component, OnInit } from '@angular/core';

import { FieldWrapper } from '@ngx-formly/core';

// NOTE: width calculation should be recalculated based on the margin and width of the tooltip
@Component({
	selector: 'formly-wrapper-label',
	template: `<div
		class="flex items-start odd:children:flex-1 md:w-[calc(100%+28px)]"
	>
		<ng-container #fieldComponent />
		@if (props['tooltip']) {
			<vp-tooltip
				[alignment]="props['tooltip']['alignment']"
				[ariaLabel]="props['tooltip']['ariaLabel'] | translate"
				[ngClass]="tooltipMargin"
				[tooltipColor]="props['tooltip']['color']"
				class="ml-2 inline-block"
				iconSize="w-5"
			>
				<vp-tooltip-content>
					{{ props['tooltip']['content'] | translate }}
				</vp-tooltip-content>
			</vp-tooltip>
		}
	</div>`,
})
export class TooltipWrapperComponent extends FieldWrapper implements OnInit {
	tooltipMargin = '';

	ngOnInit(): void {
		this.setTooltipMargin(this.props);
	}

	setTooltipMargin(props): void {
		if (props['label']) {
			switch (props['labelExtras']['alignment']) {
				case 'left-aligned':
				case 'top-left-aligned':
					this.tooltipMargin = 'mt-10 md:mt-3';
					break;
				case 'top-aligned-stacked':
				case 'top-aligned-inline':
					this.tooltipMargin = 'mt-10 md:mt-11';
					break;
				default:
					break;
			}
		}
	}
}
