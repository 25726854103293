import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-divider',
	standalone: true,
	imports: [CommonModule],
	template: `<hr
		[ngClass]="{
			'md:-mx-8 lg:-mx-10 2xl:-mx-12': field.props['withNegativeSpacing']
		}"
		class="divider my-6"
	/>`,
})
export class DividerComponent extends FieldType<FieldTypeConfig> {}
