import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'vp-segmented-control',
	standalone: true,
	imports: [NgClass, TranslateModule],
	templateUrl: './segmented-control.component.html',
})
export class SegmentedControlComponent extends BaseDirective {
	@Input() segmentedOptions: { value: string; name: string }[] = [];
	@Input() hasError = false;
	@Input() isValid = false;
	@Input() disabled = false;
	/* @deprecated No support for horizontal view */
	@Input() forceHorizontal = false;
	@Input() value = '';
	@Input() id = '';
	@Output() selectedLabel = new EventEmitter<string>();

	constructor(public override translate: TranslateService) {
		super(translate);
	}

	selectLabel(value: string) {
		this.selectedLabel.emit(value);
		this.value = value;
	}
}
