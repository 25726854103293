import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

import { PriceComponent } from './components/price/price';

@Component({
	host: { ngSkipHydration: 'true' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	selector: 'vp-pricing',
	templateUrl: './pricing.html',
	imports: [CommonModule, PriceComponent, TranslateModule],
})
export class PricingComponent extends BaseDirective {
	@Input({ required: true }) price: number;
	@Input() fromPrice = 0;
	@Input() indication = false;
	@Input() conditions = '';
	@Input() currency = 'EUR';
	@Input() size: 'sm' | 'base' | 'lg' | 'xl' = 'base';
	@Input() alignment: 'self-start' | 'self-end' = 'self-end';
	@Input() isHorizontal = false;

	constructor(public override translate: TranslateService) {
		super(translate);
	}
}
