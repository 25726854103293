<div>
	<div class="flex flex-col items-center gap-y-1 md:gap-2">
		<span class="text-2xl font-semibold" data-test-id="summary-title">
			{{ 'storyblok.giftcard.summary-title' | translate }}
		</span>
		<span data-test-id="summary-description">
			{{ 'storyblok.giftcard.summary-description' | translate }}
		</span>
	</div>

	@if (showAlert) {
		<vp-alert
			[closeable]="false"
			[content]="
				'storyblok.giftcard.payment-' +
					orderStatus +
					'-alert.description' | translate
			"
			[emphasized]="true"
			[title]="
				'storyblok.giftcard.payment-' + orderStatus + '-alert.title'
					| translate
			"
			[type]="
				orderStatus === 'pending' ? AlertType.Info : AlertType.Success
			"
			className="my-4 md:my-6"
			data-test-id="alert"
		/>
	}

	@if (receiptItems.length > 0) {
		<vp-receipt-summary
			[currency]="currency"
			[items]="receiptItems"
			[itemsDescription]="'storyblok.giftcard.summary-title' | translate"
			[receiptDescription]="
				'storyblok.giftcard.summary-description' | translate
			"
			[total]="orderSummary.totalIncl"
			[totalVat]="orderSummary.taxes"
			class="mt-4 block md:mt-6"
			data-test-id="summary-card"
		/>
	}
</div>
