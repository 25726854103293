import { XHR_IGNORE } from '../patching/patch-utils';
import { isResponseSuccessful } from './response-status';
import { Promise } from '../polyfills';
export function sendXHR(method, url, _ref) {
  var _ref$timeout = _ref.timeout,
    timeout = _ref$timeout === void 0 ? HTTP_REQUEST_TIMEOUT : _ref$timeout,
    payload = _ref.payload,
    headers = _ref.headers,
    beforeSend = _ref.beforeSend,
    sendCredentials = _ref.sendCredentials;
  return new Promise(function (resolve, reject) {
    var xhr = new window.XMLHttpRequest();
    xhr[XHR_IGNORE] = true;
    xhr.open(method, url, true);
    xhr.timeout = timeout;
    xhr.withCredentials = sendCredentials;
    if (headers) {
      for (var header in headers) {
        if (headers.hasOwnProperty(header)) {
          xhr.setRequestHeader(header, headers[header]);
        }
      }
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        var status = xhr.status,
          responseText = xhr.responseText;
        if (isResponseSuccessful(status)) {
          resolve(xhr);
        } else {
          reject({
            url: url,
            status: status,
            responseText: responseText
          });
        }
      }
    };
    xhr.onerror = function () {
      var status = xhr.status,
        responseText = xhr.responseText;
      reject({
        url: url,
        status: status,
        responseText: responseText
      });
    };
    var canSend = true;
    if (typeof beforeSend === 'function') {
      canSend = beforeSend({
        url: url,
        method: method,
        headers: headers,
        payload: payload,
        xhr: xhr
      });
    }
    if (canSend) {
      xhr.send(payload);
    } else {
      reject({
        url: url,
        status: 0,
        responseText: 'Request rejected by user configuration.'
      });
    }
  });
}