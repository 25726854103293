import { createFeatureSelector } from '@ngrx/store';

import * as fromReducer from './flatpickr.reducers';

export const selectFeature =
	createFeatureSelector<fromReducer.FlatpickrState>('flatpickrState');

export const selectInstance = (state) => state.instance;

export const selectStartDate = (state) => state.startDate;

export const selectEndDate = (state) => state.endDate;

export const selectSelectedDates = (state) => {
	if (state.startDate) {
		if (state.endDate) {
			return [state.startDate, state.endDate];
		} else {
			return [state.startDate];
		}
	} else {
		return [];
	}
};

export const selectNights = (state) => state.nights;

export const selectPrice = (state) => state.price;
