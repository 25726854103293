<div class="inline-block">
	<div
		class="ribbon rounded-r-sm p-1.5 text-xs font-semibold uppercase !leading-none tracking-wider text-dark-primary md:p-2 md:text-sm"
	>
		{{ ribbonText }}
	</div>
	<div
		class="ribbon-fold size-0 border-b-[5px] border-r-[8px] border-solid"
	></div>
</div>
