import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';

@Injectable({ providedIn: 'root' })
export class CountryService {
	constructor(private readonly http: HttpClient) {}

	getCountries(
		language: string,
	): Observable<{ label: string; value: string }[]> {
		const headers = new HttpHeaders();
		return this.http
			.get<HALResponse<{ label: string; value: string }[]>>(
				`/assets/i18n/countries-${language.toLowerCase()}.json`,
				{
					headers,
					observe: 'response',
				},
			)
			.pipe(map((response) => response.body.data));
	}

	getCountry(countryCode: string, language: string): Observable<string> {
		return this.getCountries(language).pipe(
			map((countries) => {
				return (
					countries.find(
						(x: { value: string; label: string }) =>
							x.value === countryCode,
					)?.label || ''
				);
			}),
		);
	}
}
