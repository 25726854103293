import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { RoomCardFacilitiesInterface } from '@valk-nx/rooms-store/rooms.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'vp-room-facilities',
	templateUrl: './room-facilities.component.html',
	standalone: true,
	imports: [AngularSvgIconModule, CommonModule, TranslateModule],
})
export class RoomFacilitiesComponent {
	@Input({ required: true }) facilities!: RoomCardFacilitiesInterface;
	@Input({ required: true }) maxNumberOfGuests!: number;
	@Input({ required: true }) babyBeds = 0;
	@Input({ required: true }) roomSurface!: number;
	@Input({ required: true }) roomIndex = 0;
	@Input() showMoreInformationAccordion = false;

	get allFacilities(): string[] {
		return [
			...this.facilities.sleepingComfort,
			...this.facilities.bathroom,
			...this.facilities.misc,
		];
	}

	showAdditonalInformation = false;

	toggleAdditionalInformation() {
		this.showAdditonalInformation = !this.showAdditonalInformation;
	}
}
