export var globalState = {
  fetchInProgress: false
};
export function apmSymbol(name) {
  return '__apm_symbol__' + name;
}
function isPropertyWritable(propertyDesc) {
  if (!propertyDesc) {
    return true;
  }
  if (propertyDesc.writable === false) {
    return false;
  }
  return !(typeof propertyDesc.get === 'function' && typeof propertyDesc.set === 'undefined');
}
function attachOriginToPatched(patched, original) {
  patched[apmSymbol('OriginalDelegate')] = original;
}
export function patchMethod(target, name, patchFn) {
  var proto = target;
  while (proto && !proto.hasOwnProperty(name)) {
    proto = Object.getPrototypeOf(proto);
  }
  if (!proto && target[name]) {
    proto = target;
  }
  var delegateName = apmSymbol(name);
  var delegate;
  if (proto && !(delegate = proto[delegateName])) {
    delegate = proto[delegateName] = proto[name];
    var desc = proto && Object.getOwnPropertyDescriptor(proto, name);
    if (isPropertyWritable(desc)) {
      var patchDelegate = patchFn(delegate, delegateName, name);
      proto[name] = function () {
        return patchDelegate(this, arguments);
      };
      attachOriginToPatched(proto[name], delegate);
    }
  }
  return delegate;
}
export var XHR_IGNORE = apmSymbol('xhrIgnore');
export var XHR_SYNC = apmSymbol('xhrSync');
export var XHR_URL = apmSymbol('xhrURL');
export var XHR_METHOD = apmSymbol('xhrMethod');