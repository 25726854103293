function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;
  _setPrototypeOf(subClass, superClass);
}
function _setPrototypeOf(o, p) {
  _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) {
    o.__proto__ = p;
    return o;
  };
  return _setPrototypeOf(o, p);
}
import Span from './span';
import SpanBase from './span-base';
import { generateRandomId, merge, now, getTime, extend, removeInvalidChars } from '../common/utils';
import { REUSABILITY_THRESHOLD, TRUNCATED_TYPE } from '../common/constants';
import { captureBreakdown as _captureBreakdown } from './breakdown';
var Transaction = function (_SpanBase) {
  _inheritsLoose(Transaction, _SpanBase);
  function Transaction(name, type, options) {
    var _this;
    _this = _SpanBase.call(this, name, type, options) || this;
    _this.traceId = generateRandomId();
    _this.marks = undefined;
    _this.spans = [];
    _this._activeSpans = {};
    _this._activeTasks = new Set();
    _this.blocked = false;
    _this.captureTimings = false;
    _this.breakdownTimings = [];
    _this.sampleRate = _this.options.transactionSampleRate;
    _this.sampled = Math.random() <= _this.sampleRate;
    return _this;
  }
  var _proto = Transaction.prototype;
  _proto.addMarks = function addMarks(obj) {
    this.marks = merge(this.marks || {}, obj);
  };
  _proto.mark = function mark(key) {
    var skey = removeInvalidChars(key);
    var markTime = now() - this._start;
    var custom = {};
    custom[skey] = markTime;
    this.addMarks({
      custom: custom
    });
  };
  _proto.canReuse = function canReuse() {
    var threshold = this.options.reuseThreshold || REUSABILITY_THRESHOLD;
    return !!this.options.canReuse && !this.ended && now() - this._start < threshold;
  };
  _proto.redefine = function redefine(name, type, options) {
    if (name) {
      this.name = name;
    }
    if (type) {
      this.type = type;
    }
    if (options) {
      this.options.reuseThreshold = options.reuseThreshold;
      extend(this.options, options);
    }
  };
  _proto.startSpan = function startSpan(name, type, options) {
    var _this2 = this;
    if (this.ended) {
      return;
    }
    var opts = extend({}, options);
    opts.onEnd = function (trc) {
      _this2._onSpanEnd(trc);
    };
    opts.traceId = this.traceId;
    opts.sampled = this.sampled;
    opts.sampleRate = this.sampleRate;
    if (!opts.parentId) {
      opts.parentId = this.id;
    }
    var span = new Span(name, type, opts);
    this._activeSpans[span.id] = span;
    if (opts.blocking) {
      this.addTask(span.id);
    }
    return span;
  };
  _proto.isFinished = function isFinished() {
    return !this.blocked && this._activeTasks.size === 0;
  };
  _proto.detectFinish = function detectFinish() {
    if (this.isFinished()) this.end();
  };
  _proto.end = function end(endTime) {
    if (this.ended) {
      return;
    }
    this.ended = true;
    this._end = getTime(endTime);
    for (var sid in this._activeSpans) {
      var span = this._activeSpans[sid];
      span.type = span.type + TRUNCATED_TYPE;
      span.end(endTime);
    }
    this.callOnEnd();
  };
  _proto.captureBreakdown = function captureBreakdown() {
    this.breakdownTimings = _captureBreakdown(this);
  };
  _proto.block = function block(flag) {
    this.blocked = flag;
    if (!this.blocked) {
      this.detectFinish();
    }
  };
  _proto.addTask = function addTask(taskId) {
    if (!taskId) {
      taskId = 'task-' + generateRandomId(16);
    }
    this._activeTasks.add(taskId);
    return taskId;
  };
  _proto.removeTask = function removeTask(taskId) {
    var deleted = this._activeTasks.delete(taskId);
    deleted && this.detectFinish();
  };
  _proto.resetFields = function resetFields() {
    this.spans = [];
    this.sampleRate = 0;
  };
  _proto._onSpanEnd = function _onSpanEnd(span) {
    this.spans.push(span);
    delete this._activeSpans[span.id];
    this.removeTask(span.id);
  };
  _proto.isManaged = function isManaged() {
    return !!this.options.managed;
  };
  return Transaction;
}(SpanBase);
export default Transaction;