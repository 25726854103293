<div class="bg-accent-25" data-test-class="newsletter">
	<div class="mx-auto flex max-w-screen-2xl flex-col md:flex-row">
		@if (photos.length > 0) {
			<div
				class="grid h-60 grid-cols-3 md:hidden md:h-auto md:w-3/12 md:grid-cols-1 lg:grid lg:w-4/12 lg:grid-cols-2 xl:grid-cols-3 2xl:w-5/12"
			>
				@for (photo of photos; track photo) {
					<vp-image
						[image]="{
							src: photo.file,
							altText: photo.alt,
							width: 1324,
							height: 1060,
							style: styleHeight,
						}"
						[sources]="[
							{
								min: null,
								max: sizes.sm,
								width: 848,
								height: 512,
							},
							{
								min: sizes.sm,
								max: sizes.lg,
								width: 1060,
								height: 720,
							},
							{
								min: sizes.lg,
								max: sizes.xxl,
								width: 1324,
								height: 1060,
							},
							{
								min: sizes.xxl,
								max: null,
								width: 1589,
								height: 1020,
							},
						]"
						class="md:hidden md:first:block md:last:block xl:block"
						className="h-full object-cover"
					/>
				}
			</div>
		}
		<div
			#cardOuterContent
			class="relative flex w-full flex-col px-6 py-10 md:justify-center md:px-1/12 md:py-16 lg:py-20 xl:py-32"
		>
			<div #cardContent>
				<ng-content />
				@if (content) {
					<markdown
						[data]="content"
						class="list-checkmarks mb-8 mt-6 block md:text-lg"
					/>
				}
				<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
					<div class="flex flex-col md:flex-row md:gap-x-4">
						<formly-form
							[fields]="formFields"
							[form]="formGroup"
							class="w-full"
						/>
						<button
							[ngClass]="{
								'btn-is-loading': newsletterSubmitting,
							}"
							class="btn btn-primary mt-4 h-fit w-full md:mt-7 md:w-fit"
							data-test-id="newsletter-button"
							id="newsletter-submit"
							type="submit"
						>
							{{ 'components.newsletter.sign-up' | translate }}
						</button>
					</div>
					@if (newsletterSubmitted && !newsletterSubmitError) {
						<span
							class="mt-1 block text-center leading-loose text-dark-secondary md:absolute md:mt-0 md:text-left"
							data-test-id="subscribe-confirmation"
						>
							{{
								'components.newsletter.confirmation' | translate
							}}
						</span>
					}
				</form>
			</div>
		</div>
	</div>
</div>
