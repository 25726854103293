import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { resolvePostalCode as postalCodeResolver } from './postal-code.helper';
import {
	GetAddressInterface,
	PatchAddressInterface,
} from './postal-code.interface';
import { PostalCodeService } from './postal-code.service';

@Injectable()
export class PostalCodeFacade {
	constructor(private readonly postalCodeService: PostalCodeService) {}

	resolvePostalCode(
		formGroupValueChanges$: Observable<Partial<GetAddressInterface>>,
		storeData$: Observable<GetAddressInterface>,
		patchValue: (a: PatchAddressInterface) => void,
	): Observable<GetAddressInterface> {
		return postalCodeResolver(
			formGroupValueChanges$,
			storeData$,
			300,
			(value) => patchValue(value),
			(address) => this.postalCodeService.getAddress(address),
		);
	}
}
