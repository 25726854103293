<vp-footer
	*ngrxLet="hotelInfo$ as hotelInfo"
	[bottomItems]="bottomMenu | menuItems"
	[editableInStoryblok]="_editable"
	[information]="hotelInfo"
	[menu]="{ items: footerMenu }"
	[socials]="socials"
	[title]="title || hotelInfo.name"
	[usps]="usps | menuItems"
/>
