import { InjectionToken } from '@angular/core';

import { AlertPosition } from '@valk-nx/components/ui-alert/src/lib/contracts/alert.types';

export const alertErrorSettings = {
	closeable: true,
	duration: 5000,
	emphasized: false,
	hasElevation: true,
	position: AlertPosition.TopCenter,
};

export const BOOKINGTOOL = new InjectionToken<string[]>('bookingtool');
