<div class="flex flex-col">
	@if (carousel) {
		<sb-carousel [config]="carousel.config" [items]="carousel.items" />
	}
	@if (widget) {
		<div class="z-50 -mt-6 px-3 md:px-8 2xl:px-10">
			<div class="mx-auto w-full max-w-screen-2xl">
				<div class="mx-auto flex w-full flex-col lg:w-10/12 2xl:w-8/12">
					<sb-booking-widget
						[bookingtoolUrl]="widget.bookingtoolUrl"
						[calendarType]="widget.calendarType"
						[currency]="widget.currency"
						[dealGUID]="widget.dealGUID"
						[defaultAdults]="widget.defaultAdults"
						[hotelCode]="widget.hotelCode"
						[hotelGUID]="widget.hotelGUID"
						[maxAdults]="widget.maxAdults"
						[maxChildren]="widget.maxChildren"
						[maxDate]="widget.maxDate"
						[maxInfants]="widget.maxInfants"
						[maxNights]="widget.maxNights"
						[minAdults]="widget.minAdults"
						[minChildren]="widget.minChildren"
						[minDate]="widget.minDate"
						[minInfants]="widget.minInfants"
						[minNights]="widget.minNights"
						[rateCode]="widget.rateCode"
						[validFrom]="widget.validFrom"
						[validUntil]="widget.validUntil"
					/>
				</div>
			</div>
		</div>
	}
</div>
