import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';

import { MasonryComponent } from '@valk-nx/compositions/ui-masonry/src/lib/masonry.component';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { StoryblokImage } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-masonry-section',
	templateUrl: './masonry-section.html',
	standalone: true,
	imports: [
		CommonModule,
		ColumnsComponent,
		DynamicModule,
		MasonryComponent,
		StoryblokDirective,
	],
})
export class StoryblokMasonryComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) title!: ISbStoryData[];
	@Input({ required: true }) images!: StoryblokImage[];

	vpImages: PhotoInterface[] = [];
	titleWithFigures: ISbStoryData[] = [];

	ngOnInit(): void {
		this.setImages();
		this.setFigures();
	}

	setImages(): void {
		this.vpImages = this.images.map((image) => {
			if (image.bynder?.length > 0) {
				return StoryblokHelper.mapBynderToPhotoInterface(
					image.bynder[0],
					image.imageAlt,
				);
			} else {
				return {
					src: '',
					alt: image.imageAlt,
				};
			}
		});
	}

	setFigures(): void {
		this.titleWithFigures = this.title.map((title) => {
			return {
				...title,
				topFigure: 'assets/icons/figure-line.svg',
			};
		});
	}
}
